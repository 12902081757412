import { IconDownloadSimple } from 'components';
import { ConclusionExerciseDto } from '@libs/ms-api';

import * as S from './ConclusionExercise.styles';
import { useFeatureToggle } from 'hooks';
export interface ConclusionExerciseProps {
  content: ConclusionExerciseDto;
  moduleNumber: number;
  shouldShowLine: boolean;
  completed: boolean;
}

const ConclusionExerciseComponent = ({
  content,
  moduleNumber,
  shouldShowLine,
  completed,
}: ConclusionExerciseProps) => {
  return (
    <S.Wrapper
      shouldShowLine={shouldShowLine}
      className={completed ? 'completed' : null}>
      <S.TitleContainer>
        <S.ModuleNumber className={completed ? 'completed' : null}>
          {moduleNumber}
        </S.ModuleNumber>
        <S.ModuleTitle>Exercício de Conclusão</S.ModuleTitle>
      </S.TitleContainer>
      <S.ContentContainer>
        <h4>{content.title}</h4>
        <p>{content.description || ''}</p>
        <S.DownloadButton href={content.url} target="_blank">
          Baixar agora
          <div className="icon-container">
            <IconDownloadSimple color={'#fe7000'} />
          </div>
        </S.DownloadButton>
      </S.ContentContainer>
    </S.Wrapper>
  );
};

export const ConclusionExercise = ({
  content,
  moduleNumber,
  shouldShowLine,
  completed,
}: ConclusionExerciseProps) => {
  const ConclusionExerciseToggle = useFeatureToggle({
    ComponentWhenEnabled: () => (
      <ConclusionExerciseComponent
        content={content}
        moduleNumber={moduleNumber}
        shouldShowLine={shouldShowLine}
        completed={completed}
      />
    ),
    ComponentWhenDisabled: () => (
      <ConclusionExerciseComponent
        content={content}
        moduleNumber={moduleNumber}
        shouldShowLine={false}
        completed={false}
      />
    ),
    featureKeys: ['certificates'],
  });
  return <ConclusionExerciseToggle />;
};
