/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

// Template Page
import { SignUp } from '@templates';

// Components
import { Logo, Text, IconHelp } from '@components';

// Themes
import { main as theme } from '@themes';

import { Form, SocialLogin } from './containers';

// Styles
import { Wrapper, InputSection, TitleSection } from './login.styles';

const Login = () => {
  return (
    <>
      <SignUp titlePage="Login" highlight=" ">
        <Wrapper>
          <div className="mobile-only">
            <Logo size="150px" />
          </div>
          <TitleSection>
            <Text
              as="h6"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorSystem.baseColor400}
              weight="bold"
              height="normal"
              dheight="normal">
              Login
            </Text>
            <div className="page__subtitle">
              <Text
                as="h1"
                size="2rem"
                dsize="2rem"
                color={theme.colorSystem.baseColor}
                family={theme.fonts.fontFamily.serif}
                weight="bold"
                height="normal"
                dheight="normal">
                Acesse sua Conta
              </Text>
              <a
                target="_blank"
                href="https://meusucesso.com/faq/"
                id="login__link__faq">
                <IconHelp size="18px" color={theme.colorPallete.base.default} />
              </a>
            </div>
          </TitleSection>
          <InputSection>
            <Form />
          </InputSection>
          <SocialLogin />
        </Wrapper>
      </SignUp>
    </>
  );
};

export default Login;
export { Login };
