import styled, { css } from 'styled-components';
import Image from 'next/image';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-family: ${theme.fonts.fontFamily.sans};
    padding-block: 16px;
    background-color: ${theme.colorSystem.black};
    padding-inline: 20px;

    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Content = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3.2rem;
    margin: 0 auto;

    .slick-track {
      margin: 0;
      overflow: auto;

      @media (max-width: 790px) {
        display: flex;
        gap: 24px;
        align-items: stretch;
        gap: 12px;
        width: 100% !important;

        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .slick-slide {
          width: 100% !important;
        }
      }
    }

    .slick-arrow {
      top: 50%;
      width: 5%;
      height: 100%;

      &::before {
        display: none !important;
      }
      &.slick-disabled {
        display: none !important;
      }

      &.slick-next {
        right: 0;
        background-image: linear-gradient(to right, transparent, #000000);
        svg {
          transform: rotate(180deg);
        }
      }
      &.slick-prev {
        left: calc(-1% - 56px);
        background-image: linear-gradient(to left, transparent, #000000);
      }
    }

    @media (max-width: 1573px) {
      margin: 0;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: 20px;
  `}
`;

export const ContainerPersons = styled.div`
  ${() => css`
    display: flex;
    width: 100%;
    gap: 64px;
  `}
`;

export const Card = styled.div`
  ${() => css`
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    min-height: 42px;

    gap: 16px;

    span {
      width: 32px !important;
      height: 32px !important;
    }

    :last-child {
      margin-right: 24px;
    }
  `}
`;

export const DefaultCardImage = styled.div`
  ${() => css`
    width: 32px;
    height: 32px;
    background-color: #d9d9d9;
    border-radius: 32px;
    margin-bottom: auto;
  `}
`;

export const CardImage = styled(Image)`
  ${() => css`
    border-radius: 32px;
    min-width: 32px;
    min-height: 32px;
  `}
`;

export const CardContentText = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 4px;
    width: max-content;
    max-width: 250px;
  `}
`;

export const CardPersonName = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-size: 16px;
    font-weight: ${theme.fonts.fontWeight.bold};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const CardPersonDescription = styled.h5`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[170]};
    font-size: 14px;
    font-weight: ${theme.fonts.fontWeight.regular};
  `}
`;

export const NavigationArrow = styled.button`
  width: 30px;
  z-index: 3;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 100%;
  }
`;
