/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { actions as checklistActions } from '@store/ducks/checklist';

// Helpers
import { ramdomizeArray } from '@helpers';

import { main as theme } from '@themes';

import { LoggedIn } from '@templates';

import { Text, IconRightArrow } from '@components';

import { LessonChecklist, LessonChecklistResults } from './containers';

import { styles } from './Checklist.styles';

const Wrapper = styled.div`
  ${styles}
`;

const Checklist = () => {
  const dispatch = useDispatch();
  const { push, query } = useRouter();

  const { checklistData } = useSelector((state) => state.checklistReducer);

  const [currentQuestion, setCurrentQuestion] = useState(false);

  const [showResult, toggleShowResult] = useState(false);

  const token = Cookies.get('tokenClient')
  const refreshToken = Cookies.get('refreshtokenClient')


  const getChecklistData = async () => {
    dispatch(
      checklistActions.getChecklistRequest({
        auth: {
          token: token,
          refresh_token: refreshToken,
        },
        data: {
          slug: query.aula,
        },
      })
    );
  }
  // ? Checks wheter the numbers of questions answered equals the total questions:
  const checklisAnswered =
    checklistData?.checklist.filter((question) => question?.answered?.done)
      .length === checklistData?.total_questions;

  // ? Find the right and wrong questions answered by the user:
  const userAnswers = checklistData?.checklist.map((question) =>
    question?.answered?.option
      ? question.options.filter(
          (option) =>
            !!(
              option.id === question.answered.option &&
              option.correct_option === 'true'
            )
        ).length === 1
      : false
  );
  
  useEffect(() => {
    getChecklistData();
  }, []);


  useEffect(() => {
    if (checklistData && checklistData.lesson_slug === query.aula) {
      // ? If the user insert a non-exist question on adress (like 42):
      if (!checklistData?.checklist[query.question - 1]) {
        push(
          '/curso/checklist/[aula]/[question]',
          `/curso/checklist/${query.aula}/1`,
          { shallow: true }
        );

        setCurrentQuestion({
          ...checklistData.checklist[0],
          options: ramdomizeArray(checklistData.checklist[0].options),
        });
      } else if (
        !currentQuestion ||
        query.question.toString() !== currentQuestion.order.toString()
      ) {
        // ? If the user change the URL, always change the question too:
        setCurrentQuestion({
          ...checklistData.checklist[query.question - 1],
          options: ramdomizeArray(
            checklistData.checklist[query.question - 1].options
          ),
        });
        toggleShowResult(false);
      }
    }
  }, [checklistData, query]);

  const handleBackButton = () => {
    return push('/curso/aula/[aula]', `/curso/aula/${query.aula}`);
  };

  return (
    <LoggedIn refreshToken={refreshToken} titlePage="Checklist">
      <Wrapper
        bgimage={checklistData?.image ? checklistData?.image[5]?.link : ``}
      >
        <div className="checklist-page__picture">
          {/* Icon back button */}
          {/* <div
            className="checklist-page__back-control mobile-only"
            onClick={() => handleBackButton()}
            aria-hidden
          >
            <span className="arrow-left">
              <IconRightArrow size="32px" color={theme.colorSystem.white} />
            </span>
          </div> */}
        </div>
        <div className="checklist-page__content">
          {/* Back button */}
          <div
            className="checklist-page__close-control desk-only-"
            onClick={() => handleBackButton()}
            aria-hidden
          >
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base.default}
              weight="bold"
              cursor="pointer"
            >
              <span className="arrow-left">
                <IconRightArrow color={theme.colorSystem.black} />
              </span>
              <span>Voltar</span>
            </Text>
          </div>

          {/* Questions */}
          {currentQuestion && !showResult && (
            <div className="checklist-page__content-wrapper">
              <LessonChecklist
                currentQuestion={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
                toggleShowResult={toggleShowResult}
                checklisAnswered={checklisAnswered}
                userAnswers={userAnswers}
              />
            </div>
          )}

          {/* Graphic Result */}
          {showResult && checklisAnswered && (
            <div className="checklist-page__content-wrapper checklist-page__result-wrapper">
              <LessonChecklistResults
                userAnswers={userAnswers}
                total={checklistData?.total_questions}
                url={`/curso/checklist/${query.aula}/1`}
              />
            </div>
          )}
        </div>
      </Wrapper>
    </LoggedIn>
  );
};

Checklist.propTypes = {
  refreshToken: PropTypes.string,
};

Checklist.defaultProps = {
  refreshToken: '',
};

export { Checklist };
export default Checklist;
