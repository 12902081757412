import React from 'react';
import Cookies from 'js-cookie';

import { LoggedIn } from '@templates';

import { CourseContent } from './containers';

const Course = () => {
  const refreshToken = Cookies.get('refreshtokenClient');
  return (
    <>
      <LoggedIn
        refreshToken={refreshToken}
        titlePage="Programa de Estudo"
        modalActive={false}
        showingModal={false}>
        <CourseContent />
      </LoggedIn>
    </>
  );
};

export { Course };
export default Course;
