import React from 'react';
import * as S from './ClassSummary.style';
import Image from 'next/image';
import { ScrollableContainer } from 'components';

import { getTitleAboutText } from './helpers';
import { LessonDto } from '@libs/ms-api';

interface ClassSumaryProps {
  lessonData: LessonDto;
}

export const ClassSummary = ({ lessonData }: ClassSumaryProps) => {
  return (
    <S.Wrapper className="wrapper-class-summary">
      <S.Title>{lessonData?.metadata?.WhatWillLearn?.title}</S.Title>
      <S.List>
        {lessonData?.metadata?.WhatWillLearn.table?.map((sumary, index) => (
          <li key={index}>{`- ${sumary}`}</li>
        ))}
      </S.List>

      {lessonData?.involvedPeople?.length > 0 && (
        <>
          <S.Title className="title-about">
            {`Sobre ${getTitleAboutText(
              'Empreendedor',
              lessonData?.involvedPeople.length > 1,
            )}`}
          </S.Title>
          <ScrollableContainer>
            <S.AboutEntrepreneursContainer>
              {lessonData?.involvedPeople?.map((person, index) => (
                <S.EntrepreneursCard key={`${person.type}-${index}`}>
                  <S.Avatar>
                    {person?.image?.url && (
                      <Image
                        alt={person?.image?.alt}
                        src={person?.image?.url}
                        width={42}
                        height={42}
                        className="avatar"
                      />
                    )}
                  </S.Avatar>
                  <S.EntrepreneursContent>
                    <S.Title>{person?.name}</S.Title>
                    <S.EntrepreneursDescription>
                      {person?.bio}
                    </S.EntrepreneursDescription>
                  </S.EntrepreneursContent>
                </S.EntrepreneursCard>
              ))}
            </S.AboutEntrepreneursContainer>
          </ScrollableContainer>
        </>
      )}
    </S.Wrapper>
  );
};
