import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;

  .paper {
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr auto;

    .block__title {
      margin-bottom: 3rem;
    }

    .block__content {
      display: flex;
      flex-direction: column;
      position: relative;

      @media all and (min-width: ${theme.breakpoints.big}) {
        .block__content__wrapper {
          padding-right: 1rem;
          position: absolute;
          overflow: hidden;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }

      .block__content__month {
        margin-bottom: 4rem;
        position: relative;
        height: 100%;

        .subscription__table {
          display: grid;
          grid-template-rows: auto 1fr;
          width: 100%;
          height: 100%;

          &__header,
          .table-row {
            display: grid;
            grid-template-columns: 8rem 1fr 4rem 1fr;
            width: 100%;

            @media all and (min-width: ${theme.breakpoints.medium}) {
              grid-template-columns: 9rem 1fr 4rem 1fr;
            }

            @media all and (min-width: 1366px) {
              grid-template-columns: 9rem 1fr 6rem 1fr;
            }
          }

          &__header {
            position: absolute;
            top: 0;

            background-color: ${theme.colorSystem.white};
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 0.1rem solid ${theme.colorPallete.base[200]};

            .column-name {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              width: 100%;
            }
          }

          &__body {
            width: 100%;
            padding-top: 4rem;
            overflow: scroll;

            @media all and (min-width: ${theme.breakpoints.medium}) {
              padding-top: 6rem;
            }

            &__block {
              padding-bottom: 1rem;
              border-bottom: 0.1rem solid ${theme.colorPallete.base[200]};

              .table-row {
                .table-cell {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  border-right: 0.1rem solid ${theme.colorPallete.base[200]};

                  width: 100%;
                  margin: 2rem 0;
                }

                .status-cell {
                  border: none;

                  & > div.invoice__status {
                    width: 100%;

                    div.invoice__status__content {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;

                      width: 100%;
                      max-width: 9.4rem;
                      margin: 0 auto;

                      @media all and (min-width: ${theme.breakpoints.medium}) {
                        max-width: 10.8rem;
                      }

                      .status-circle {
                        width: 1.2rem;
                        height: 1.2rem;
                        min-width: 1.2rem;
                        min-height: 1.2rem;
                        margin-right: 0.5rem;
                        margin-top: 0.2rem;
                        border-radius: 1rem;
                      }

                      .open {
                        background-color: transparent;
                        border: 0.2rem solid ${theme.colorPallete.base[200]};
                      }

                      .paid {
                        background-color: ${theme.colorSystem.success};
                      }

                      .canceled,
                      .refunded {
                        background-color: ${theme.colorPallete.base[200]};
                      }

                      .pending,
                      .rejected {
                        background-color: ${theme.colorSystem.danger};
                      }

                      .processing,
                      .analisys {
                        background-color: ${theme.colorSystem.warning};
                      }
                    }
                  }
                }
              }

              & > div.subscription-payment-cta {
                display: block;
                height: fit-content;
                padding-bottom: 2rem;
                margin-bottom: 1rem;

                border-bottom: 0.1rem solid ${theme.colorPallete.base[200]};

                .subscription__button_paynow {
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }

        .block__content__month__payment-data {
          display: block;
          text-align: center;
          padding-top: 1.5rem;
          border-top: 0.1rem solid ${theme.colorPallete.base[200]};

          .ms-button {
            margin-top: 1.5rem;
          }
        }
      }
    }

    .block__cancel-subscription {
      padding: 2rem 1rem;
      background-color: ${theme.colorPallete.base[100]};
      border-radius: 0.4rem;
      text-align: center;

      a {
        max-width: 29.5rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colorPallete.base.default};
        text-decoration: none;
        border: 0.2rem solid ${theme.colorPallete.base.default};
        border-radius: 0.4rem;
        padding: 0.5rem;
        margin: 0 auto;
        margin-top: 2rem;
        cursor: pointer;

        @media all and (min-width: ${theme.breakpoints.big}) {
          max-width: 100%;
        }
      }
    }
  }
`;

export default styles;
export { styles };
