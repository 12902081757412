/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { Button, Input } from '@components';

// Helpers
import { polishingString } from '@helpers';

// Styles
import { FooterButton, FormStyles } from './form.styles';

const Form = () => {
  const router = useRouter();

  const { query } = router;

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(
          'Sua senha deverá ter no mínimo 8 dígitos, formada por letras e números'
        )
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*_#?&]{8,}$/,
          'Sua senha deverá ter no mínimo 8 dígitos, formada por letras e números'
        ),
      passwordConfirmation: Yup.string()
        .required('As senhas digitadas não estão iguais')
        .oneOf(
          [Yup.ref('password'), null],
          'As senhas digitadas não estão iguais'
        ),
    }),
    onSubmit: async (values) => {
      const { passwordConfirmation, ...restValues } = values;

      const data = {
        ...restValues,
        password_confirmation: passwordConfirmation,
        token: query.token,
      };

      dispatch(userActions.resetPasswordRequest({ data }));
    },
  });

  return (
    <FormStyles onSubmit={formik.handleSubmit} method="post">
      <div className="form__input">
        <Input
          label="Nova senha"
          type="password"
          size="medium"
          placeholder=""
          name="password"
          id="password"
          value={formik.values.password}
          onChange={(e) =>
            formik.setFieldValue('password', polishingString(e.target.value))
          }
          validation={
            formik.errors.password && formik.touched.password ? 'invalid' : null
          }
          validationMsg={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : ''
          }
        />
      </div>

      <div className="form__input">
        <Input
          label="Repita sua nova senha"
          type="password"
          size="medium"
          placeholder=""
          name="passwordConfirmation"
          id="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={(e) =>
            formik.setFieldValue(
              'passwordConfirmation',
              polishingString(e.target.value)
            )
          }
          validation={
            formik.errors.passwordConfirmation &&
            formik.touched.passwordConfirmation
              ? 'invalid'
              : null
          }
          validationMsg={
            formik.errors.passwordConfirmation &&
            formik.touched.passwordConfirmation
              ? formik.errors.passwordConfirmation
              : ''
          }
        />
      </div>
      <FooterButton>
        <Button variant="primary" size="medium" type="submit">
          Redefinir
        </Button>
      </FooterButton>
    </FormStyles>
  );
};

export { Form };
export default Form;
