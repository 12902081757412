import styled from 'styled-components';

const WrapperTop = styled.div`
  width: 100%;
  margin: 0 auto 3rem;
  padding: 6rem 2rem;
  padding-top: 9.1rem;

  max-width: ${(props) => props.theme.breakpoints.big};

  header {
    padding: 2rem 0;
  }

  .my-list {
    &__subtitle {
      padding: 2rem 0;
    }
    &-video__title,
    &-video__subtitle {
      word-wrap: break-word;
      word-break: break-word;
      /* hyphens: auto; */
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.medium}) {
    padding: 2rem;
  }
`;

export { WrapperTop };
export default WrapperTop;
