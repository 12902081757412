import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 830px;
    flex-direction: column;
    gap: 8px;

    font-family: ${theme.fonts.fontFamily.sans};

    padding-inline: 20px;
    margin-block: 46px;

    @media (min-width: 1024px) {
      padding-left: 10vw;
    }
  `}
`;

export const HeaderComments = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 1.6rem;
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colorSystem.baseColor220};
  `}
`;

export const Description = styled.h3`
  ${({ theme }) => css`
    font-size: 1.8rem;
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colorPallete.white};
  `}
`;

export const AmountComments = styled.p`
  ${({ theme }) => css`
    font-size: 1.6rem;
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colorPallete.white};
    margin-block: 32px 24px;
    span {
      color: ${theme.colorSystem.baseColor320};
    }
  `}
`;
