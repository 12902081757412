import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';

import { actions as lessonActions } from 'store/ducks/lesson';

import { IconLoading } from 'components';

import { CommentCard, Form } from './containers';

import { Wrapper } from './CommentsBox.styles';

interface CommentsBoxProps {
  lessonId: string;
  currentVideo: {
    comments_titles: {
      title: string;
      description: string;
    };
  };
  refreshToken: string;
}

const CommentsBox = ({
  lessonId,
  currentVideo,
  refreshToken,
}: CommentsBoxProps) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { commentsData } = useSelector((state: any) => state.lessonReducer);

  useEffect(() => {
    if (!commentsData) {
      dispatch(
        lessonActions.getCommentsRequest({
          payload: {
            data: {
              lessonId,
            },
          },
        }),
      );
    }
  }, [commentsData]);

  useEffect(() => {
    if (commentsData) {
      const slideComment = document.querySelector('.slideComment');
      slideComment?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [commentsData]);

  let commentKey = 0;
  let commentAnswerKey = 0;
  return (
    <Wrapper>
      {!commentsData ? (
        <IconLoading />
      ) : (
        <div className="comments__content">
          {currentVideo?.comments_titles?.title && (
            <p>{currentVideo?.comments_titles?.title}</p>
          )}
          <div className="comments__content__form">
            <Form lessonId={lessonId} />
          </div>
          <div className="comments__content__items">
            {commentsData &&
              commentsData.map((comment, commentIdx) => {
                commentKey = commentIdx;
                return (
                  <div
                    className={`comments__content__items__card ${
                      comment.id === router?.query?.idComment
                        ? 'slideComment'
                        : ''
                    }`}
                    key={`comments-${commentKey}`}>
                    <CommentCard comment={comment} lessonId={lessonId} />
                    {/* First level */}
                    <div className="comments__content__items__card__answer">
                      {comment.answer &&
                        comment.answer.map(
                          (commentAnswer, commentAnswerIdx) => {
                            commentAnswerKey = commentAnswerIdx;
                            return (
                              <CommentCard
                                key={`comment-answer-${commentAnswerKey}`}
                                comment={commentAnswer}
                                blockAnswer
                                refreshToken={refreshToken}
                                lessonId={lessonId}
                              />
                            );
                          },
                        )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CommentsBox;
export { CommentsBox };
