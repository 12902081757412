import React from 'react';
import styled from 'styled-components';

// Theme
import { main as theme } from '@themes';

// Components
import { GoogleButton, FacebookButton, Text } from '@components';

// Styles
import { styles } from './SocialLogin.styles';

// Begin container
const Wrapper = styled.div`
  ${styles}
`;

const SocialLogin = React.memo(() => {
  return (
    <Wrapper>
      <div className="container">
        <div className="container__header">
          <Text
            dsize="1.6rem"
            size="1.6rem"
            weight={theme.fonts.fontWeight.bold}
            color={theme.colorPallete.base.default}
          >
            Acesse com as suas redes sociais:
          </Text>
        </div>

        <div className="container__interactions">
          <div className="container__interactions__button">
            <GoogleButton type="login" id="login__button__connect-google" />
          </div>
          <div className="container__interactions__button">
            <FacebookButton type="login" id="login__button__connect-facebook" />
          </div>
        </div>

        <div className="container__footer">
          <Text
            dsize="1.4rem"
            size="1.4rem"
            weight={theme.fonts.fontWeight.regular}
            color={theme.colorPallete.base[400]}
          >
            Caso não consiga acessar com suas redes sociais, será necessário
            vincular novamente. Acesse com seu login e senha cadastrados no
            primeiro acesso ao meuSucesso.com, e siga para o seu perfil para
            concluir esta ação.
          </Text>
        </div>
      </div>
    </Wrapper>
  );
});

export default SocialLogin;
export { SocialLogin };
