import styled from 'styled-components';
import { main as theme } from '@themes';

const Wrapper = styled.main`
  width: 100%;
  padding: 6rem 0 2rem;

  @media (min-width: ${theme.breakpoints.medium}) {
    padding-top: 0;
  }
`;

export { Wrapper };
export default Wrapper;
