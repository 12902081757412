import { css } from 'styled-components';

const userdataStyles = ({ theme, userPhoto }) => css`
  width: 100%;
  background-color: transparent;

  .paper {
    display: block;

    .block-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3.6rem;

      button {
        background-color: transparent;
        outline: none;
        display: flex;
        align-items: center;
        cursor: pointer;

        .block-title__button-text {
          text-decoration: underline;
          margin-right: 0.5rem;
          border-bottom: 0.1rem solid transparent;
        }

        &:hover {
          .block-title__button-text {
            color: ${theme.colorPallete.base[500]};
          }

          #icon-pencil path {
            fill: ${theme.colorPallete.base[500]};
          }
        }
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .main-container__photo-holder {
        display: block;
        width: 100%;
        align-self: center;
        text-align: center;
        margin-bottom: 3.6rem;

        .photo-holder {
          width: 16rem;
          height: 16rem;
          margin: 0 auto;
          margin-bottom: 1.2rem;
          border-radius: 16rem;
          overflow: hidden;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(${userPhoto});
          background-color: ${theme.colorPallete.base[100]};

          img {
            width: 100%;
            height: auto;
          }
        }

        button {
          background-color: transparent;

          .change-photo {
            text-decoration: underline;
          }

          &:hover {
            .change-photo {
              color: ${theme.colorPallete.base[400]};
            }
          }
        }
      }

      .main-container__data-holder {
        width: 100%;

        .data-holder {
          width: 100%;

          .field-wrapper {
            padding-bottom: 1.8rem;

            h4 {
              padding-bottom: 0.5rem;
            }
          }

          .button-wrapper {
            max-width: 31.5rem;
            padding-top: 2rem;
          }
        }
      }
    }

    @media all and (min-width: ${theme.breakpoints.medium}) {
      .main-container {
        flex-direction: row;

        .main-container__photo-holder {
          width: 100%;
          align-self: flex-start;
          margin-right: 3.6rem;
        }

        .main-container__data-holder {
          width: 100%;
        }
      }
    }

    @media all and (min-width: ${theme.breakpoints.big}) {
      .main-container {
        flex-direction: row;

        .main-container__photo-holder {
          width: 16rem;
        }

        .main-container__data-holder {
          .data-holder {
            .button-wrapper {
              max-width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

export default userdataStyles;
export { userdataStyles };
