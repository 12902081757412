import { useState } from 'react';
import * as S from './CertificateInfo.styles';
import { CustomTooltip, IconMedal, IconInfo } from '@components';
import { useFeatureToggle } from 'hooks';

interface CertificateInfoProps {
  isMobile: boolean;
}

const CertificateInfoComponent = ({ isMobile }: CertificateInfoProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <S.Wrapper>
      <S.CertificateInfoConatiner>
        <IconMedal size="36px" />
        <S.CertificateText>
          <p>Este Curso possui</p>
          <h4>Certificado de conclusão</h4>
        </S.CertificateText>
        <S.InfoButton
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}>
          <IconInfo color="#838383" className="icon-info" />
        </S.InfoButton>

        <CustomTooltip
          bottom={isMobile ? '-135px' : '-135px'}
          right={isMobile ? '-80px' : '-380px'}
          shouldAppear={showTooltip}>
          <S.TooltipContent>
            <h5>Requisitos para emissão de certificado do curso:</h5>
            <ul>
              <li>
                Assistir todos os documentários e aulas de empreendedores e
                especialistas do curso;
              </li>
            </ul>
            <p>Nem todos os cursos emitem certificado</p>
          </S.TooltipContent>
        </CustomTooltip>
      </S.CertificateInfoConatiner>
    </S.Wrapper>
  );
};

const CertificateInfo = ({ isMobile }: CertificateInfoProps) => {
  const CertificateInfoToggle = useFeatureToggle({
    ComponentWhenEnabled: () => (
      <CertificateInfoComponent isMobile={isMobile} />
    ),
    featureKeys: ['certificates'],
  });
  return <CertificateInfoToggle />;
};

export default CertificateInfo;
export { CertificateInfo };
