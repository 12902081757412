import styled, { css } from 'styled-components';
import Link from 'next/link';

export const WrapperLink = styled(Link)`
  height: 100%;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 270px;
    max-width: 270px;
    position: relative;

    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 10px 20px 10px;
    background-color: ${theme.colorPallete.base[600]};
    border-radius: 4px;

    &.disabled {
      cursor: not-allowed;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        z-index: 2;
      }
    }
  `}
`;

export const NewContent = styled.span`
  ${({ theme }) => css`
    display: block;
    position: absolute;
    padding: 6px 12px;

    top: 6px;
    left: 6px;

    font-weight: ${theme.fonts.fontWeight.bold};

    background-color: ${theme.colorPallete.primary[200]};
    border-radius: 4px;
  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 4px;
  aspect-ratio: 1.67;
  position: relative;

  img {
    border-radius: 4px;
  }
`;

export const CheckCompletion = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${theme.colorPallete.white};

    display: flex;
    align-items: center;
    justify-content: center;

    .icon-checked {
      display: none;
    }

    &.completed {
      background-color: ${theme.colorPallete.primary[200]};

      .icon-checked {
        display: block;
      }
    }
  `}
`;

export const DisabledLock = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerProgress = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 13px;
    margin-block: 16px;

    gap: 6px;

    p {
      font-weight: ${theme.fonts.fontWeight.regular};
      display: flex;
      align-items: center;
      margin-top: -2px;
    }
  `}
`;

export const ProgressBar = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 4px;
    background: ${theme.colorPallete.base[425]};
    border-radius: 10px;
    @media (min-width: 1440px) {
      height: 5px;
    }
  `}
`;

interface ProgressProps {
  progress: number;
}

export const Progress = styled.div<ProgressProps>`
  ${({ progress, theme }) => css`
    width: calc(${progress} * 100%);
    height: 100%;
    background-color: ${theme.colorPallete.primary[200]};
    border-radius: 10px;
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    padding: 18px 4px 0 4px;
    background-color: ${theme.colorPallete.base[600]};
    max-width: 100%;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const HeaderContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: space-between;
    gap: 6px;
    text-wrap: nowrap;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-weight: 700;
    font-size: 12px;
    color: ${theme.colorPallete.primary[200]};
  `}
`;

export const Duration = styled.h3`
  ${() => css`
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
  `}
`;

export const Description = styled.p`
  ${() => css`
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    margin-top: 16px;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;

export const TagsArea = styled.div`
  height: 20px;
`;

export const ExerciseContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[320]};
    font-size: ${theme.fonts.fontSize.xs};
    font-family: ${theme.fonts.fontFamily.sans};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    &.completed {
      color: ${theme.colorPallete.white};
    }
  `};
`;

export const BonusContent = styled.div`
  ${({ theme }) => css`
    padding: 4px 12px;
    z-index: 3;
    top: 10px;
    right: 10px;
    background-color: ${theme.colorPallete.primary.default};
    border-radius: 72px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.xxs};
    font-weight: ${theme.fonts.fontWeight.bold};
    position: absolute;
  `}
`;

export const StatusLabel = styled.span`
  ${({ theme }) => css`
    padding: 4px 6px;
    margin-left: 4px;
    width: fit-content;
    z-index: 3;
    background-color: ${theme.colorPallete.primary.default};
    border-radius: 4px;
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.xxs};
    font-weight: ${theme.fonts.fontWeight.bold};
    position: fixed;
  `}
`;
