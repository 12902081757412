import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 119.3rem;

  @media all and (max-width: ${(props) => props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.7rem;
    }
  }

  .bullets__items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5rem;

    @media all and (max-width: ${(props) => props.theme.breakpoints.big}) {
      flex-direction: column;
      margin-top: 2.5rem;

      &__col__title {
        margin-top: 2.5rem;
      }
    }

    &__col {
      &__title {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 30rem;

        svg {
          margin-right: 2rem;
          min-width: 3rem;
          height: 3.4rem;
        }

        h3 {
          width: 27rem;
        }
      }

      &__items {
        ul {
          margin: 1.5rem 0 0 6.4em;

          li {
            font-size: 1.4rem;
            font-family: ${(props) => props.theme.fonts.fontFamily.sans};
            line-height: 3rem;
          }
        }
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
