/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { Button, Input, Text, IconSquareCheck } from '@components';

// theme
import { main } from '@themes';

// Helpers
import { polishingString } from '@helpers';

// Styles
import { FooterButton, FormStyles, LinkSection } from './form.styles';

const Form = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);

  const { error } = useSelector((state) => state.userReducer);

  const [blockedButton, setBlockedButton] = useState(false);

  const [keepMeLogged, toggleKeepMeLogged] = useState(false);

  useEffect(() => {
    if (error) {
      setBlockedButton(false);
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Digite um e-mail válido')
        .required('É obrigatório informar seu e-mail')
        .test(
          'is-possible',
          'O e-mail digitado não é valido',
          (email) =>
            email &&
            email.indexOf('@') !== -1 &&
            email.substr(0, email.indexOf('@')).match(/[A-Za-z]+/)
        ),
      password: Yup.string().required('É obrigatório informar sua senha'),
    }),
    onSubmit: async (values) => {
      setBlockedButton(true);
      dispatch(userActions.loginRequest({ data: { ...values, keepMeLogged } }));
    },
  });

  const { setFieldValue, handleSubmit } = formik;

  const handleKeepMeLogged = () => {
    toggleKeepMeLogged(!keepMeLogged);
  };

  const preventLazySubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <FormStyles method="post" onSubmit={(e) => preventLazySubmit(e)}>
      <div className="form__input">
        <Input
          label="E-mail"
          placeholder=" "
          name="email"
          type="text"
          id="email"
          value={formik.values.email}
          onBlur={(e) => setFieldValue('email', e.target.value.toLowerCase())}
          onChange={(e) =>
            setFieldValue('email', polishingString(e.target.value))
          }
          validation={
            formik.errors.email && formik.touched.email ? 'invalid' : null
          }
          validationMsg={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : ''
          }
          autoCorrect="off"
          autoCapitalize="none"
        />
      </div>

      <div className="form__input">
        <Input
          label="Senha"
          type="password"
          name="password"
          placeholder="******"
          id="password"
          value={formik.values.password}
          onChange={(e) =>
            setFieldValue('password', polishingString(e.target.value))
          }
          validation={
            formik.errors.password && formik.touched.password ? 'invalid' : null
          }
          validationMsg={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : ''
          }
          autoCorrect="off"
          autoCapitalize="none"
          onKeyDown={(e) => {
            if (e.keyCode === 9 || e.which === 9 || e.key === 'Tab') {
              e.preventDefault();
              buttonRef.current.focus();
            }
          }}
        />
      </div>
      <LinkSection>
        <div className="cb-keep-me-logged">
          <input
            type="checkbox"
            id="keep-me-logged"
            value={keepMeLogged}
            onChange={handleKeepMeLogged}
          />

          <label htmlFor="keep-me-logged">
            <span className="styled-checkbox">
              <IconSquareCheck
                size="18px"
                color={main.colorPallete.primary.default}
              />
            </span>
            <Text
              as="span"
              size="1rem"
              dsize="1rem"
              color={main.colorPallete.base[400]}
              weight="bold"
              height="normal"
              dheight="normal"
              transform="uppercase"
              cursor="pointer"
            >
              MANTENHA-ME CONECTADO
            </Text>
          </label>
        </div>

        <Link href="/esqueci-minha-senha" id="login__link__recover-password">

          <Text
            as="span"
            size="1rem"
            dsize="1rem"
            color={main.colorPallete.base[400]}
            weight="bold"
            height="normal"
            dheight="normal"
            transform="uppercase"
            decoration="underline"
            cursor="pointer"
          >
            Esqueci minha senha
          </Text>

        </Link>
      </LinkSection>
      <FooterButton>
        <Button
          id="login__button__enter"
          variant="primary"
          size="medium"
          type="submit"
          ref={buttonRef}
          disabled={blockedButton}
        >
          Entrar
        </Button>

        <a
          href="https://checkout.meusucesso.com/"
          rel="noreferrer"
          target="_blank"
          id="login__link__register-account"
        >
          <Text
            as="span"
            size="1rem"
            dsize="1rem"
            color={main.colorPallete.base[400]}
            weight="bold"
            height="normal"
            dheight="normal"
            transform="uppercase"
            decoration="underline"
            cursor="pointer"
          >
            Não tenho uma conta
          </Text>
        </a>
      </FooterButton>
    </FormStyles>
  );
};

export { Form };
export default Form;
