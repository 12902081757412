import styled from 'styled-components';

export const Wrapper = styled.main`
  width: 100%;
  padding: 0 0 2rem;
  position: relative;

  .page-loading {
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
  }
`;

export const LoadingMessage = styled.div`
  width: 100%;
  min-height: 25px;
  text-align: center;

  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 12px;

  padding-inline: 16px;

  @media (min-width: 360px) {
    a {
      margin-inline: auto;
    }
  }

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    a {
      min-width: 230px;
    }
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 769px) {
    h4 {
      font-size: 14px;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1500px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 32px;
  }
`;

export const SkeletonContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 270px;
  min-height: 360px;

  @media (min-width: 2100px) {
    max-width: 100%;
    max-height: 315px;
  }
`;
