import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { logoutHandler } from '@utils';

// Components
import { Button } from '@components';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Styles
import { styles } from './ExitButtonContainer.styles';

const Wrapper = styled.div`
  ${styles}
`;

const ExitButtonContainer = () => {
  const dispatch = useDispatch();

  const logOut = () => logoutHandler(dispatch);

  return (
    <Wrapper className="exit-button-container">
      <Button
        variant="secondary"
        size="big"
        type="button"
        onClick={logOut}
        id="profile__button__logout"
      >
        Sair do meuSucesso.com
      </Button>
    </Wrapper>
  );
};

export default ExitButtonContainer;
export { ExitButtonContainer };
