import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/vimeo';
import { IconClose } from '@components';
import { main as theme } from '@themes';

import { styles } from './VideoModal.styles';

const Wrapper = styled.div`
  ${styles}
`;

const VideoModal = React.memo(({ video, visible, toggleOpenedVideo }) => {
  const modclass = classNames({
    visible: visible === true,
  });

  return (
    <Wrapper className={modclass} onClick={() => toggleOpenedVideo(!visible)}>
      <div className="video-container">
        {video.vimeo_id && (
          <>
            <div className="close-modal">
              <button type="button" onClick={() => toggleOpenedVideo(!visible)}>
                <IconClose size="22px" color={theme.colorPallete.white} />
              </button>
            </div>

            {visible && (
              <div className="video-wrap">
                <ReactPlayer
                  controls
                  config={{
                    vimeo: {
                      playerOptions: {
                        autoplay: true,
                        color: theme.colorPallete.primary.default,
                        loop: false,
                        portrait: false,
                        title: false,
                      },
                    },
                  }}
                  id="lesson-vid"
                  loop={false}
                  width="100%"
                  height="100%"
                  url={`https://vimeo.com/${video.vimeo_id}`}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
});

VideoModal.propTypes = {
  video: PropTypes.shape({
    vimeo_id: PropTypes.string,
  }),
  visible: PropTypes.bool,
  toggleOpenedVideo: PropTypes.func,
};

VideoModal.defaultProps = {
  video: {},
  visible: false,
  toggleOpenedVideo: () => '',
};

export default VideoModal;
export { VideoModal };
