import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.bigger};
  padding: 2rem 0;
  margin: 0 auto;

  header {
    padding: 2rem 0;
  }

  .faq-list {
    article {
      margin: 0 0 4rem;
      max-width: 100%;
      width: ${(props) => props.theme.breakpoints.medium};

      p {
        margin: 0 0 2rem;
      }

      a {
        margin-top: 2rem;
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
