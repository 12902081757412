import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { useGetCertificateInformation } from '@libs/ms-api-react-query';
import { msApiLib } from 'services/config/ms-api';
import { checkFeatureToggle } from '@utils';

import { LoggedIn } from '@templates';
import { PrivateCertificateContent } from './containers';

const PrivateCertificate = () => {
  const refreshToken = Cookies.get('refreshtokenClient');
  const router = useRouter();

  const {
    userReducer: { userData },
  } = useSelector((state: any) => state);

  const { data: certificateContent, isError } = useGetCertificateInformation(
    msApiLib,
    {
      enabled: !!userData && checkFeatureToggle(userData, ['certificates']),
      params: {
        studentId: userData?.id,
        courseId: router.query.courseId as string,
        certificateId: router.query.id as string,
      },
    },
  );

  useEffect(() => {
    if (!!userData && !checkFeatureToggle(userData, ['certificates']))
      router.replace('/404');
  }, [userData]);

  if (isError) router.push('/404');

  useEffect(() => {
    if (!!userData && (!userData?.social_name || userData?.social_name === ''))
      router.push('/confirmar-nome');
  }, [userData?.social_name]);

  return (
    <>
      <LoggedIn
        refreshToken={refreshToken}
        titlePage={`Certificado ${
          certificateContent ? ` - ${certificateContent?.course?.title}` : ''
        }`}
        modalActive={false}
        showingModal={false}>
        <PrivateCertificateContent certificateContent={certificateContent} />
      </LoggedIn>
    </>
  );
};

export { PrivateCertificate };
export default PrivateCertificate;
