import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

export const IFrameContainer = styled.div`
  position: relative;

  & > div {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 4px;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
`;

export const NextButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    z-index: 99;
    right: 2%;
    bottom: 12%;
    display: flex;
    align-items: center;
    gap: 14px;
    padding-left: 48px;
    padding-right: 36px;
    height: 44px;
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    background-color: ${theme.colorPallete.primary[400]};
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
    }

    :hover {
      background-color: ${theme.colorPallete.primary[300]};
    }
  `}
`;
