import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  background-color: transparent;

  .paper {
    display: block;

    .block__title {
      margin-bottom: 3rem;
    }

    .block__content {
      max-width: 60rem;
      display: grid;
      grid-template-areas:
        'flag cardnumber'
        'cardholder expiration'
        'security security'
        'button button';
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;

      h4 {
        margin-bottom: 1rem;
      }

      .block__content__flag {
        grid-area: flag;
      }

      .block__content__cardnumber {
        grid-area: cardnumber;
      }

      .block__content__cardholder {
        grid-area: cardholder;
      }

      .block__content__expiration {
        grid-area: expiration;
      }

      .block__content__security {
        grid-area: security;
      }

      .block__content__button {
        max-width: 31.5rem;
        grid-area: button;
      }

      @media all and (min-width: ${theme.breakpoints.medium}) {
        grid-template-areas:
          'flag cardnumber expiration'
          'cardholder cardholder security'
          'button button button';
      }
    }
  }
`;

export default styles;
export { styles };
