import React from 'react';
import PropTypes from 'prop-types';

// Themes
import { main as theme } from '@themes';

// Global Components
import { IconLeftArrow, Text } from '@components';

const BackButton = ({ courseName }) => {
  return (
    <>
      <IconLeftArrow color={theme.colorPallete.white} />
      <Text
        className="goback__text"
        size="1.4rem"
        color={theme.colorPallete.white}
        weight={theme.fonts.fontWeight.bold}
      >
        {courseName && courseName !== 'Biblioteca' ? courseName : `Voltar`}
      </Text>
    </>
  );
};

BackButton.propTypes = {
  courseName: PropTypes.string,
};

BackButton.defaultProps = {
  courseName: '',
};

export { BackButton };
export default BackButton;
