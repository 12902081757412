import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    min-height: 104px;
    width: 100%;
    display: flex;
    justify-content: center;

    h3,
    p {
      font-family: ${theme.fonts.fontFamily.sans};
      font-weight: ${theme.fonts.fontWeight.bold};
    }

    h3 {
      color: ${theme.colorPallete.base[220]};
      font-size: 16px;
      margin-bottom: 12px;
    }

    p {
      color: ${theme.colorPallete.white};
      font-size: 18px;
    }

    .comments__content {
      width: 100%;
      max-width: 116rem;
      padding: 4rem 2rem;

      @media all and (max-width: ${(props) => props.theme.breakpoints.small}) {
        padding: 4rem 2rem;
      }

      &__form {
        margin-top: 5rem;
        @media all and (max-width: ${(props) =>
            props.theme.breakpoints.small}) {
          margin-top: 2.5rem;
        }
      }

      &__items {
        &__card {
          &__answer {
            margin-left: 4.6rem;
          }
        }
      }
    }
  `}
`;

export { Wrapper };
export default Wrapper;
