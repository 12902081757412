import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { IconLoading, Text } from '@components';

// Styles
import { Wrapper } from './CatalogList.styles';

// Containers
import { Keywords, ListVideos } from './containers';

const CatalogList = ({ content, refreshToken }) => {
  const { cleanLibraryLoading } = useSelector((state) => state.libraryReducer);

  return (
    <Wrapper>
      <header>
        <Text as="h1" dsize="2.2rem" size="1.8rem" weight="bold" dheight="180%">
          Biblioteca
        </Text>
        <Text as="p" dsize="1.4rem" dheight="150%">
          Encontre o que você precisa de um jeito rápido.
        </Text>
      </header>

      <div className="catalog-list__filter">
        <div className="catalog-list__filter-tags">
          <Keywords refreshToken={refreshToken} />
        </div>
      </div>
      {content &&
        (cleanLibraryLoading ? (
          <div className="loading-holder">
            <IconLoading />
          </div>
        ) : (
          <ListVideos />
        ))}
    </Wrapper>
  );
};

CatalogList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      contents: PropTypes.instanceOf(Array),
      order: PropTypes.number,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  refreshToken: PropTypes.string,
};

CatalogList.defaultProps = {
  content: {
    contents: [],
    order: 0,
    subtitle: null,
    title: null,
  },
  refreshToken: '',
};

export { CatalogList };
export default CatalogList;
