import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;

  .course__week {
    /* padding-right: 2rem; */

    @media all and (min-width: ${theme.breakpoints.big}) {
      padding: 0 2rem;
    }

    .course__week-title {
      display: flex;
      align-items: center;

      padding: 0 2rem;

      @media all and (min-width: ${theme.breakpoints.big}) {
        padding: 0;
      }

      span {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.2rem;
        height: 4.2rem;
        max-width: 4.2rem;
        max-height: 4.2rem;
        min-width: 4.2rem;
        min-height: 4.2rem;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        margin-right: 1rem;
      }
    }

    .course__week-content {
      padding-top: 1.8rem;
      padding-bottom: 3.6rem;

      .course__week__card {
        padding-bottom: 2rem;

        &:last-child {
          padding-right: 2rem;
          @media all and (min-width: ${theme.breakpoints.big}) {
            padding-right: 0;
          }
        }
      }
    }
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    .course__week {
      .course__week-content {
        border-left: 0.6rem solid rgba(255, 255, 255, 0.2);
        padding: 1.8rem 0 3.6rem 2.8rem;
        margin-left: 1.8rem;
      }

      &:last-child {
        .course__week-content {
          border-left: 0.6rem solid rgba(255, 255, 255, 0);
        }
      }
    }
  }
`;

export default styles;
export { styles };
