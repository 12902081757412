/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Templates
import { LoggedIn } from '@templates';

// Skeletons
import { TitleSkeleton, TagbarSkeleton, VideoListSkeleton } from '@skeletons';

// Containers
import { Button, CatalogList, Banner } from './containers';

// Styles
import { Wrapper } from './Library.styles';

const Library = ({ refreshToken }) => {
  const libraryData = useSelector((state) => state.libraryReducer.libraryData);

  const [lessonContent, setLessonContent] = useState(false);

  useEffect(() => {
    if (!lessonContent && libraryData?.lessons) {
      setLessonContent(libraryData.lessons[0].contents);
    }
  }, [lessonContent, libraryData]);

  return (
    <LoggedIn
      refreshToken={refreshToken}
      titlePage="Biblioteca"
    >
      <Wrapper>
        {libraryData && libraryData?.banner && (
          <Banner content={libraryData.banner.contents[0]} />
        )}

        {libraryData && libraryData.lessons.length > 0 ? (
          <>
            <CatalogList
              content={libraryData.lessons[0].contents}
              refreshToken={refreshToken}
            />
            <Button
              content={lessonContent}
              setLessonContent={setLessonContent}
              refreshToken={refreshToken}
            />
          </>
        ) : (
          <div className="skeleton">
            <TitleSkeleton />
            <TagbarSkeleton />
            <VideoListSkeleton />
          </div>
        )}
      </Wrapper>
    </LoggedIn>
  );
};

Library.propTypes = {
  refreshToken: PropTypes.string,
};

Library.defaultProps = {
  refreshToken: '',
};

export { Library };
export default Library;
