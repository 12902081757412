/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { LoggedIn } from '@templates';

// NOTIFICATION COMPONENT (SHALL BE REMOVED AS SOON AS AN ENDPOINT IS CREATED)
import { Text, Notification } from '@components';

import { SlideModal } from '@containers';

// Actions
import { actions as userActions } from '@store/ducks/user';

import { main as theme } from '@themes';

// PAGE CONTAINERS
import {
  ExitButtonContainer,
  Notifications,
  UserAccountInfo,
  UserAddress,
  UserData,
  UserFinancialData,
  UserSubscriptionInfo,
  WatchLessonZero,
  EditUserAddress,
  EditUserData,
} from './containers';

// LESSON ZERO MODAL
// import { LessonZeroModal } from '../Home/containers';

// Styles
import { styles } from './Profile.styles';

const Wrapper = styled.div`
  ${styles}
`;

const Profile = ({ refreshToken }) => {
  const { editModal, profileData } = useSelector((state) => state.userReducer);

  // const [lessonZero, toggle] = useState(false);

  const dispatch = useDispatch();

  const {
    userReducer: { userData },
  } = useSelector((state) => state);

  const loadEditUser = useCallback(() => {
    return dispatch(
      userActions.setEditModal({
        payload: {
          editModal: <EditUserData refreshToken={refreshToken} />,
        },
      }),
    );
  }, [dispatch, refreshToken]);

  const loadEditAddress = useCallback(() => {
    return dispatch(
      userActions.setEditModal({
        payload: {
          editModal: <EditUserAddress refreshToken={refreshToken} />,
        },
      }),
    );
  }, [dispatch, refreshToken]);

  useEffect(() => {
    if (profileData?.profile?.restriction) {
      switch (profileData.profile.restriction) {
        case 'document':
          loadEditUser();
          break;
        case 'address':
          loadEditAddress();
          break;
        default:
          break;
      }
    }
  }, [profileData]);

  return (
    <>
      <SlideModal showing={!!editModal}>{editModal}</SlideModal>
      {/* <LessonZeroModal
      location="profile"
      visible={lessonZero}
      toggleOpenedVideo={toggle}
      video={
        _.get(userData, 'user_trial.account_status', 'Trialing') === 'Active'
          ? '458339329'
          : '454571640'
      }
    /> */}

      <LoggedIn titlePage="Perfil" modalActive={editModal}>
        <Wrapper>
          <section className="notifications__container">
            {profileData?.profile?.restriction === 'defaulting' && (
              <Notifications>
                <Notification
                  colorStrip={theme.colorSystem.danger}
                  noclose
                  text='Olá. Seu pagamento ainda não foi confirmado. Para ter acesso completo à plataforma, insira um cartão de crédito válido na área de "Dados financeiros" ou efetue seu pagamento clicando na fatura vencida. Caso precise de suporte, entre em contato pelo FAQ.'
                />
              </Notifications>
            )}

            {!profileData?.profile?.restriction &&
              profileData?.profile?.complient === 'active_defaulting' && (
                <Notification
                  colorStrip="#EE9191"
                  colorBg={theme.colorSystem.danger}
                  title={
                    <>
                      <Text
                        as="p"
                        size="1.6rem"
                        dsize="1.6rem"
                        family={theme.fonts.fontFamily.sans}
                        weight={theme.fonts.fontWeight.bold}
                        color={theme.colorPallete.white}>
                        Há pendências nos seus pagamentos.
                      </Text>

                      <br />
                      <Text
                        as="p"
                        size="1.6rem"
                        dsize="1.6rem"
                        family={theme.fonts.fontFamily.sans}
                        weight={theme.fonts.fontWeight.bold}
                        color={theme.colorPallete.white}>
                        Está precisando de ajuda?
                      </Text>
                      <Link href="/historico-financeiro" target="_blank">
                        <Text
                          as="p"
                          size="1.6rem"
                          dsize="1.6rem"
                          family={theme.fonts.fontFamily.sans}
                          weight={theme.fonts.fontWeight.bold}
                          color={theme.colorPallete.white}
                          decoration="underline">
                          Clique aqui.
                        </Text>
                      </Link>
                    </>
                  }
                  noclose
                />
              )}
          </section>
          <section className="account">
            <Text
              as="h2"
              size="1.8rem"
              dsize="2.2rem"
              weight="bold"
              color={theme.colorPallete.white}>
              Conta
            </Text>
            {profileData && (
              <div className="account-containers">
                <UserData refreshToken={refreshToken} />
                <UserAddress refreshToken={refreshToken} />
                <UserAccountInfo refreshToken={refreshToken} />
                <UserSubscriptionInfo />
                <UserFinancialData refreshToken={refreshToken} />
                <ExitButtonContainer />
              </div>
            )}
          </section>
          {/* <section
          id="profile__first-class__button"
          className="lesson-zero-button"
          onClick={() => toggle(!lessonZero)}
        >
          <WatchLessonZero />
        </section> */}
        </Wrapper>
      </LoggedIn>
    </>
  );
};

Profile.propTypes = {
  refreshToken: PropTypes.string,
};

Profile.defaultProps = {
  refreshToken: '',
};

export default Profile;
export { Profile };
