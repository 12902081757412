import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  margin: 0 0 40px;

  .search-video {
    &__item {
      position: relative;
    }

    &__thumb {
      picture {
        z-index: 0;
      }

      .thumb__progress {
        height: 0.8rem;
      }

      &:hover {
        .thumb__play-icon {
          svg,
          path {
            fill: ${theme.colorPallete.primary.default};
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }

    &__title {
      margin-top: 1rem;
      margin-bottom: 0.4rem;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4rem 3rem;

      @media all and (min-width: ${(props) => props.theme.breakpoints.small}) {
        /* grid-template-columns: repeat(2, 1fr); */
      }

      @media all and (min-width: ${(props) => props.theme.breakpoints.medium}) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media all and (min-width: ${(props) => props.theme.breakpoints.big}) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
`;

export { styles };
export default styles;
