import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  margin: 0 0 40px;

  .my-list-video {
    &__item {
      position: relative;
    }

    &__thumb {
      picture {
        z-index: 0;
      }

      .thumb__progress {
        height: 0.8rem;
      }

      &:hover {
        .thumb__play-icon {
          svg,
          path {
            fill: ${theme.colorPallete.primary.default};
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }

    &__title {
      margin-top: 1rem;
      margin-bottom: 0.4rem;
    }

    &__favorite {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;
      z-index: 5;
    }

    &__fav-button {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      cursor: pointer;

      .check {
        display: block;
      }

      .uncheck {
        display: none;
      }

      .label {
        position: absolute;
        right: calc(100% + 10px);
        top: 20%;
        display: block;
        background: rgba(255, 255, 255, 0.5);

        border-radius: 4px;
        padding: 4px;
        width: 100px;
        opacity: 0;
        pointer-events: none;

        transition: all 0.2s ease-in-out;
      }

      &:hover {
        .check {
          display: none;
        }

        .uncheck {
          display: block;
        }

        .label {
          top: 0%;
          opacity: 1;

          transition: all 0.2s ease-in-out;
        }
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4rem 3rem;

      @media all and (min-width: ${(props) => props.theme.breakpoints.small}) {
        /* grid-template-columns: repeat(2, 1fr); */
      }

      @media all and (min-width: ${(props) => props.theme.breakpoints.medium}) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media all and (min-width: ${(props) => props.theme.breakpoints.big}) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
`;

export { styles };
export default styles;
