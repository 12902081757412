import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import ReactPlayer from 'react-player/vimeo';

// Theme
import { main as theme } from '@themes';

// Components
import { Button, Logo, Text } from '@components';

// Styles
import { styles } from './ClassZero.styles';

// ------------------------------------------------------------------------- //

const Wrapper = styled.main`
  ${styles}
`;

const ClassZero = () => {
  const router = useRouter();

  const handleClick = useCallback(() => {
    router.push('/home');
  }, []);

  return (
    <Wrapper>
      <div className="page__header">
        <div className="page__header__logo">
          <Logo size="220px" color="#fff" />
        </div>
      </div>

      <div className="page__body">
        <Text
          className="page__body__title"
          size="2.6rem"
          dsize="1.4rem"
          weight={theme.fonts.fontWeight.bold}
          color={theme.colorPallete.primary.default}
        >
          Aula Zero
        </Text>

        <div className="page__body__container">
          <Text
            className="page__body__container__title"
            as="h1"
            size="1.6rem"
            dsize="6rem"
            weight={theme.fonts.fontWeight.bold}
            family={theme.fonts.fontFamily.serif}
            color={theme.colorPallete.base.default}
          >
            Assistir Aula Zero
          </Text>

          <div className="page__body__container__content">
            <div className="page__body__container__content__info">
              <div className="video-container">
                <ReactPlayer
                  controls
                  config={{
                    vimeo: {
                      playerOptions: {
                        autoplay: false,
                        color: theme.colorPallete.primary.default,
                        loop: false,
                        portrait: false,
                        title: false,
                      },
                    },
                  }}
                  id="lesson-vid"
                  loop={false}
                  width="100%"
                  height="100%"
                  url="https://vimeo.com/458339329"
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="page__body__container__content__button">
              <Button
                variant="hollow"
                size="big"
                id="cancel__button__close"
                onClick={handleClick}
              >
                Voltar para a plataforma
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="page__footer">
        <Text
          as="p"
          size="1.3rem"
          dsize="1.3rem"
          color={theme.colorPallete.base[300]}
        >
          meusucesso.com© Copyright 2014-2021. powered by meuSucesso.com
        </Text>
      </div>
      <div id="nchat" chat-sdk="___$_6"></div>
    </Wrapper>
  );
};

export { ClassZero };
export default ClassZero;
