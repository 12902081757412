import React from 'react';
import PropTypes from 'prop-types';

// Componentes
import { Text } from '@components';

// Containers
import { PosterListStatic } from '@containers';

// Styles
import { Wrapper } from './CatalogList.styles';

const CatalogList = ({ content, title }) => {
  return (
    <Wrapper>
      <header>
        <Text as="h2" dsize="2.2rem" weight="bold" dheight="180%">
          <span>{title}</span>
        </Text>
      </header>
      {content && <PosterListStatic content={content} wrap />}
    </Wrapper>
  );
};

CatalogList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      contents: PropTypes.instanceOf(Array),
      order: PropTypes.number,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

CatalogList.defaultProps = {
  content: {
    contents: [],
    order: 0,
    subtitle: null,
    title: null,
  },
  title: null,
};

export { CatalogList };
export default CatalogList;
