import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Containers
import { CardUser } from '../CardUser';
import { Form } from '../Form';

// Styles
import { Wrapper } from './CommentCard.styles';

const CommentCard = ({ comment, blockAnswer, refreshToken, lessonId }) => {
  const [formOpened, toggleFormOpened] = useState(false);

  return (
    <Wrapper
      key={`comment-${comment.id}`}
      formOpened={formOpened}
      blockAnswer={blockAnswer}
    >
      <CardUser
        comment={comment}
        formOpened={formOpened}
        blockAnswer={blockAnswer}
        toggleFormOpened={toggleFormOpened}
      />
      <div className="form">
        <Form
          variant="secondary"
          refreshToken={refreshToken}
          lessonId={lessonId}
          commentId={comment.id}
        />
      </div>
    </Wrapper>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
  }),
  blockAnswer: PropTypes.bool,
  refreshToken: PropTypes.string,
  lessonId: PropTypes.string,
};

CommentCard.defaultProps = {
  comment: {},
  blockAnswer: false,
  refreshToken: '',
  lessonId: '',
};

export { CommentCard };
export default CommentCard;
