import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    min-width: none;
  }
`;

export const TextContainer = styled.div`
  p {
    margin-block: 3%;
  }
`;

export const FullName = styled.span`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[400]};
  `}
`;

export const Form = styled.form`
  @media (max-width: 768px) {
    min-width: none;
  }
`;

export const InputContainer = styled.div`
  margin-top: 2.4rem;
  height: 100px;
`;

export const CertificateTemplate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  width: 85%;
  margin-top: 2%;
  aspect-ratio: 1.4;
  padding-block: 12px;

  background-color: #efefef;
  border-bottom: 18px solid #fe7000;
  border-radius: 2px;

  .icon-certificate {
    margin-bottom: 8px;
  }

  .icon-watermark {
    margin-top: 8px;
  }

  .subtext {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextplaceHolder = styled.div`
  ${({ theme, size }) => css`
    width: ${size};
    height: 10px;
    border-radius: 4px;
    background-color: ${theme.colorPallete.base[200]};
  `}
`;

export const SocialName = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorPallete.primary[200]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.g};
    font-weight: ${theme.fonts.fontWeight.bold};
    text-align: center;
    max-width: 90%;
    height: 25px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 4rem 0 0 0;
  gap: 25px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;
