import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Actions
import { actions as libraryActions } from '@store/ducks/library';

// Components
import { IconLoading, Text } from '@components';

// Themes
import { main as theme } from '@themes';

// Styles
import { Wrapper } from './Button.styles';

const Button = ({ refreshToken }) => {
  const {
    libraryDataPage,
    libraryData: { tags },
    loading,
    cleanLibraryLoading,
    libraryLength,
  } = useSelector((state) => state.libraryReducer);

  const dispatch = useDispatch();

  const handleOnCLick = async () => {
    const activeTag = tags.filter((tag) => tag.active);

    let params = {
      page: libraryDataPage + 1,
    };

    if (activeTag.length > 0) {
      params = {
        ...params,
        tag: activeTag[0].slug,
      };
    }

    dispatch(
      libraryActions.getLibraryRequest({
        payload: {
          params,
          auth: {
            refresh_token: refreshToken,
          },
        },
      })
    );
  };

  return (
    <Wrapper>
      {loading && !cleanLibraryLoading ? (
        <IconLoading />
      ) : (
        !cleanLibraryLoading &&
        libraryLength > 0 && (
          <button
            type="button"
            onClick={handleOnCLick}
            disabled={cleanLibraryLoading}
            className={classnames({ disabled: cleanLibraryLoading })}
            id="library__button__load-more"
          >
            <Text
              as="h3"
              dsize="1.6rem"
              size="1.6rem"
              dheight="1.8rem"
              color={theme.colorSystem.white}
            >
              Carregar mais
            </Text>
          </button>
        )
      )}
    </Wrapper>
  );
};

Button.propTypes = {
  refreshToken: PropTypes.string,
};

Button.defaultProps = {
  refreshToken: null,
};

export { Button };
export default Button;
