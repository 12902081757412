import React from 'react';

// Components
import { Logo, Text } from '@components';

// Themes
import { main } from '@themes';

// Templates
import { SignUp } from '@templates';

// Styles
import { Wrapper, InputSection, TitleSection } from './ForgotPassword.styles';

import { Form } from './containers';

const ForgotPassword = () => {
  return (
    <>
      <SignUp titlePage="Esqueci minha senha" highlight=" ">
        <Wrapper>
          <div className="mobile-only">
            <Logo size="150px" />
          </div>

          <TitleSection>
            <Text
              as="h6"
              size="1.6rem"
              dsize="1.6rem"
              color={main.colorSystem.baseColor400}
              weight="bold"
              height="normal"
              dheight="normal">
              Redefinir Senha
            </Text>
            <Text
              as="h1"
              size="2rem"
              dsize="2rem"
              color={main.colorSystem.baseColor}
              family={main.fonts.fontFamily.serif}
              weight="bold"
              height="normal"
              dheight="normal">
              Informe o mesmo e-mail que utilizou ao criar sua conta no
              meuSucesso.com.
            </Text>
          </TitleSection>
          <InputSection>
            <Form />
          </InputSection>
        </Wrapper>
      </SignUp>
    </>
  );
};

export { ForgotPassword };
export default ForgotPassword;
