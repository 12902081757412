/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { Button, Input } from '@components';

// Helpers
import { polishingString } from '@helpers';

// Styles
import { FooterButton, FormStyles } from './form.styles';

const Form = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Digite um e-mail válido')
        .required('Campo e-mail obrigatório')
        .test(
          'is-possible',
          'O e-mail digitado não é valido',
          (email) =>
            email &&
            email.indexOf('@') !== -1 &&
            email.substr(0, email.indexOf('@')).match(/[A-Za-z]+/)
        ),
    }),
    onSubmit: async (values) => {
      dispatch(userActions.forgotPasswordEmailRequest({ data: values }));
    },
  });

  return (
    <FormStyles onSubmit={formik.handleSubmit} method="post">
      <div className="form__input">
        <Input
          label="E-mail"
          type="text"
          size="medium"
          placeholder=""
          name="email"
          id="email"
          value={formik.values.email}
          onBlur={(e) =>
            formik.setFieldValue('email', e.target.value.toLowerCase())
          }
          onChange={(e) =>
            formik.setFieldValue('email', polishingString(e.target.value))
          }
          validation={
            formik.errors.email && formik.touched.email ? 'invalid' : null
          }
          validationMsg={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : ''
          }
        />
      </div>

      <FooterButton>
        <Button
          variant="primary"
          size="medium"
          type="submit"
          id="forgot-pass__button__send"
        >
          Enviar e-mail
        </Button>
      </FooterButton>
    </FormStyles>
  );
};

export { Form };
export default Form;
