import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  height: 14rem;
  min-height: 14rem;
  overflow: hidden;
  padding: 1.2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  border-radius: 0.4rem;
  background-color: #222222;
  background-image: url('/assets/images/aulazero-dois.jpg');
  background-position: right;
  background-size: auto;
  background-repeat: no-repeat;

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .button-title,
    .play-icon {
      opacity: 0.7;
    }
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 10rem;
  }
`;

export default styles;
export { styles };
