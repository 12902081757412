import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'helpers/useWindowSize';
import { LoggedIn } from '@templates';
import { LessonContentSkeleton } from '@skeletons';
import { LessonContent } from './containers';
import {
  useGetLessonData,
  useGetCheckCourseProgress,
} from '@libs/ms-api-react-query';
import { msApiLib } from '@services/config/ms-api';
import Cookies from 'js-cookie';

const Lesson = () => {
  const [hasWaited, setHasWaited] = useState(false);
  const [courseProgressState, setCourseProgressState] = useState(undefined);

  const { query } = useRouter();

  const refreshToken = Cookies.get('refreshtokenClient');

  const { width } = useWindowSize();
  let isTablet = true;
  if (!!width) {
    isTablet = width <= 1024;
  }

  const {
    userReducer: { userData },
  } = useSelector((state: any) => state);

  const {
    courseReducer: { courseModal },
  } = useSelector((state: any) => state);

  const { data: lessonData } = useGetLessonData(msApiLib, {
    enabled: true,
    slug: query.aula as string,
  });

  const { data: courseProgress } = useGetCheckCourseProgress(msApiLib, {
    studentId: userData?.id,
    courseId: lessonData?.course?.id,
    lessonId: lessonData?.id,
    enabled: hasWaited,
  });

  async function sendDataLayer() {
    if (lessonData?.slug) {
      let strQuery = lessonData.slug;
      let arr = [];
      strQuery = strQuery.toString().replace(/-/g, ' ');
      arr = strQuery.split(' ');
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      let str2 = arr.join(' ');

      //@ts-ignore
      window.dataLayer = window.dataLayer || [];
      //@ts-ignore
      window.dataLayer.push({
        event: 'virtualPageview',
        pageUrl: window.location.href,
        curso: str2,
      });
    } else {
      return null;
    }
  }

  useEffect(() => {
    setHasWaited(false);
    sendDataLayer();

    if (!!lessonData) {
      setTimeout(() => {
        setHasWaited(true);
      }, 10000);
    }
  }, [query?.aula, lessonData]);

  useEffect(() => {
    setCourseProgressState(courseProgress);
  }, [courseProgress, hasWaited, lessonData?.course?.id]);

  return (
    <>
      <LoggedIn
        titlePage={lessonData?.name || 'Lição'}
        modalActive={courseModal}>
        {lessonData ? (
          <LessonContent
            lessonData={lessonData}
            courseProgress={courseProgressState}
          />
        ) : (
          <LessonContentSkeleton isTablet={isTablet} />
        )}
      </LoggedIn>
    </>
  );
};

export { Lesson };
export default Lesson;
