import styled, { css } from 'styled-components';

import { main as theme } from 'themes';

export const Wrapper = styled.section`
  width: 100%;
  padding: 2rem;
  padding-top: 9.1rem;

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding-top: 5rem;
  }

  section {
    padding-left: 8px;
  }

  header {
    padding: 2rem 8px;
  }

  .subtitleCatalog {
    span {
      color: #fe7000;
      font-weight: bold;
    }
  }
`;

export const TitleContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 2rem 8px;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: 22px;
    color: #ffffff;
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.regular};
    font-size: 14px;
    color: #ffffff;

    span {
      font-weight: ${theme.fonts.fontWeight.bold};
      color: ${theme.colorSystem.primaryColor};
    }
  `}
`;
