import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  img,
  .comment-avatar {
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 3.6rem;
    margin-right: 1rem;
  }

  .info {
    width: 100%;

    &__time {
      margin-top: 0.6rem;
    }
    &__comment {
      margin-top: 1.2rem;
      word-wrap: break-word;
    }
    &__buttons {
      display: ${(props) => (props.blockAnswer ? 'none' : 'block')};
      margin-top: 1.2rem;

      button {
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
