import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IconArrow } from 'components';
import { ClassCard } from '../ClassCard';

import * as S from './CarouselCards.styles';
import { Week } from '../../types';
import { ModulesDto } from '@libs/ms-api';

interface CarouselCardsProps {
  module: ModulesDto;
}

const CarouselCards = ({ module }: CarouselCardsProps) => {
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    draggable: true,
    speed: 1000,
    rows: 1,
    slidesToShow: 6,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    centerMode: false,
    prevArrow: (
      <S.NavigationArrow className="arrow__previous" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    nextArrow: (
      <S.NavigationArrow className="arrow__next" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1850,
        settings: {
          slidesToShow: 4.5,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1335,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 790,
        settings: {
          unslick: true,
        },
      },
    ],
  };

  if (!module) return null;

  return (
    <S.Wrapper>
      <S.SliderContainer>
        <Slider {...settings}>
          {module.lessons &&
            module.lessons.map((lesson, index) => (
              <ClassCard lesson={lesson} moduleNumber={index + 1} />
            ))}
        </Slider>
      </S.SliderContainer>
    </S.Wrapper>
  );
};

export default CarouselCards;
export { CarouselCards };
