import React from 'react';
import PropTypes from 'prop-types';

// Components
import { IconBookmark, IconPen, IconOpenBook } from '@components';

const ToggleIcon = ({ id }) => {
  if (id === 0) {
    return <IconOpenBook size="27px" />;
  }
  if (id === 1) {
    return <IconPen size="27px" />;
  }
  return <IconBookmark size="27px" />;
};

ToggleIcon.propTypes = {
  id: PropTypes.number.isRequired,
};

export { ToggleIcon };
export default ToggleIcon;
