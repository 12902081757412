import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  background: ${theme.colorPallete.base[600]};
  padding: 6rem 2rem;
  background-image: linear-gradient(
    ${(props) => props.gradient_start},
    ${(props) => props.gradient_end}
  );

  /* background-image: url('/assets/images/extra-content-bg.png');
  background-size: cover;
  background-position: center; */

  .extra-section {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;

    .extra-section__title {
      margin-bottom: 3rem;
    }

    .course__week {
      padding: 0;

      .course__week-title {
        display: flex;
        align-items: center;

        span {
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 4.2rem;
          height: 4.2rem;
          max-width: 4.2rem;
          max-height: 4.2rem;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 100%;
          margin-right: 1rem;
        }
      }

      .course__week-content {
        padding-top: 1.8rem;
        padding-bottom: 3.6rem;

        .course__week__card {
          padding-bottom: 2rem;

          .course__week__card--item {
            background-color: ${theme.colorPallete.base.default};
            width: 100%;

            .info__lesson-title {
              color: ${theme.colorPallete.white};
            }

            .info__lesson-title {
              color: ${theme.colorPallete.white};
            }

            .info__lesson-subtitle,
            .info__lesson-description,
            .info__buttons--download__text {
              color: ${theme.colorPallete.base[100]};
            }
          }
        }
      }
    }
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    .extra-section {
      padding-top: 6rem;

      .extra-section__title {
        padding-left: 2rem;
        margin-bottom: 6rem;
      }
      .course__week {
        padding: 0 2rem;
        padding-right: 4rem;

        .course__week-content {
          border-left: 0.6rem solid rgba(255, 255, 255, 0.2);
          padding: 1.8rem 0 3.6rem 2.8rem;
          margin-left: 1.8rem;
        }

        &:last-child {
          .course__week-content {
            border-left: 0.6rem solid rgba(255, 255, 255, 0);
          }
        }
      }
    }
  }
`;

export default styles;
export { styles };
