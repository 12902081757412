import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.bigger};
  margin: 0 auto;
  padding: 2rem 0;

  .block__keywords {
    display: flex;
    flex-wrap: wrap;

    .block__keywords--item {
      margin-right: 1.2rem;
      margin-bottom: 1.2rem;
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding: 2rem;
  }
`;

export default styles;
export { styles };
