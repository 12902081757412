/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { IconPencil, Paper, Text } from '@components';

// Helpers
import { CountriesArray } from '@helpers';

// Themes
import { main as theme } from '@themes';
import { styles } from './UserAddress.styles';

// Containers
import { EditUserAddress } from '../EditUserAddress';

const Wrapper = styled.div`
  ${styles}
`;

const UserAddress = ({ refreshToken }) => {
  const user = useSelector((state) => state.userReducer.profileData);

  const dispatch = useDispatch();

  const setShowModal = (value) => {
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));
  };

  const resolveZipcode = (zipcode) => {
    if (zipcode !== null) {
      return zipcode.replace(/^(\d{5})(\d{3}).*/, '$1-$2');
    }
    return '';
  };

  return (
    <Wrapper className="user-address">
      <Paper className="paper">
        <div className="block__title">
          <Text
            as="h3"
            size="1.8rem"
            dsize="1.8rem"
            weight="bold"
            color={theme.colorPallete.primary.default}
          >
            Endereço
          </Text>
          <button
            onClick={() =>
              setShowModal(<EditUserAddress refreshToken={refreshToken} />)
            }
            id="profile__button__edit-address"
          >
            <Text
              className="block-title__button-text"
              color={theme.colorPallete.base[400]}
              weight={theme.fonts.fontWeight.bold}
            >
              Editar
            </Text>
            <IconPencil color={theme.colorPallete.base[400]} />
          </button>
        </div>
        <div className="block__content">
          <div className="block__content--zipcode">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[400]}
              weight="bold"
            >
              {user?.profile?.country === 'BR' ? 'CEP' : 'Zip Code'}
            </Text>
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              {user?.profile?.country === 'BR' &&
              _.has(user.profile, 'zip_code')
                ? resolveZipcode(user.profile.zip_code)
                : ''}
            </Text>
          </div>
          <div className="block__content--address">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[400]}
              weight="bold"
            >
              {user?.profile?.country === 'BR' ? 'Endereço' : 'Linha 1'}
            </Text>
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              <>
                {`${user?.profile?.street}, ${user?.profile?.number || ''}` ||
                  ''}
              </>
            </Text>
          </div>
          <div className="block__content--complement">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[400]}
              weight="bold"
            >
              {(user?.profile?.country === 'BR' ? 'Complemento' : 'Linha 2') ||
                ''}
            </Text>
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              {user?.profile?.complement || ''}
            </Text>
          </div>
          <div className="block__content--country">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[400]}
              weight="bold"
            >
              País
            </Text>
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              {(user.profile &&
              user.profile.country &&
              user.profile.country.length === 2
                ? CountriesArray.find(
                    (item) => item.sigla === user.profile.country
                  ).nome_pais
                : '') || ''}
            </Text>
          </div>
          <div className="block__content--province">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[400]}
              weight="bold"
            >
              {user?.profile?.country === 'BR'
                ? 'Estado'
                : 'Estado / Província / Região'}
            </Text>
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              {user.profile.province}
            </Text>
          </div>
          <div className="block__content--city">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[400]}
              weight="bold"
            >
              Cidade
            </Text>
            <Text
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              {user?.profile?.city || ''}
            </Text>
          </div>
        </div>
      </Paper>
    </Wrapper>
  );
};

UserAddress.propTypes = {
  refreshToken: PropTypes.string,
};

UserAddress.defaultProps = {
  refreshToken: '',
};

export default UserAddress;
export { UserAddress };
