import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.bigger};
  padding: 2rem;
  margin: 0 auto;

  header {
    padding: 2rem 0;
  }

  .about-list {
    width: 100%;
    max-width: ${(props) => props.theme.breakpoints.medium};
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      grid-template-columns: auto 1fr;
    }

    &__picture {
      width: 180px;
      height: 180px;
      overflow: hidden;
      border-radius: 180px;
    }
    &__description {
      max-width: 100%;
      padding: 2rem 0;

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        padding: 2rem;
        margin-left: 2rem;
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
