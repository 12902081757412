import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';

import { usePostSocialNameData } from 'libs/ms-api-react-query';
import { msApiLib } from 'services/config/ms-api';

import {
  Button,
  Input,
  IconCertificateCircle,
  IconWatermark,
} from '@components';

import { actions as userActions } from '@store/ducks/user';

import * as S from './Form.styles';

export const Form = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const bearerToken = Cookies.get('tokenClient');
  const refreshToken = Cookies.get('refresh_tokenClient');

  //@ts-ignore
  const { userData } = useSelector((state) => state.userReducer);

  const { mutate, isSuccess } = usePostSocialNameData(msApiLib);

  const formik = useFormik({
    initialValues: {
      social_name: userData.name,
    },
    validationSchema: Yup.object().shape({
      social_name: Yup.string()
        .matches(/^\S+\s\S+.*$/, 'Nome e sobrenome são necessários')
        .required('Nome social é obrigatório'),
    }),
    onSubmit: async (values) => {
      const { social_name } = values;
      await mutate({ bearerToken, query: { social_name: social_name } });
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    validateForm,
    validateField,
  } = formik;

  const handleOnChange = async (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  const capitalizeLetters = (inputString) => {
    const words = inputString?.split(' ');

    const formattedString = words
      ?.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join(' ');

    return formattedString;
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        userActions.getUserDataRequest({
          payload: {
            auth: {
              refreshToken: refreshToken,
            },
          },
        }),
      );
      router.push('/certificados');
    }
  }, [isSuccess]);

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit} method="post">
        <S.InputContainer>
          {
            //@ts-ignore
            <Input
              label="Nome de Certificado"
              name="social_name"
              type="text"
              placeholder="Insira o nome social"
              size="medium"
              id="socialName"
              value={formik.values.social_name}
              validation={
                formik.errors.social_name && formik.touched.social_name
                  ? 'invalid'
                  : null
              }
              onChange={(e) => handleOnChange('social_name', e.target.value)}
              validationMsg={
                formik.errors.social_name && formik.touched.social_name
                  ? formik.errors.social_name
                  : ''
              }
              maxLength="200"
            />
          }
        </S.InputContainer>
        <S.CertificateTemplate>
          <IconCertificateCircle size="64px" className="icon-certificate" />
          <S.TextplaceHolder size="50%" />
          <S.SocialName>
            {capitalizeLetters(formik.values.social_name)}
          </S.SocialName>
          <div className="subtext">
            <S.TextplaceHolder size="80%" />
            <S.TextplaceHolder size="70%" />
          </div>
          <IconWatermark className="icon-watermark" />
        </S.CertificateTemplate>

        <S.ButtonContainer>
          {
            //@ts-ignore
            <Button
              id="first-access__button__entry"
              variant="primary"
              size="medium"
              type="submit"
              disabled={!!formik.errors.social_name}>
              Confirmar Nome
            </Button>
          }
        </S.ButtonContainer>
      </S.Form>
    </S.Wrapper>
  );
};
