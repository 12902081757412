import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.bigger};
  padding: 0 2rem;
  margin: 0 auto;

  header {
    padding: 2rem 0;
  }

  .loading-holder {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .catalog-list {
    &__filter {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        /* display: grid;
        grid-template-columns: auto 1fr; */
      }

      &-select {
        width: 100%;

        @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
          width: ${(props) => props.theme.breakpoints.smaller};
        }
      }

      .catalog-list__filter-tags {
        span {
          cursor: pointer;
        }
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
