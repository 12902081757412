import { css } from 'styled-components';

const styles = ({ theme, bgimage }) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background: ${theme.colorPallete.base[100]};

  .checklist-page {
    &__picture {
      position: relative;
      z-index: 1;

      width: 100%;
      height: calc((7 / 16) * 100vw);
      max-height: calc(100vh - 60rem);
      min-height: 21.2rem;

      background: rgba(0, 0, 0, 0.5) url(${bgimage}) center center no-repeat;
      background-size: cover;
    }

    &__content {
      padding: 3.6rem 2rem;
      margin: 0 auto;
      width: 100%;
      max-width: ${theme.breakpoints.big};
    }

    &__back-control {
      position: absolute;
      top: 2rem;
      left: 2rem;
      display: block;
      cursor: pointer;
      z-index: 5;

      .arrow-left {
        display: inline-block;
        transform: rotate(180deg) translate(0.3rem, 0.15rem);
      }
    }

    &__close-control {
      padding: 0 0 3.6rem;
      max-width: 10rem;

      .arrow-left {
        display: inline-block;
        transform: rotate(180deg) translate(0.3rem, 0.15rem);
      }
    }

    &__content-wrapper {
      display: grid;
      grid-template-columns: 1fr;

      &.checklist-page__result-wrapper {
        /* @media (min-width: ${theme.breakpoints.medium}) {
          grid-template-columns: 54% auto;
        }
        @media (min-width: ${theme.breakpoints.big}) {
          grid-template-columns: 65% auto;
        } */
      }
    }
  }

  .mobile-only {
    display: block;
    @media (min-width: ${theme.breakpoints.medium}) {
      display: none;
    }
  }
  .desk-only {
    display: none;
    @media (min-width: ${theme.breakpoints.medium}) {
      display: block;
    }
  }
`;

export default styles;
export { styles };
