import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: min-content auto;

    grid-gap: 24px;

    margin-top: 32px;
  `}
`;

export const Avatar = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 42px;
    height: 42px;
    align-items: center;
    border-radius: 42px;
    background-color: ${theme.colorSystem.primaryColor};
    font-size: 16px;
    font-weight: ${theme.fonts.fontWeight.bold};

    &.answer {
      width: 32px;
      height: 32px;
    }

    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 42px;
    }
  `}
`;

export const Contents = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .wrapper-add-comment .avatar {
      margin-block: auto;
    }
  `}
`;

export const HeaderContents = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      display: none;
    }

    @media (min-width: 600px) {
      flex-direction: row;

      span {
        display: contents;
      }
    }
  `}
`;

export const UserName = styled.h3`
  ${({ theme }) => css`
    font-size: 1.6rem;
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colorPallete.white};
  `}
`;

export const PublishedTime = styled.h3`
  ${({ theme }) => css`
    font-size: 1.6rem;
    font-weight: ${theme.fonts.fontWeight.bold};
    color: #7a7a7a;
  `}
`;

export const CommentContent = styled.p`
  ${({ theme }) => css`
    font-size: 1.4rem;
    font-weight: ${theme.fonts.fontWeight.regular};
  `}
`;

export const AnswersController = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ButtonTextWrapperProps {
  answersIsOpen: boolean;
}

export const ButtonTextWrapper = styled.button<ButtonTextWrapperProps>`
  ${({ theme, answersIsOpen }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    padding: 10px 5px;
    color: ${theme.colorPallete.white};
    background-color: transparent;
    font-size: 1.4rem;
    font-weight: ${theme.fonts.fontWeight.bold};
    cursor: pointer;

    span {
      color: ${theme.colorSystem.primaryColor};
    }
    svg {
      transform: rotate(${answersIsOpen ? '0' : '180deg'});
    }
  `}
`;

export const AnswersWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    .wrapper-answer {
      &:last-child {
        margin-bottom: 32px;
      }
    }
  `}
`;
