import React from 'react';
import { ScrollableContainer, Card } from 'components';
import { CourseListItemDto, WatchingListItemDto } from 'libs/ms-api';
import { CardTypes } from 'components/Card/types';
import * as S from './RecommendedContent.styles';

interface RecommendedContentProps {
  content: WatchingListItemDto[] | CourseListItemDto[];
}

const RecommendedContent = React.memo(
  ({ content }: RecommendedContentProps) => {
    if (!content) <></>;

    return (
      <S.Wrapper>
        <S.Content>
          <S.Title>Programas de Estudo Recomendados</S.Title>
          <ScrollableContainer className="recommended-content-carousel">
            {content.map((card, index) => (
              <Card
                key={index}
                cardContent={card}
                type={CardTypes.RECOMMENDED}
              />
            ))}
          </ScrollableContainer>
        </S.Content>
      </S.Wrapper>
    );
  },
);

export default RecommendedContent;
export { RecommendedContent };
