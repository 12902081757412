import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    aspect-ratio: 16 / 9;
    padding: 27px 20px;
    font-family: ${theme.fonts.fontFamily.sans};
    background-color: ${theme.colorPallete.base[680]};
    border-radius: 4px;

    @media (min-width: 1025px) {
      padding: 27px 41px;
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const AmountQuestions = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const QuestionsTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorSystem.baseColor100};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const Radio = styled.input`
  width: 18px;
  height: 18px;

  margin-inline: 20px;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  border: 2px solid #949494;
  border-radius: 50%;

  &:checked:before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    background-color: #e96700;
    border-radius: 50%;

    position: relative;
    left: 15%;
    top: 15%;
  }
`;

export const ItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    text-align: start;
    gap: 16px;

    width: 100%;
    margin-block: 16px;
    padding-right: 20px;
    color: ${theme.colorPallete.white};
    font-weight: 500;
    font-size: ${theme.fonts.fontSize.md};
  `}
`;

export const Questions = styled.button`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 64px auto;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: #d2d2d2;
    font-size: ${theme.fonts.fontSize.md};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    line-height: 133%;

    border-radius: 4px;
    border: 1.5px solid #454545;

    background-color: transparent;

    cursor: default;

    &.correct,
    &.wrong {
      .feedback-container {
        display: block;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      height: 100%;
      margin-inline: 20px;
    }

    &.not-answered {
      cursor: pointer;
      input {
        cursor: pointer;
      }
    }

    &:not(.current) {
      svg {
        display: none;
      }
    }

    &.correct,
    &.wrong {
      border-radius: 8px;
      border: 1.5px solid #00ad8b;

      input {
        display: none;
      }
    }

    &.correct {
      border: 1.5px solid #00ad8b;
    }

    &.wrong {
      border: 1.5px solid #db3d27;
    }
  `}
`;

interface QuestionFeedbackContainer {
  type: 'correct' | 'wrong' | '';
}

export const QuestionFeedbackContainer = styled.div<QuestionFeedbackContainer>`
  ${({ theme, type }) => css`
    display: none;
    grid-column: 1 / 3;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 12px;
    text-align: start;
    border-radius: 8px;
    border: 1.5px solid ${type === 'correct' ? '#173a33' : '#785151'};
    font-weight: ${theme.fonts.fontWeight.regular};
    font-size: ${theme.fonts.fontSize.sm};

    padding: 11px 20px;

    @media (min-width: 1025px) {
      width: 97%;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 24px;
  width: 100%;

  button {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  @media (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PreviousQuestion = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    background-color: transparent;
    gap: 14px;
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
    line-height: 140%;
  `}
`;

interface CheckAnswerProps {
  isNextQuestion: boolean;
}

export const CheckAnswer = styled.button<CheckAnswerProps>`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    gap: 14px;

    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.6);

    padding: 12px 16px;

    color: ${theme.colorPallete.white};
    text-align: center;
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};

    &.button-next-question {
      background-color: #ed6900;
      border: 1px solid #ed6900;
    }

    svg {
      transform: rotate(180deg);
    }

    @media (min-width: 1025px) {
      margin-left: auto;
    }
  `}
`;
