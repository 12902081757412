/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';

const SeeResultsButton = ({
  lastQuestion,
  toggleShowResult,
  setCurrentQuestion,
}) => {
  const handleBackButton = () => {
    setCurrentQuestion(false);
    return Router.back();
  };

  return lastQuestion ? (
    <div
      className="content__interactions__goback"
      onClick={() => toggleShowResult(true)}
      aria-hidden
    >
      <a>Ver resultado</a>
    </div>
  ) : (
    <div
      className="content__interactions__goback"
      onClick={() => handleBackButton()}
      aria-hidden
    >
      <a>Voltar</a>
    </div>
  );
};

SeeResultsButton.propTypes = {
  lastQuestion: PropTypes.bool,
  toggleShowResult: PropTypes.func,
  setCurrentQuestion: PropTypes.func,
};

SeeResultsButton.defaultProps = {
  lastQuestion: false,
  toggleShowResult: () => '',
  setCurrentQuestion: () => '',
};

export { SeeResultsButton };
export default SeeResultsButton;
