import { IconWatermark } from '@components/Icons';
import { CertificateDto } from '@libs/ms-api';
import { Skeleton } from '@skeletons/Skeleton';

import * as S from './CertificateTemplate.styles';

interface CertificateTemplateProps {
  title: string;
  studentName: string;
  issuanceDate: string;
  durationInSec: number;
}

export const CertificateTemplateSkeleton = () => {
  return (
    <S.SkeletonWrapper>
      <Skeleton isFullHeight isFullWidth />
    </S.SkeletonWrapper>
  );
};

export const CertificateTemplate = ({
  title,
  studentName,
  issuanceDate,
  durationInSec,
}: CertificateTemplateProps) => {
  const convertSecondsToHours = (seconds: number) => {
    return Math.floor(seconds / 3600);
  };

  const formatDate = (date: string) => {
    const dateFormat = new Date(date);
    const formattingOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const dateFormatter = new Intl.DateTimeFormat('pt-BR', formattingOptions);
    if (!!date) return dateFormatter.format(dateFormat);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.LogoContainer>
          <IconWatermark color={'#FFF'} />
        </S.LogoContainer>
        <div>
          <S.CourseSubtitle>Curso</S.CourseSubtitle>
          <S.CourseTitle>{title}</S.CourseTitle>
        </div>
        <div>
          <S.NameSubtitle>Certificamos que</S.NameSubtitle>
          <S.StudentName>{studentName}</S.StudentName>
          <S.Description>
            concluiu o curso online com carga horária estimada em{' '}
            {convertSecondsToHours(durationInSec)} horas.
            <br />
            {formatDate(issuanceDate)}.
          </S.Description>
        </div>
        <S.SignaturesContainer>
          <img src="/assets/images/flavio-signature.png" alt="Flávio Augusto" />
        </S.SignaturesContainer>
      </S.Content>
      <S.LateralStrip />
    </S.Wrapper>
  );
};
