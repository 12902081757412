import { styled, css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 90px;
    margin-bottom: 32px;
    padding: 12px;
  }
`;

export const TopNavigation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2%;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    background-color: transparent;
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    font-weight: ${theme.fonts.fontWeight.bold};
    margin-right: 32px;
    cursor: pointer;
  `}
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 10% 8% 10%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;

  @media (max-width: 1350px) {
    flex-direction: column;
    padding: 0 2% 20% 2%;
  }
`;

export const CertificateInfo = styled.div`
  width: 70%;

  @media (max-width: 1350px) {
    width: 100%;
  }
`;

export const CourseSubtitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[220]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.sm};
    font-weight: ${theme.fonts.fontWeight.bold};
    text-transform: uppercase;
  `}
`;

export const CourseTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.gg};
    font-weight: ${theme.fonts.fontWeight.bold};

    margin-block: 8px 16px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.sm};
    font-weight: ${theme.fonts.fontWeight.regular};
    line-height: 133%;

    max-width: 410px;

    @media (max-width: 1350px) {
      max-width: 100%;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  margin-block: 24px 40px;

  @media (max-width: 1350px) {
    flex-direction: column;
    width: 300px;
  }
`;

export const PrimaryButton = styled.a`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    background-color: ${theme.colorPallete.primary[200]};
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;

    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const SecondaryButton = styled.a`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    background-color: transparent;
    padding: 12px 24px;
    border: 1px solid ${theme.colorPallete.white};
    border-radius: 4px;
    cursor: pointer;

    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const ShareButton = styled.a`
  ${({ theme }) => css`
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    background-color: transparent;
    padding: 12px 24px;
    margin-top: 24px;
    border: 1px solid ${theme.colorPallete.white};
    border-radius: 4px;
    cursor: pointer;

    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};

    @media (max-width: 1350px) {
      width: 300px;
    }
  `}
`;
