import styled, { css } from 'styled-components';

export const Playlist = styled.div`
  width: 100%;
  position: relative;
  max-height: 465px;

  @media (min-width: 1025px) {
    width: 40%;
    aspect-ratio: 0.88;
    height: auto;
    margin-left: 24px;
    padding-block: 2.7rem;
  }
`;

export const Module = styled.div`
  margin-bottom: 16px;
`;

export const ModuleSelectGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.md};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    line-height: 133%;
    margin-bottom: 8px;
  `}
`;

export const ModuleSelect = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 8px;
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    border: 1px solid ${theme.colorPallete.base[500]};
    background-color: ${theme.colorPallete.base[500]};
    border-radius: 4px;
    cursor: pointer;

    span {
      text-align: left;
    }

    svg {
      transform: rotate(270deg);
    }

    &.options-open {
      border: 1px solid ${theme.colorPallete.base[400]};

      svg {
        transform: rotate(90deg);
      }
    }
  `}
`;

export const DropdownsOptions = styled.ul`
  ${({ theme }) => css`
    width: 100%;
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 6px;
    border-radius: 4px;
    padding: 8px;
    background-color: ${theme.colorPallete.base[500]};
    font-family: ${theme.fonts.fontFamily.sans};
    max-height: 204px;
    padding-right: 8px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px !important;
      background: rgba(255, 255, 255, 0.2) !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #868585 !important;
      border-radius: 8px !important;
      height: 20px;
    }
  `}
`;

export const Option = styled.li`
  ${({ theme }) => css`
    cursor: default;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 99;
    line-height: 133%;

    color: ${theme.colorPallete.base[170]};
    height: auto;
    font-size: ${theme.fonts.fontSize.md};
    margin-bottom: 4px;
    border-radius: 8px;
    padding: 12px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${theme.colorPallete.base[600]};
      cursor: pointer;
    }

    &.selected {
      background-color: ${theme.colorPallete.base[600]};
      cursor: default;
    }
  `}
`;

export const ClassList = styled.div`
  ${({ theme }) => css`
    max-height: 365px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 14px;

    &.padding {
      padding-right: 16px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px !important;
      background: rgba(255, 255, 255, 0.2) !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #da571a !important;
      border-radius: 8px !important;
      height: 20px;
    }

    @media (min-width: 1025px) {
      max-height: calc(100% - 40px);
    }
  `}
`;

export const CardClass = styled.div`
  ${({ theme }) => css`
    padding: 16px;
    background-color: ${theme.colorPallete.base[680]};
    display: flex;
    gap: 8px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &.selected {
      background-color: rgba(254, 112, 0, 0.3);
      border: 1px solid ${theme.colorPallete.primary[300]};
    }

    &:hover:not(.selected) {
      background-color: ${theme.colorPallete.base[600]};
    }
  `}
`;

export const Icon = styled.div`
  &.completed svg {
    width: 20px;
  }
`;

export const ClassContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const PrimaryInfo = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.sm};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    line-height: 133%;
    display: flex;
    gap: 8px;
  `}
`;

export const Order = styled.span`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[220]};

    &.selected {
      color: ${theme.colorPallete.white};
    }
  `}
`;

export const ClassTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[90]};

    &.selected {
      color: ${theme.colorPallete.white};
    }
  `}
`;

export const SecondaryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Tag = styled.span`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.sm};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.regular};
    line-height: 133%;
    background-color: ${theme.colorPallete.base[450]};
    padding: 4px 8px;
    border-radius: 4px;

    &.selected {
      background-color: ${theme.colorPallete.primary[600]};
    }
  `}
`;

export const Duration = styled.span`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[220]};
    font-size: ${theme.fonts.fontSize.sm};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.regular};
    line-height: 133%;
  `}
`;
