import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { main as theme } from '@themes';
import { Text, DownloadButton } from '@components';

// Helpers
import fileReaderPath from '@helpers/fileReaderPath';

import { styles } from './CoursewareBanner.styles';

const Wrapper = styled.div`
  ${styles}
`;

const CoursewareBanner = React.memo(({ color, content }) => {
  const pathServerImage = `https://res.cloudinary.com/redfuel/image/upload`;

  const { userData, authStatus } = useSelector((state) => state.userReducer);

  let mobImage = pathServerImage;
  let bgImage = pathServerImage;

  if (content.image) {
    content.image.map((item) => {
      switch (item.type) {
        case 'md-background-mobile':
          mobImage = pathServerImage + item.path;
          break;
        default:
          bgImage = pathServerImage + item.path;
          break;
      }
    });
  }

  const courseAttach = (content) => {
    const uriEncode = btoa(`${content?.url}`);
    return `/anexo/${content?.filetype}/Material Didático - ${content?.title}/${uriEncode}`;
  };

  return (
    <Wrapper mobimage={mobImage} bgimage={bgImage} color={color}>
      <div className="banner__image" />

      <div className="banner__content">
        <Text
          as="h3"
          className="banner__content--title"
          size="1.4rem"
          dsize="1.4rem"
          weight={theme.fonts.fontWeight.bold}
          transform="uppercase"
        >
          {content.title}
        </Text>
        <Text
          as="p"
          className="banner__content--text"
          size="1.4rem"
          dsize="1.4rem"
          weight={theme.fonts.fontWeight.regular}
        >
          {content.description}
        </Text>
        <div className="banner__content--attachments">
          {userData && (
            <>
              <DownloadButton
                slug={content.slug}
                text="Baixar agora"
                variant="light"
                color={color}
                disabled={userData?.user_trial?.account_status === 'Trialing'}
                size="big"
                url={courseAttach(content)}
              />

              {userData?.user_trial?.account_status === 'Trialing' && (
                <div className="trial-text">
                  <Text size="1.2rem" color={theme.colorSystem.danger}>
                    O material didático será disponibilizado após a efetivação
                    da assinatura
                  </Text>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
});

CoursewareBanner.propTypes = {
  color: PropTypes.string,
  content: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  }),
};

CoursewareBanner.defaultProps = {
  color: theme.colorPallete.primary.default,
  content: '',
};

export default CoursewareBanner;
export { CoursewareBanner };
