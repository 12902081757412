import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { actions as lessonActions } from '@store/ducks/lesson';

// Validations
import { useFormik } from 'formik';

// Components
import { Button, Input, ReactAvatar } from '@components';

// Styles
import { Wrapper } from './Form.styles';

const Form = ({ variant, refreshToken, lessonId, commentId }) => {
  const userData = useSelector((state) => state.userReducer.userData);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      comment: '',
      comment_id: commentId || null,
    },
    onSubmit: async (values) => {
      const { comment, ...restValues } = values;

      const data = {
        ...restValues,
        description: comment,
        lesson_id: lessonId,
      };

      dispatch(
        lessonActions.sendCommentsRequest({
          payload: {
            data,
            auth: {
              refresh_token: refreshToken,
            },
          },
        })
      );

      formik.setFieldValue('comment', '');
    },
  });

  return (
    <Wrapper variant={variant} onSubmit={formik.handleSubmit}>
      <div className="form__input">
        {userData && userData.avatar ? (
          <div className="photo-holder">
            <img
              src={`${userData.avatar}`}
              alt={userData.name}
            />
          </div>
        ) : (
          <div className="photo-holder">
            <ReactAvatar
              name={userData.name}
              size="3.6"
              className="comment-avatar"
            />
          </div>
        )}
        <div className="form__input__text">
          <Input
            type="textarea"
            placeholder="Deixe seu comentário"
            name="comment"
            id="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            validation={formik.values.comment.trim() ? null : 'invalid'}
            validationMsg={
              formik.values.comment.length > 0 &&
                formik.values.comment.trim() === ''
                ? 'Preencha o comentário antes de prosseguir.'
                : null
            }
          />
        </div>
      </div>
      <div className="form__buttons">
        {formik.values.comment.trim() && (
          <div className="form__buttons__content">
            <Button
              type="button"
              size="small"
              onClick={() => formik.setFieldValue('comment', '')}
              className="btn-not-ds"
            >
              Cancelar
            </Button>
            <Button variant="primary" type="submit" size="small">
              Comentar
            </Button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

Form.propTypes = {
  variant: PropTypes.string,
  refreshToken: PropTypes.string,
  lessonId: PropTypes.string,
  commentId: PropTypes.string,
};

Form.defaultProps = {
  variant: 'primary',
  refreshToken: '',
  lessonId: '',
  commentId: null,
};

export { Form };
export default Form;
