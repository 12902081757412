import { Dispatch, SetStateAction } from 'react';
import { Modal } from '@containers';
import {
  IconMedalCheckedSolid,
  IconArrowBold,
  IconCheckCircleSolid,
} from '@components/Icons';

import * as S from './CompletionModal.styles';

import { CompletionModalProps, ModalType } from './types';
import Link from 'next/link';
import { useFeatureToggle } from 'hooks';

const WithCertificate = (
  handleCloseModal: () => void,
  handleIssueCertificate: () => Promise<void>,
) => {
  return (
    <>
      <IconMedalCheckedSolid size="60px" color="#ED6900" />
      <S.ModalTitle>Programa de Estudo Concluído!</S.ModalTitle>
      <S.ModalText>
        Parabéns! Emita já seu certificado clicando no botão abaixo ou acesse
        “Meus Certificados” no menu do ícone superior para acessar os
        certificados de seus cursos concluídos.
      </S.ModalText>
      <S.ModalText>
        Você também pode rever os vídeos e refazer os exercícios do programa de
        estudo concluído quando quiser.
      </S.ModalText>
      <S.MainButton onClick={() => handleIssueCertificate()}>
        Emitir Certificado
        <IconArrowBold width="24" height="26" strokeWidth="1.8" />
      </S.MainButton>
      <S.SecondaryButton onClick={() => handleCloseModal()}>
        Fechar Modal
      </S.SecondaryButton>
    </>
  );
};

const WithoutCertificate = (handleCloseModal: () => void) => {
  return (
    <>
      <IconCheckCircleSolid size="60px" color="#ED6900" />
      <S.ModalTitle>Programa de Estudo Concluído!</S.ModalTitle>
      <S.ModalText>
        Parabéns! Você acaba de dar mais um grande passo no mundo do
        empreendedorismo. Mas a jornada não termina aqui! Confira mais Estudos
        de Casos presentes na plataforma para aprender ainda mais!
      </S.ModalText>
      <S.ModalText>
        Você também pode rever os vídeos e refazer os exercícios do programa de
        estudo concluído quando quiser.
      </S.ModalText>
      <Link href="/home" passHref legacyBehavior>
        <S.MainButton as="a">
          Conferir outros cursos
          <IconArrowBold width="24" height="26" strokeWidth="1.8" />
        </S.MainButton>
      </Link>

      <S.SecondaryButton onClick={() => handleCloseModal()}>
        Fechar Modal
      </S.SecondaryButton>
    </>
  );
};

const CompletionModalComponent = ({
  type,
  visible,
  setIsModalVisible,
  setHasShownModal,
  handleIssueCertificate,
}: CompletionModalProps) => {
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setHasShownModal(true);
  };

  const getModalType = () => {
    if (type === ModalType.withCertificate)
      return WithCertificate(handleCloseModal, handleIssueCertificate);
    if (type === ModalType.withoutCertificate)
      return WithoutCertificate(handleCloseModal);
  };

  return (
    <Modal visible={visible} setVisibility={setIsModalVisible}>
      <S.Wrapper>{getModalType()}</S.Wrapper>
    </Modal>
  );
};

export const CompletionModal = ({
  type,
  visible,
  setIsModalVisible,
  setHasShownModal,
  handleIssueCertificate,
}: CompletionModalProps) => {
  const CompletionModalToggle = useFeatureToggle({
    ComponentWhenEnabled: () => (
      <CompletionModalComponent
        type={type}
        visible={visible}
        setIsModalVisible={setIsModalVisible}
        setHasShownModal={setHasShownModal}
        handleIssueCertificate={handleIssueCertificate}
      />
    ),
    featureKeys: ['certificates'],
  });
  return <CompletionModalToggle />;
};
