import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    gap: 32px;
    padding-top: 2.6rem;
    width: 100%;
    background-color: ${theme.colorSystem.black};
    padding-bottom: 45px;

    padding-inline: 20px;

    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Content = styled.div`
  ${() => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 32px;

    .recommended-content-carousel {
      gap: 32px;

      a {
        max-width: 270px;
      }
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: 20px;
    font-family: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;
