import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colorPallete.base};
    height: unset;
    padding: 6rem 0;
    margin-block: 40px;

    &.sponsored {
      padding-top: 0;
    }
  `}
`;

export const Content = styled.div`
  margin: 0 auto;
  padding-inline: 10vw;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Introduction = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    margin-bottom: 24px;
    padding-inline: 20px;

    h1 {
      font-size: ${theme.fonts.fontSize.md};
      font-weight: ${theme.fonts.fontWeight.bold};
      margin-bottom: 4px;
    }
    p {
      color: ${theme.colorPallete.base[150]};
      font-size: ${theme.fonts.fontSize.xs};
      font-weight: ${theme.fonts.fontWeight.regular};
    }
  `}
`;
