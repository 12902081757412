import { css, styled } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 727px;
    height: 492px;
    padding-inline: 16%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    text-align: center;
    background-color: ${theme.colorPallete.base[680]};

    border: 1px solid ${theme.colorPallete.base[600]};
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);

    @media (max-width: 767px) {
      padding-inline: 8%;
    }
  `}
`;

export const ModalTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.xg};
    font-weight: ${theme.fonts.fontWeight.bold};

    @media (max-width: 767px) {
      font-size: ${theme.fonts.fontSize.g};
    }
  `}
`;

export const ModalText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[90]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.regular};

    @media (max-width: 767px) {
      font-size: ${theme.fonts.fontSize.sm};
    }
  `}
`;

export const MainButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 12px 24px;
    margin-top: 8px;
    cursor: pointer;

    background-color: ${theme.colorPallete.primary[200]};
    border-radius: 4px;

    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const SecondaryButton = styled.button`
  ${({ theme }) => css`
    padding: 8px;

    background-color: transparent;
    border-bottom: 1px solid ${theme.colorPallete.base[90]};

    color: ${theme.colorPallete.base[90]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
    cursor: pointer;
  `}
`;
