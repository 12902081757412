import React from 'react';
import { Head } from '@containers';
import styled from 'styled-components';

// Components
import { Logo, Text } from '@components';

// Theme
import { main as theme } from '@themes';

// Styles
import { styles } from './BadGateway.styles';

const Wrapper = styled.main`
  ${styles}
`;

const BadGateway = React.memo(() => {
  return (
    <>
      <Head title="404 — Page not found — meuSucesso.com" />
      <Wrapper>
        <div className="page__header">
          <Logo size="150px" />
        </div>

        <div className="page__error__message">
          <Text
            className="error__regular-text"
            size="2rem"
            dsize="2rem"
            color={theme.colorPallete.white}
          >
            Error
          </Text>
          <Text
            as="h1"
            className="error__number"
            family={theme.fonts.fontFamily.serif}
            dsize="30rem"
            size="10rem"
            color={theme.colorPallete.primary.default}
          >
            502
          </Text>
          <Text
            className="error__regular-text"
            size="2rem"
            dsize="2rem"
            color={theme.colorPallete.white}
          >
            Bad Gateway
          </Text>
        </div>
      </Wrapper>
    </>
  );
});

export default BadGateway;
export { BadGateway };
