import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 3.6rem 2rem;

  .block__title {
    margin-bottom: 2.5rem;
  }

  .block__keywords {
    display: flex;
    flex-wrap: wrap;

    .block__keywords--item {
      margin-right: 1.2rem;
      margin-bottom: 1.2rem;
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding: 3.6rem;
  }
`;

export default styles;
export { styles };
