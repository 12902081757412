import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-left: 4px;
    margin-bottom: 30px;
    position: relative;

    &:not(.last)::before {
      content: '';
      width: 1px;
      height: calc(100% + 30px);
      position: absolute;

      left: calc(35px / 2 - 1px);
      top: calc(35px / 2);

      background-color: ${theme.colorPallete.base[320]};
      z-index: 1;
    }

    &.completed {
      &::before {
        background-color: ${theme.colorPallete.primary[200]};
      }
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 24px;
`;

export const ModuleNumber = styled.p`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    z-index: 5;

    border-radius: 50%;
    background-color: ${theme.colorPallete.base[600]};
    border: 1px solid ${theme.colorPallete.base[400]};

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};

    &.completed {
      background-color: #70380a;
      border: 1px solid #fe7000;
    }
  `}
`;

export const ModuleTitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const CarouselContainer = styled.div`
  margin-left: 35px;
  padding-right: 12px;

  @media (max-width: 790px) {
    padding-right: 0;
  }
`;
