import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Componentes
import { Text } from '@components';

// Themes
import { main } from '@themes';

// Styles
import { Wrapper } from './FaqList.styles';

const FaqList = ({ content, title }) => {
  return (
    <Wrapper>
      <header>
        <Text as="h2" dsize="2.2rem" weight="bold" dheight="180%">
          <span>{title}</span>
        </Text>
      </header>
      <div className="faq-list">
        {content &&
          content.map((item) => {
            return (
              <article>
                <Text as="h4" dsize="1.5rem" weight="bold" dheight="180%">
                  {item.question.title}
                </Text>
                <Text
                  as="p"
                  dsize="1.6rem"
                  weight="200"
                  dheight="160%"
                  color={main.colorPallete.base[200]}
                >
                  {item.answer.text}
                </Text>
                <Link href="/" passHref target="_blank">

                  <Text
                    dsize="1.2rem"
                    weight="200"
                    dheight="160%"
                    color={main.colorPallete.primary.default}
                    decoration="underline"
                    transform="uppercase"
                  >
                    Ver a questão completa
                  </Text>

                </Link>
              </article>
            );
          })}
      </div>
    </Wrapper>
  );
};

FaqList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      contents: PropTypes.instanceOf(Array),
      order: PropTypes.number,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

FaqList.defaultProps = {
  content: {
    contents: [],
    order: 0,
    subtitle: null,
    title: null,
  },
  title: null,
};

export { FaqList };
export default FaqList;
