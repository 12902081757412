import { useEffect, useRef } from 'react';
import { characterLimiter } from 'helpers';
import { IconArrow, IconCheck, IconCircleOutline } from 'components';
import { formatDuration } from 'helpers/dates';
import * as S from './Playlist.styles';
import { PlaylistDto, Module, Lesson } from '@libs/ms-api';

import { PlaylistSkeleton } from '@skeletons/LessonContentSkeleton/PlaylistSkeleton';

interface PlaylistProps {
  playlistContent: PlaylistDto & { nextLesson: Lesson };
  handleChangeVideo: (value: any) => void;
  selectedLessonOrQuizOption: any;
  setSelectedLessonOrQuizOption: React.Dispatch<any>;
  selectedModuleOption: any;
  setSelectedModuleOption: React.Dispatch<any>;
  isModuleOptionsOpen: boolean;
  setIsModuleOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWasModuleUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const Playlist = ({
  playlistContent,
  handleChangeVideo,
  selectedLessonOrQuizOption,
  setSelectedLessonOrQuizOption,
  selectedModuleOption,
  setSelectedModuleOption,
  isModuleOptionsOpen,
  setIsModuleOptionsOpen,
  setWasModuleUpdated,
}: PlaylistProps) => {
  const moduleSelectRef = useRef(null);
  const classListRef = useRef(null);
  const selectedLessonRef = useRef(null);

  const handleClickOutsideModuleSelect = (e: any) => {
    if (
      moduleSelectRef &&
      moduleSelectRef.current &&
      !moduleSelectRef.current.contains(e.target)
    ) {
      setIsModuleOptionsOpen(false);
    }
  };

  const scrollToSelectedLesson = () => {
    const topPosition = selectedLessonRef.current?.offsetTop - 194;
    if (classListRef && classListRef.current) {
      classListRef.current.scrollTop = topPosition;
    }
  };

  const handleOnClickModuleOption = (module: Module) => {
    setSelectedModuleOption(module);
    setWasModuleUpdated(true);
    scrollToSelectedLesson();
  };

  const handleOnClickCard = (lesson: Lesson) => {
    if (lesson.type === 'quiz') {
      setSelectedLessonOrQuizOption(lesson);
    } else {
      setSelectedLessonOrQuizOption(lesson);
      handleChangeVideo(lesson);
      scrollToSelectedLesson();
    }
  };

  const getLessonTitle = (CurrentItem: Lesson, CurrentIndex: number) => {
    if (CurrentItem?.type === 'quiz') {
      return `Exercício de ${playlistContent?.lessons[CurrentIndex - 1].category
        .title}`;
    } else return CurrentItem?.title;
  };

  const getModuleTitle = (module: Module) => {
    const moduleIndex = playlistContent.availableModules.findIndex(
      (moduleToFind) => {
        return moduleToFind.id === module.id;
      },
    );

    return `${moduleIndex + 1}. ${module.name}`;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideModuleSelect);
    return () =>
      document.removeEventListener('mousedown', handleClickOutsideModuleSelect);
  }, []);

  useEffect(() => {
    scrollToSelectedLesson();
  }, [selectedLessonOrQuizOption]);

  if (!playlistContent) return <PlaylistSkeleton />;

  return (
    <S.Playlist>
      <>
        <S.Module ref={moduleSelectRef}>
          <S.ModuleSelectGroup>
            <S.Label htmlFor="module-select">Módulo selecionado</S.Label>
            <S.ModuleSelect
              type="button"
              id="module-select"
              onClick={() => setIsModuleOptionsOpen((prevState) => !prevState)}
              className={isModuleOptionsOpen ? 'options-open' : ''}
              title={selectedModuleOption?.name}>
              <span>
                {characterLimiter(getModuleTitle(selectedModuleOption), 75)}
              </span>
              <IconArrow width="15" height="15" />
            </S.ModuleSelect>
          </S.ModuleSelectGroup>

          {isModuleOptionsOpen && (
            <S.DropdownsOptions>
              {playlistContent.availableModules.map((module, index) => (
                <S.Option
                  key={module.name}
                  role="option"
                  className={
                    selectedModuleOption?.slug === module?.slug
                      ? 'selected'
                      : ''
                  }
                  onClick={() => handleOnClickModuleOption(module)}
                  title={module.name}>
                  {characterLimiter(`${index + 1}. ${module.name}`, 75)}
                </S.Option>
              ))}
            </S.DropdownsOptions>
          )}
        </S.Module>

        <S.Label>Vídeos do módulo selecionado</S.Label>
        <S.ClassList
          className={
            selectedModuleOption?.playlistContents?.length > 3 ? 'padding' : ''
          }
          ref={classListRef}>
          {playlistContent?.lessons
            ?.filter((lesson) => lesson.status !== 'pendente')
            .map((lesson, index: number) => {
              return (
                <S.CardClass
                  key={lesson.id}
                  className={
                    lesson.id === selectedLessonOrQuizOption?.id
                      ? 'selected'
                      : ''
                  }
                  ref={
                    lesson.id === selectedLessonOrQuizOption?.id
                      ? selectedLessonRef
                      : null
                  }
                  onClick={() => handleOnClickCard(lesson)}>
                  <S.Icon
                    className={
                      lesson?.progress?.completed ||
                      lesson?.progress?.percent > 0.95
                        ? 'completed'
                        : ''
                    }>
                    {lesson?.progress?.completed ||
                    lesson?.progress?.percent > 0.95 ? (
                      <IconCheck color="#F26522" />
                    ) : (
                      <IconCircleOutline />
                    )}
                  </S.Icon>
                  <S.ClassContent>
                    <S.PrimaryInfo>
                      <S.Order
                        className={
                          lesson.id === selectedLessonOrQuizOption?.id
                            ? 'selected'
                            : ''
                        }>
                        {index + 1}
                      </S.Order>
                      <S.ClassTitle
                        className={
                          lesson.id === selectedLessonOrQuizOption?.id
                            ? 'selected'
                            : ''
                        }
                        title={getLessonTitle(lesson, index)}>
                        {characterLimiter(getLessonTitle(lesson, index), 63)}
                      </S.ClassTitle>
                    </S.PrimaryInfo>
                    <S.SecondaryInfo>
                      {lesson?.category && (
                        <S.Tag
                          className={
                            lesson.id === selectedLessonOrQuizOption?.id
                              ? 'selected'
                              : ''
                          }>
                          {lesson?.category?.title}
                          {lesson?.category?.subtitle !== '' &&
                          lesson.type !== 'liveclass'
                            ? ` - ${lesson?.category?.subtitle}`
                            : null}
                        </S.Tag>
                      )}
                      {lesson?.duration && (
                        <S.Duration>
                          {formatDuration(lesson?.duration)}
                        </S.Duration>
                      )}
                    </S.SecondaryInfo>
                  </S.ClassContent>
                </S.CardClass>
              );
            })}
        </S.ClassList>
      </>
    </S.Playlist>
  );
};

export default Playlist;
export { Playlist };
