import React from 'react';
import PropTypes from 'prop-types';

// Themes
import { main } from '@themes';

// Components
import { Text } from '@components';

// Containers
import { ToggleIcon } from './containers';

// Styles
import { Wrapper } from './Bullets.styles';

const Bullets = ({ bulletsData, styles, title }) => {
  let colKey = 0;

  return (
    <Wrapper styles={styles}>
      <Text
        as="h1"
        weight="bold"
        color={main.colorPallete.white}
        size="3.2rem"
        dsize="3.2rem"
        height="4rem"
        dheight="4rem"
      >
        {title}
      </Text>
      <div className="bullets__items">
        {bulletsData.items.map((col, colIdx) => {
          colKey = colIdx;
          return (
            <div className="bullets__items__col" key={`col-${colKey}`}>
              <div className="bullets__items__col__title">
                <ToggleIcon id={colIdx} />
                <Text
                  as="h3"
                  weight="bold"
                  color={styles.colors.primary}
                  size="1.8rem"
                  dsize="1.8rem"
                  height="2.3rem"
                  dheight="2.3rem"
                >
                  {col.title}
                </Text>
              </div>
              <div
                className="bullets__items__col__items"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: col.content }}
              />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

Bullets.propTypes = {
  bulletsData: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
  styles: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
};

Bullets.defaultProps = {
  bulletsData: {
    title: 'Aprenda sobre Inovação e Tecnologia neste Estudo de Caso.',
    content:
      '<ul><li>Criação do Protocolo</li><li>O Começo da Inovação Tecnológica</li><li> A Democratização da Internet</li><li> O Impacto da Internet na Sociedade</li><li> Privacidade e Segurança</li><li> O Futuro da Internet</li></ul>',
  },
  styles: {
    colors: {
      primary: '#00B41E',
    },
  },
  title: '',
};

export default Bullets;
export { Bullets };
