import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 0 2rem;

  header {
    padding: 2rem;
  }

  .banner-home {
    &--wide {
      width: 100%;
      padding: 0;
    }
  }
`;

export { Wrapper };
export default Wrapper;
