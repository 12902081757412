import React from 'react';
import PropTypes from 'prop-types';
import { Text, PosterBanner } from '@components';

import { Wrapper } from './Banner.styles';

const Banner = ({ content }) => {
  return (
    <Wrapper>
      <>
        {content.title && content.subtitle ? (
          <header>
            <Text as="h1" dsize="2.6rem" weight="bold" dheight="180%">
              {content.title}
            </Text>
            <Text as="p" dsize="1.4rem" dheight="150%">
              {content.subtitle}
            </Text>
          </header>
        ) : null}

        <div>
          {content.contents[0] && (
            <PosterBanner content={content.contents[0]} />
          )}
        </div>
      </>
    </Wrapper>
  );
};

Banner.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    contents: PropTypes.instanceOf(Array),
  }),
};

Banner.defaultProps = {
  content: {
    title: null,
    subtitle: null,
    contents: [],
  },
};

export { Banner };
export default Banner;
