/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Link from 'next/link';
import moment from 'moment';
import { Button, BankFlag, IconBarCode, Paper, Text } from '@components';

import { main as theme } from '@themes';
import { styles } from './UserSubscriptionInfo.styles';

const Wrapper = styled.div`
  ${styles}
`;

const UserSubscriptionInfo = () => {
  const { profileData } = useSelector((state) => state.userReducer);

  let billKey = 0;

  const verifyStatus = (status, bill) => {
    if (status) {
      switch (status) {
        case 'open':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle open" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Em aberto
                </Text>
              </div>
            </div>
          );
        case 'paid':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle paid" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Pago
                </Text>
              </div>
            </div>
          );
        case 'canceled':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle canceled" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Cancelado
                </Text>
              </div>
            </div>
          );
        case 'refunded':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle refunded" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Estornado
                </Text>
              </div>
            </div>
          );
        case 'pending':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle pending" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Pendente
                </Text>
              </div>
            </div>
          );
        case 'rejected':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle rejected" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Rejeitado
                </Text>
              </div>
            </div>
          );
        case 'processing':
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle processing" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Processando
                </Text>
              </div>
            </div>
          );
        default:
          return (
            <div className="invoice__status">
              <div className="invoice__status__content">
                <span className="status-circle analisys" />
                <Text
                  color={theme.colorPallete.base[400]}
                  size="1.2rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.bold}
                >
                  Analisando
                </Text>
              </div>
            </div>
          );
      }
    }
    return '';
  };

  const retornaLista = () => {

    profileData?.list_bill.sort((a, b) => {
      if (a.due_date < b.due_date) {
        return 1;
      } else if (a.due_date > b.due_date) {
        return -1;
      } else {
        if (a.status === "open" && b.status === "paid") {
          return -1;
        } else if (a.status === "paid" && b.status === "open") {
          return 1;
        } else {
          return 0;
        }
      }
    });

    return profileData?.list_bill.map((bill, billIdx) => {
      billKey = billIdx;
      return bill?.amount && (
        <div className="subscription__table__body__block">
          <div className="table-row">
            <div className="table-cell">
              <Text
                color={theme.colorPallete.base[400]}
                size="1.2rem"
                dsize="1.4rem"
                weight={theme.fonts.fontWeight.bold}
                className="subscription__invoice__date"
              >
                {bill.due_date
                  ? moment(bill.due_date, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY'
                  )
                  : '--'}
              </Text>
            </div>
            <div className="table-cell">
              <Text
                color={theme.colorPallete.base[400]}
                size="1.2rem"
                dsize="1.4rem"
                weight={theme.fonts.fontWeight.bold}
                className="subscription__invoice__value"
              >
                {bill?.amount?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </div>
            <div className="table-cell">
              {bill.payment_method &&
                bill.payment_method.brand ? (
                <div className="card-flag">
                  <BankFlag name={bill.payment_method.brand} />
                </div>
              ) : (
                <div className="card-flag">
                  <IconBarCode size="30px" />
                </div>
              )}
            </div>
            <div className="table-cell status-cell">
              {verifyStatus(bill.status, bill)}
            </div>
          </div>

          {!bill.payment_method &&
            (bill.bill_url ? (
              <div className="subscription__button_paynow">
                <Link href={bill.bill_url} passHref target="_blank">

                  <Button
                    variant="hollow"
                    size="medium"
                    disabled={
                      bill.status === 'paid' ||
                      bill.status === 'refunded'
                    }
                  >
                    Pague agora
                  </Button>

                </Link>
              </div>
            ) : (
              <div className="subscription__button_paynow">
                <Button
                  variant="hollow"
                  size="medium"
                  disabled={
                    bill.status === 'paid' ||
                    bill.status === 'refunded'
                  }
                >
                  Pague agora
                </Button>
              </div>
            ))}
        </div>
      );
    });
  }

  return (
    <Wrapper className="subscription-info">
      <Paper className="paper">
        <div className="block__title">
          <Text
            as="h3"
            size="1.8rem"
            dsize="1.8rem"
            weight="bold"
            color={theme.colorPallete.primary.default}
          >
            Assinatura
          </Text>
        </div>

        <div className="block__content">
          <div className="subscription__list block__content__wrapper">
            <div className="subscription__list__invoice block__content__month">
              <div className="subscription__table">
                <div className="subscription__table__header">
                  <div className="column-name">
                    <Text
                      color={theme.colorPallete.base[400]}
                      size="1.2rem"
                      dsize="1.6rem"
                      weight={theme.fonts.fontWeight.bold}
                    >
                      Vencimento
                    </Text>
                  </div>
                  <div className="column-name">
                    <Text
                      color={theme.colorPallete.base[400]}
                      size="1.2rem"
                      dsize="1.6rem"
                      weight={theme.fonts.fontWeight.bold}
                    >
                      Valor
                    </Text>
                  </div>
                  <div className="column-name">
                    <Text
                      color={theme.colorPallete.base[400]}
                      size="1.2rem"
                      dsize="1.6rem"
                      weight={theme.fonts.fontWeight.bold}
                    >
                      Meio de pagamento
                    </Text>
                  </div>
                  <div className="column-name">
                    <Text
                      color={theme.colorPallete.base[400]}
                      size="1.2rem"
                      dsize="1.6rem"
                      weight={theme.fonts.fontWeight.bold}
                    >
                      Situação
                    </Text>
                  </div>
                </div>

                <div className="subscription__table__body">
                  {retornaLista()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block__cancel-subscription">
          <Text
            color={theme.colorPallete.base.default}
            size="1.6rem"
            dsize="1.6rem"
            weight={theme.fonts.fontWeight.bold}
          >
            Clique no botão para iniciar seu processo de cancelamento:
          </Text>
          <Link
            href="/cancelar-assinatura"
            passHref
            target="_blank"
            id="profile__link__cancel-subscription">

            <Text
              color={theme.colorPallete.base.default}
              size="1.6rem"
              dsize="1.6rem"
              weight={theme.fonts.fontWeight.bold}
            >
              Solicitar cancelamento
            </Text>

          </Link>
        </div>
      </Paper>
    </Wrapper>
  );
};

export default UserSubscriptionInfo;
export { UserSubscriptionInfo };
