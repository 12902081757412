import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  padding: 4rem 1rem;

  .page__container {
    width: 100%;
    max-width: 50rem;
    margin: auto;

    .page__header {
      margin-bottom: 3rem;

      .page__header__goback {
        margin-bottom: 2rem;
        background: transparent;

        &:active,
        &:focus,
        &:focus-within {
          border: none;
          outline: none;
          box-shadow: none;
        }

        .text-component {
          .arrow-left {
            display: inline-block;
            transform: rotate(180deg) translate(0.3rem, 0.15rem);
          }
        }
      }
    }

    .page__content {
      margin-bottom: 3rem;

      .page__content__form-input {
        margin-top: 2.4rem;
      }
    }

    .page__submit {
      width: 100%;
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding: 4rem;
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    padding: 4rem;

    .page__container {
      .page__submit {
        max-width: 36rem;
      }
    }
  }
`;

export default styles;
export { styles };
