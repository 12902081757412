import styled, { css } from 'styled-components';

interface WrapperProps {
  shouldShowLine: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, shouldShowLine }) => css`
    margin-left: 4px;
    margin-bottom: 30px;
    position: relative;

    ${shouldShowLine &&
    css`
      &:not(.last)::before {
        content: '';
        width: 1px;
        height: calc(100% + 30px);
        position: absolute;

        left: calc(35px / 2 - 1px);
        top: calc(35px / 2);

        background-color: ${theme.colorPallete.base[320]};
        z-index: 1;
      }

      &.completed {
        &::before {
          background-color: ${theme.colorPallete.primary[200]};
        }
      }
    `}
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 24px;
`;

export const ModuleNumber = styled.p`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    z-index: 3;

    border-radius: 50%;
    background-color: ${theme.colorPallete.base[600]};
    border: 1px solid ${theme.colorPallete.base[400]};

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};

    &.completed {
      background-color: #70380a;
      border: 1px solid #fe7000;
    }
  `}
`;

export const ModuleTitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 35px 30px 35px;
    padding: 24px;
    border-radius: 8px;
    max-width: 581px;

    background-color: ${theme.colorPallete.base[600]};
    font-family: ${theme.fonts.fontFamily.sans};

    h4 {
      font-size: ${theme.fonts.fontSize.md};
      font-weight: ${theme.fonts.fontWeight.bold};
      margin-bottom: 10px;
    }

    p {
      font-size: ${theme.fonts.fontSize.xs};
      color: ${theme.colorPallete.base[200]};
      margin-bottom: 24px;
    }
  `}
`;

export const DownloadButton = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 175px;
    padding-left: 16px;

    background-color: transparent;
    border: 1px solid ${theme.colorPallete.white};
    border-radius: 4px;

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.xs};
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colorPallete.white};

    .icon-container {
      padding: 9px;
      background-color: ${theme.colorPallete.white};
    }
  `}
`;
