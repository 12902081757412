import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { main as theme } from '@themes';

import { Tag, Text } from '@components';

import { styles } from './Keywords.styles';

const Wrapper = styled.div`
  ${styles}
`;

const Keywords = React.memo(({ data }) => {
  let KeywordsKey = 0;

  return (
    <Wrapper>
      <div className="block__title">
        <Text
          as="h3"
          size="1.6rem"
          dsize="1.6rem"
          color={theme.colorPallete.white}
          weight={theme.fonts.fontWeight.bold}
        >
          Palavras-Chave
        </Text>
      </div>
      <div className="block__keywords">
        {data &&
          data.map((item, index) => {
            KeywordsKey = index;
            return (
              <Tag
                className="block__keywords--item"
                text={item.tag}
                key={`tag-${KeywordsKey}`}
              />
            );
          })}
      </div>
    </Wrapper>
  );
});

Keywords.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
};

Keywords.defaultProps = {
  data: {},
};

export default Keywords;
export { Keywords };
