/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'next/link';
import _ from 'lodash';

// Actions
import { actions as favoriteActions } from '@store/ducks/favorite';

// Components
import { Text, IconCheck, IconError } from '@components';

// Containers
import { VideoThumb } from '@containers';

// Themes
import { main as theme } from '@themes';

// Styles
import { styles } from './ListVideos.styles';

const Wrapper = styled.div`
  ${styles}
`;

const ListVideos = ({ data, refreshToken }) => {
  const dispatch = useDispatch();

  const handleDeleteItem = (item) => {
    dispatch(
      favoriteActions.deleteFavoriteRequest({
        payload: {
          data: {
            id: item,
          },
          auth: {
            refresh_token: refreshToken,
          },
        },
      })
    );
  };

  let thumbKey = 0;

  return (
    <Wrapper>
      <div className="my-list-video__list">
        {data.map((item, index) => {
          thumbKey = index;
          return (
            <div className="my-list-video__item" key={thumbKey}>
              <div className="my-list-video__favorite">
                <button
                  className="my-list-video__fav-button"
                  type="button"
                  onClick={() => handleDeleteItem(item.id)}
                >
                  <span className="check">
                    <IconCheck size="20px" color={theme.colorSystem.success} />
                  </span>
                  <span className="uncheck">
                    <IconError
                      size="20px"
                      color={theme.colorPallete.base[200]}
                    />
                  </span>
                  <span className="label">
                    <Text
                      dsize="1rem"
                      size="1rem"
                      color={theme.colorPallete.base.default}
                    >
                      Remover da Lista
                    </Text>
                  </span>
                </button>
              </div>
              <div className="my-list-video__thumb">
                <Link href="/curso/aula/[aula]" as={item.url}>

                  <VideoThumb
                    alt={item.title}
                    img={_.get(
                      item,
                      'video.thumbnails[2].link',
                      'https://i.vimeocdn.com//video//883665447_640x360.jpg?r=pad'
                    )}
                    radius="6px"
                    time={item?.progress?.progress?.toString() || '0'}
                    source="external"
                    iconSize="30px"
                  />

                </Link>
              </div>
              <div className="my-list-video__title">
                <Text
                  as="h3"
                  dsize="1.6rem"
                  size="1.6rem"
                  weight="bold"
                  dheight="2rem"
                  color={theme.colorSystem.white}
                >
                  {item.title}
                </Text>
              </div>
              <div className="my-list-video__subtitle">
                {item.header && (
                  <Text
                    as="p"
                    dsize="1.2rem"
                    size="1.2rem"
                    dheight="1.6rem"
                    transform="uppercase"
                    color={theme.colorPallete.base[400]}
                  >
                    {`${item.header.type} .  ${item.header.author}`}
                  </Text>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

ListVideos.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  refreshToken: PropTypes.string,
};

ListVideos.defaultProps = {
  data: [],
  refreshToken: '',
};

export default ListVideos;
export { ListVideos };
