/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Actions
import { actions as checklistActions } from '@store/ducks/checklist';

// RC-Progress lib
import { Circle } from 'rc-progress';

// Components
import { Button, Text, IconRedo } from '@components';

// Theme
import { main as theme } from '@themes';

// Styles
import { styles } from './LessonChecklistResults.styles';

const Wrapper = styled.div`
  ${styles}
`;

const LessonChecklistResults = ({ userAnswers, total, url }) => {
  const dispatch = useDispatch();

  const { push, query } = useRouter();
  const [percent, setPercent] = useState(0);
  const [message, setMessage] = useState('');

  const rightAnswers = userAnswers.filter((item) => item).length;

  useEffect(() => {
    const result = (rightAnswers * 100) / total;
    setPercent(result.toString());

    if (result === 100) {
      setMessage('Parabéns!');
    } else if (result >= 50) {
      setMessage('Muito bom!');
    } else if (result > 0) {
      setMessage('Quase lá.');
    } else {
      setMessage('Tente novamente.');
    }
  }, [userAnswers, total]);

  const resetChecklist = () => {
    dispatch(
      checklistActions.resetChecklistRequest({
        data: {
          slug: query.aula,
        },
      })
    );
    return push(url);
  };

  return (
    <Wrapper>
      <div className="content__header">
        <Text
          as="h2"
          weight={theme.fonts.fontWeight.bold}
          dsize="3.2rem"
          size="3.2rem"
          color={theme.colorPallete.primary.default}
        >
          Checklist
        </Text>

        <Text
          weight={theme.fonts.fontWeight.bold}
          dsize="1.6rem"
          size="1.6rem"
          color={theme.colorPallete.black}
        >
          Resumo de desempenho
        </Text>
      </div>
      <div className="checklist-results__body-wrapper">
        <div className="content__body">
          <Text
            weight={theme.fonts.fontWeight.bold}
            dsize="3.2rem"
            size="2.4rem"
            color={theme.colorPallete.base.default}
          >
            {message}
          </Text>

          <div className="content__graph">
            <div className="content__graph__progress" id="progress">
              <Circle
                className="circle-graph"
                percent={percent}
                strokeWidth="15"
                trailColor="transparent"
                strokeColor={theme.colorSystem.success}
                strokeLinecap="butt"
              />

              <div className="content__graph__progress__inner">
                <Text
                  dsize="9.2rem"
                  size="9.2rem"
                  color={theme.colorPallete.base.default}
                  weight={theme.fonts.fontWeight.bolder}
                >
                  {`${userAnswers.filter((item) => item).length}`}
                </Text>
                <Text
                  dsize="1.4rem"
                  size="1.4rem"
                  color={theme.colorPallete.base.default}
                  weight={theme.fonts.fontWeight.bold}
                >
                  Acertos
                </Text>
              </div>
            </div>
          </div>

          <Text
            weight={theme.fonts.fontWeight.medium}
            dsize="1.6rem"
            size="1.4rem"
            color={theme.colorPallete.base.default}
          >
            {`Do total de ${total} questões`}
          </Text>
        </div>

        <div className="content__interactions">
          <div className="content__interactions__show-answer">
            <Button
              variant="primary"
              size="big"
              onClick={() =>
                push('/curso/aula/[aula]', `/curso/aula/${query.aula}`)
              }
            >
              Finalizar
            </Button>
          </div>

          <div className="content__interactions__redo">
            <button
              type="button"
              className="redo-button"
              onClick={resetChecklist}
            >
              <Text
                weight={theme.fonts.fontWeight.bold}
                dsize="1.6rem"
                size="1.6rem"
                color={theme.colorPallete.base.default}
              >
                <IconRedo size="19px" color={theme.colorPallete.base.default} />
                <span>Refazer</span>
              </Text>
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

LessonChecklistResults.propTypes = {
  userAnswers: PropTypes.arrayOf(PropTypes.bool.isRequired),
  total: PropTypes.number,
  url: PropTypes.string,
};

LessonChecklistResults.defaultProps = {
  userAnswers: [],
  total: 0,
  url: '/',
};

export default LessonChecklistResults;
export { LessonChecklistResults };
