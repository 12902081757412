import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

export const WrapperLoading = styled.div`
  ${({ theme }) => css`
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: ${theme.colorPallete.base[680]};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const ResultWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 36px 24px;
    background-color: ${theme.colorPallete.base[680]};
    border-radius: 4px;

    @media (max-width: 1025px) {
      min-height: 150vw;
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.gg};
    color: ${theme.colorPallete.white};
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.regular};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.base[150]};
  `}
`;

export const ResultGraphic = styled.div`
  width: 65%;
  position: relative;
  margin-block: 32px;

  @media (min-width: 1025px) {
    width: 25%;
    margin-block: 15px 0;
  }
`;

export const CorrectAnswers = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.xxg};
    color: ${theme.colorPallete.white};

    span {
      font-weight: ${theme.fonts.fontWeight.regular};
      font-size: ${theme.fonts.fontSize.md};
    }
  `}
`;

export const ButtonsArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    align-items: stretch;
    justify-content: stretch;
    width: 80%;

    @media (min-width: 1025px) {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  `}
`;

export const Redo = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    background-color: transparent;
    gap: 14px;
    cursor: pointer;
    padding: 0;
    border: 2px solid #fff;
    border-radius: 4px;
    height: 44px;
    text-align: center;

    @media (min-width: 1025px) {
      border: none;
      text-align: left;
      justify-content: flex-start;
    }
  `}
`;

export const NextLesson = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    text-align: center;
    height: 44px;
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    background-color: ${theme.colorPallete.primary[400]};
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
    }

    :hover {
      background-color: ${theme.colorPallete.primary[300]};
    }

    @media (min-width: 1025px) {
      padding-left: 48px;
      padding-right: 36px;
      text-align: left;
      justify-content: flex-start;
    }
  `}
`;
