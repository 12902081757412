import { useState } from 'react';

import * as S from './MenuMobile.styles';

import { MenuItems, MenuMobileProps } from './types';

export const MenuMobile = ({
  aulasContent,
  descricaoContent,
  materialContent,
}: MenuMobileProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItems.aulas);

  const handleOnClick = (item: MenuItems) => {
    setSelectedMenuItem(item);
  };

  const menuContent = {
    aulas: aulasContent,
    descricao: descricaoContent,
    material: materialContent,
  };
  return (
    <S.Wrapper>
      <S.Navigation>
        <S.NavigationItem
          className={selectedMenuItem === MenuItems.aulas ? 'active' : null}
          onClick={() => handleOnClick(MenuItems.aulas)}>
          Vídeos
        </S.NavigationItem>
        <S.NavigationItem
          className={selectedMenuItem === MenuItems.descricao ? 'active' : null}
          onClick={() => handleOnClick(MenuItems.descricao)}>
          Descrição
        </S.NavigationItem>
        <S.NavigationItem
          className={selectedMenuItem === MenuItems.material ? 'active' : null}
          onClick={() => handleOnClick(MenuItems.material)}>
          Material
        </S.NavigationItem>
      </S.Navigation>
      <div className={selectedMenuItem}>{menuContent[selectedMenuItem]}</div>
    </S.Wrapper>
  );
};
