import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Circle } from 'rc-progress';
import { main } from 'themes';
import { IconArrow, IconLoading } from 'components';
import { quizApi } from 'services/msv2-api';
import * as S from './Quiz.styles';
import { ListAnswered } from '../ListAnswered';
import { useWindowSize } from 'helpers/useWindowSize';
import { orderArrayByOrder } from 'helpers';
import { useSelector } from 'react-redux';
import { Lesson } from '@libs/ms-api';

interface QuizProps {
  selectedLessonOrQuizOption: Lesson;
  handleNextVideo: () => void;
  returnNextContentButtonMessage: () => string;
  showNextButton: boolean;
  setShowNextButton: (value: React.SetStateAction<boolean>) => void;
  hasNextLesson: boolean;
  checkIfTheNextContentIsLastOfModule();
  isLastContent: boolean;
}

const Quiz = ({
  selectedLessonOrQuizOption,
  handleNextVideo,
  returnNextContentButtonMessage,
  showNextButton,
  setShowNextButton,
  hasNextLesson,
  checkIfTheNextContentIsLastOfModule,
  isLastContent,
}: QuizProps) => {
  const {
    lessonReducer: { lessonData },
  }: any = useSelector((state) => state);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [correctAnswersTotal, setCorrectAnswersTotal] = useState(0);
  const [quiz, setQuiz] = useState(null);

  const { width } = useWindowSize();
  const isTablet = !!width && width < 1025;

  const countCorrectAnswers = async (quizData?: any) => {
    if (quizData) {
      const teste = await quizData?.checklist.reduce(
        (count, question) =>
          count +
          (question.options.filter(
            (option) =>
              option.id === question?.answered?.option &&
              option.correct_option === 'true',
          ).length ===
            1),
        0,
      );
      setCorrectAnswersTotal(teste);
    } else {
      const teste = await quiz?.checklist.reduce(
        (count, question) =>
          count +
          (question.options.filter(
            (option) =>
              option.id === question?.answered?.option &&
              option.correct_option === 'true',
          ).length ===
            1),
        0,
      );
      setCorrectAnswersTotal(teste);
    }
  };

  const checkIfQuizIsDone = (dataQuiz?: any) => {
    let allQuestionsAnswered;
    if (dataQuiz) {
      allQuestionsAnswered = dataQuiz.checklist.every(
        (question) => question.answered,
      );
    } else {
      allQuestionsAnswered = quiz.checklist.every(
        (question) => question.answered,
      );
    }

    if (allQuestionsAnswered) {
      if (dataQuiz) {
        countCorrectAnswers(dataQuiz);
      } else {
        countCorrectAnswers();
      }
      setShowQuizResult(true);
      checkIfTheNextContentIsLastOfModule();
      setShowNextButton(true);
      if (isLastContent) {
        setShowNextButton(false);
      }
    } else {
      setShowQuizResult(false);
    }
  };

  const handleShowAndNextVideo = () => {
    setShowNextButton(false);
    handleNextVideo();
  };

  const getChecklistData = async () => {
    const token = Cookies.get('tokenClient');

    try {
      const response = await quizApi.getQuiz({
        token,
        slug: selectedLessonOrQuizOption?.lessonSlug,
      });

      const checklistData = response.data.content.instance;
      const orderedQuestions = orderArrayByOrder(checklistData.checklist);
      const formattedChecklist = {
        ...checklistData,
        checklist: orderedQuestions,
      };

      setQuiz(formattedChecklist);

      checkIfQuizIsDone(formattedChecklist);
    } catch (err) {
      console.log(err);
    }
  };

  const resetQuiz = async () => {
    const token = Cookies.get('tokenClient');

    try {
      await quizApi.resetQuiz({
        token,
        slug: selectedLessonOrQuizOption?.lessonSlug,
      });
      setShowQuizResult(false);
      setQuiz(null);

      getChecklistData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!quiz || selectedLessonOrQuizOption.id !== quiz.id) {
      setQuiz(null);
      getChecklistData();
    }
  }, [selectedLessonOrQuizOption]);

  if (!quiz)
    return (
      <S.WrapperLoading>
        <IconLoading />
      </S.WrapperLoading>
    );

  return (
    <S.Wrapper>
      {showQuizResult && (
        <S.ResultWrapper>
          <S.TitleContainer>
            <S.Title>
              {correctAnswersTotal === 0
                ? 'Você finalizou o quiz.'
                : 'Parabéns, você finalizou o quiz!'}
            </S.Title>
            <S.Subtitle>Confira seu resultado:</S.Subtitle>
          </S.TitleContainer>
          <S.ResultGraphic>
            <Circle
              className="circle-graph"
              percent={(correctAnswersTotal * 100) / quiz?.total_questions}
              strokeWidth={15}
              trailWidth={15}
              trailColor={main.colorPallete.base[400]}
              strokeColor={main.colorSystem.successLight}
              strokeLinecap="butt"
            />
            <S.CorrectAnswers>
              {correctAnswersTotal}
              <span>{correctAnswersTotal < 2 ? 'Acerto' : 'Acertos'}</span>
            </S.CorrectAnswers>
          </S.ResultGraphic>
          <S.ButtonsArea>
            <S.Redo onClick={resetQuiz}>
              {!isTablet && <IconArrow width="15" height="15" />}
              Refazer o quiz
            </S.Redo>
            {hasNextLesson && showNextButton && (
              <S.NextLesson
                onClick={() => handleShowAndNextVideo()}
                type="button">
                {returnNextContentButtonMessage()}
                <IconArrow width="15" height="15" />
              </S.NextLesson>
            )}
          </S.ButtonsArea>
        </S.ResultWrapper>
      )}{' '}
      {quiz && !showQuizResult && (
        <ListAnswered
          quiz={quiz}
          setQuiz={setQuiz}
          checkIfQuizIsDone={checkIfQuizIsDone}
        />
      )}
    </S.Wrapper>
  );
};

export default Quiz;
export { Quiz };
