import React from 'react';

// Components
import { Logo, Text } from '@components';

// Templates
import { SignUp } from '@templates';

// Themes
import { main } from '@themes';

import { Form } from './containers';

// Styles
import { Wrapper, InputSection, TitleSection } from './NewPassword.styles';

const NewPassword = () => {
  return (
    <>
      <SignUp titlePage="Redefinir senha" highlight=" ">
        <Wrapper>
          <div className="mobile-only">
            <Logo size="150px" />
          </div>

          <TitleSection>
            <Text
              as="h6"
              size="1.6rem"
              dsize="1.6rem"
              color={main.colorSystem.baseColor400}
              weight="bold"
              height="normal"
              dheight="normal">
              Redefinir Senha
            </Text>
            <Text
              as="h1"
              size="2rem"
              dsize="2rem"
              color={main.colorSystem.baseColor}
              family={main.fonts.fontFamily.serif}
              weight="bold"
              height="normal"
              dheight="normal">
              Cadastre sua nova senha
            </Text>
          </TitleSection>
          <InputSection>
            <Form />
          </InputSection>
        </Wrapper>
      </SignUp>
    </>
  );
};

export { NewPassword };
export default NewPassword;
