import styled, { css } from 'styled-components';

const FormStyles = styled.form`
  .form {
    &__input {
      margin-top: 2.4rem;
    }

    &__button {
      width: 100%;
      margin: 4rem 0 14rem 0;
    }
`;

const FooterButton = styled.div`
  width: 100%;
  max-width: 33.5rem;
  margin: 3.6rem 0 0 0;
`;

const linkSectionStyles = () => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;

  a,
  span {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;

const LinkSection = styled.section`
  ${linkSectionStyles}
`;

export { FormStyles, FooterButton, LinkSection };
