import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  max-width: 120rem;
  background-color: ${theme.colorPallete.base[700]};

  .notifications__body {
    width: 100%;
    padding: 3.4rem 0;
  }
`;

export default styles;
export { styles };
