import Link from 'next/link';

import { IconArrowLeft } from '@components';
import { CertificateDto } from '@libs/ms-api';
import { IconDownloadSimple, IconPrinter, IconLinkedin } from '@components';
import {
  CertificateTemplate,
  CertificateTemplateSkeleton,
} from '../../../components';

import { Skeleton } from '@skeletons/Skeleton';

import * as S from './PrivateCertificateContent.styles';

interface PrivateCertificateContentProps {
  certificateContent: CertificateDto;
}

const PrivateCertificateContentSkeleton = () => {
  return (
    <S.Wrapper>
      <S.TopNavigation>
        <Skeleton width={85} />
      </S.TopNavigation>
      <S.ContentContainer>
        <CertificateTemplateSkeleton />
        <S.CertificateInfo>
          <Skeleton width={60} height={20} />
          <Skeleton width={400} height={30} mt={12} />
          <Skeleton width={380} height={10} mt={16} />
          <Skeleton width={380} height={10} mt={4} />
          <Skeleton width={280} height={10} mt={4} />
          <S.ButtonsContainer>
            <Skeleton width={275} height={60} />
            <Skeleton width={275} height={60} />
          </S.ButtonsContainer>
          <Skeleton width={180} height={10} mt={36} />
          <Skeleton width={300} height={60} mt={24} />
        </S.CertificateInfo>
      </S.ContentContainer>
    </S.Wrapper>
  );
};

export const PrivateCertificateContent = ({
  certificateContent,
}: PrivateCertificateContentProps) => {
  const publicUrl = `https://v2.meusucesso.com/certificados/publico/${certificateContent?.id}`;
  const generateLinkedinShareUrl = () => {
    const baseUrl = 'https://www.linkedin.com/profile/add';
    if (!certificateContent) return;
    const dateString = certificateContent?.createdAt;
    const dateParts = dateString.split('-');
    const dateYear = dateParts[0];
    const dateMonth = dateParts[1];

    const params = {
      startTask: 'CERTIFICATION_NAME',
      name: certificateContent?.course?.title,
      organizationId: '5190949',
      organizationName: 'meuSucesso.com',
      issueYear: dateYear,
      issueMonth: dateMonth,
      certUrl: publicUrl,
      certId: certificateContent?.id,
    };

    const queryString = Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    return `${baseUrl}?${queryString}`;
  };

  if (!certificateContent) return <PrivateCertificateContentSkeleton />;

  return (
    <S.Wrapper>
      <S.TopNavigation>
        <Link href={'/certificados'}>
          <S.BackButtonWrapper>
            <IconArrowLeft />
            Voltar
          </S.BackButtonWrapper>
        </Link>
      </S.TopNavigation>

      <S.ContentContainer>
        <CertificateTemplate
          title={certificateContent?.course?.title}
          studentName={certificateContent?.student?.socialName}
          issuanceDate={certificateContent?.createdAt}
          durationInSec={certificateContent?.course?.durationInSec}
        />
        <S.CertificateInfo>
          <S.CourseSubtitle>Curso</S.CourseSubtitle>
          <S.CourseTitle>{certificateContent?.course?.title}</S.CourseTitle>
          <S.Description>
            Parabéns! Você acabou de concluir mais um passo em sua jornada
            empreendedora. Emita já seu certificado abaixo ou compartilhe em sua
            redes sociais.
          </S.Description>
          <S.ButtonsContainer>
            <S.PrimaryButton>
              <IconDownloadSimple color="#fff" size="24px" /> Baixar Certificado
            </S.PrimaryButton>
            <S.SecondaryButton>
              <IconPrinter /> Imprimir
            </S.SecondaryButton>
          </S.ButtonsContainer>
          <S.Description>Divulgue nas redes sociais:</S.Description>
          <S.ShareButton href={generateLinkedinShareUrl()} target="_blank">
            <IconLinkedin /> Compartilhar no Linkedin
          </S.ShareButton>
        </S.CertificateInfo>
      </S.ContentContainer>
    </S.Wrapper>
  );
};
