import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  /* max-width: 78rem; */
  margin: 0 auto;
  padding: 0 1rem;

  .content__header {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    span {
      width: 10rem;
      text-align: right;
    }

    @media all and (min-width: ${theme.breakpoints.medium}) {
      display: flex;

      span {
        width: 100%;
      }
    }
  }

  .content__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content__graph {
      width: 100%;
      max-width: 30rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-self: center;
      padding-left: 0.5rem;

      .content__graph__progress {
        width: 24rem;
        height: 24rem;
        min-width: 24rem;
        min-height: 24rem;
        margin: 6rem auto 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        position: relative;
        background: ${theme.colorPallete.base[100]};

        .circle-graph {
          position: absolute;
          z-index: 1;
        }

        .content__graph__progress__inner {
          width: 16.8rem;
          height: 16.8rem;
          min-width: 16.8rem;
          min-height: 16.8rem;
          border-radius: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 2;
          background: ${theme.colorPallete.white};

          span {
            margin-top: -2rem;
            &:last-of-type {
              margin-top: -2rem;
            }
          }
        }
      }
    }
  }

  .content__interactions {
    width: 100%;
    max-width: 30rem;
    padding-top: 4rem;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    &__redo {
      margin: 3rem 0 1rem;
      display: grid;

      .redo-button {
        background: transparent;
        border: none;
        cursor: pointer;
        margin: auto;
        padding: 0.1rem;

        span {
          display: inline-flex;
          margin-left: 0.8rem;
          display: inline-flex;
          height: 2.5rem;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    .content__body {
      .content__graph {
        margin: 0;
      }
    }

    .content__interactions {
      margin: 0;
    }
  }

  .checklist-results__body-wrapper {
    background: ${theme.colorPallete.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 55px 20px;
  }
`;

export default styles;
export { styles };
