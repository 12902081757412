import React from 'react';

// Components
import { Logo, Text, Button } from '@components';

// Styles

// Themes
import { main } from '@themes';

// Templates
import { SignUp } from '@templates';
import { Wrapper, InputSection, TitleSection } from './EmailSent.styles';

const EmailSent = () => {
  return (
    <>
      <SignUp titlePage="E-mail enviado" highlight=" ">
        <Wrapper>
          <div className="mobile-only">
            <Logo size="150px" />
          </div>

          <TitleSection>
            <Text
              as="h6"
              size="1.6rem"
              dsize="1.6rem"
              color={main.colorSystem.baseColor400}
              weight="bold"
              height="normal"
              dheight="normal">
              Redefinir Senha
            </Text>
            <Text
              as="h1"
              size="2rem"
              dsize="2.4rem"
              color={main.colorSystem.baseColor}
              family={main.fonts.fontFamily.serif}
              weight="bold"
              height="normal"
              dheight="normal">
              Sua senha foi cadastrada com sucesso.
            </Text>
          </TitleSection>
          <InputSection>
            <Button
              variant="primary"
              size="medium"
              link="internal"
              href="/login">
              Efetuar login
            </Button>
          </InputSection>
        </Wrapper>
      </SignUp>
    </>
  );
};

export { EmailSent };
export default EmailSent;
