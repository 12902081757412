import React from 'react';
import Image from 'next/image';
import {
  IconChecked,
  IconLock,
  IconCheckCircle,
  IconNotePencil,
} from 'components';
import { characterLimiter } from 'helpers';
import { Content } from '../../types';
import { formatDuration } from 'helpers/dates';
import * as S from './ClassCard.styles';
import { LessonsDto } from '@libs/ms-api';

interface ClassCardProps {
  lesson: LessonsDto;
  moduleNumber: number;
}

interface CardContentProps {
  disabled?: boolean;
}
export const ClassCard = ({ lesson, moduleNumber }: ClassCardProps) => {
  const disabled = lesson.status && lesson.status === 'pendente';
  const CardContent = ({ disabled }: CardContentProps) => {
    return (
      <S.Wrapper className={disabled ? 'disabled' : null}>
        <S.ImageContainer>
          <Image
            src={lesson?.thumbnails[3].link}
            alt="thumb_video"
            layout="fill"
            objectFit="cover"
          />
          <S.CheckCompletion
            className={lesson.progress.percent > 0.98 ? 'completed' : null}>
            <IconChecked
              className="icon-checked"
              strokeWidth="2.5"
              width="10"
              height="8"
            />
          </S.CheckCompletion>
          {disabled && (
            <S.DisabledLock>
              <IconLock color="#F26522" />
            </S.DisabledLock>
          )}

          {(lesson.category.type === 'liveclass' ||
            lesson.category.type === 'extra') && (
            <S.BonusContent>Bônus</S.BonusContent>
          )}
        </S.ImageContainer>
        <S.ContentContainer>
          <S.HeaderContainer>
            <S.Title title={lesson?.category.title}>
              {characterLimiter(
                `${lesson?.category?.title} ${
                  lesson?.category?.type !== 'liveclass'
                    ? `| ${lesson?.category?.subtitle}`
                    : ''
                }`,
                30,
              )}
            </S.Title>
            {lesson?.durationInSec && (
              <S.Duration>
                {formatDuration(Number(lesson?.durationInSec))}
              </S.Duration>
            )}
          </S.HeaderContainer>

          <S.Description title={lesson?.name}>{lesson?.name}</S.Description>

          <S.ContainerProgress>
            <S.ProgressBar>
              <S.Progress progress={lesson?.progress?.percent} />
            </S.ProgressBar>
            <p>{`${(lesson?.progress?.percent * 100).toFixed()}%`}</p>
          </S.ContainerProgress>
        </S.ContentContainer>
        <S.TagsArea>
          {lesson.status === 'pendente' ? (
            <S.StatusLabel>EM BREVE</S.StatusLabel>
          ) : (
            <>
              {lesson?.hasChecklist && (
                <S.ExerciseContainer
                  className={
                    lesson?.progress?.hasAnsweredChecklist ? 'completed' : null
                  }>
                  {lesson?.progress?.hasAnsweredChecklist ? (
                    <>
                      <IconCheckCircle /> <p>Exercício realizado</p>
                    </>
                  ) : (
                    <>
                      <IconNotePencil /> <p>Exercício Disponível</p>
                    </>
                  )}
                </S.ExerciseContainer>
              )}
            </>
          )}
        </S.TagsArea>
      </S.Wrapper>
    );
  };

  if (disabled) return <CardContent disabled={disabled} />;

  return (
    <S.WrapperLink href={`aula/${lesson?.slug}`} passHref>
      <CardContent disabled={disabled} />
    </S.WrapperLink>
  );
};
