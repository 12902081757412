/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Text } from '@components';

// Theme
import { main } from '@themes';

// Containers
import { Button, ListVideos } from './containers';

// Styles
import { Wrapper } from './Search.styles';

const Search = ({ refreshToken }) => {
  const [results, setResults] = useState(null);
  const router = useRouter();
  const { searchData } = useSelector((state) => state.searchReducer);

  useEffect(() => {
    // Prevent page reload/update before user send the terms in search field
    if (searchData?.term === router?.query?.term) {
      setResults(searchData);
    }
  }, [searchData]);

  return (
    <Wrapper>
      <header>
        <Text as="h3" dsize="1.8rem" size="1.8rem" weight="bold" dheight="180%">
          <span>Você buscou por: </span>
          &nbsp;
          <Text
            as="span"
            dsize="2.2rem"
            size="2.2rem"
            weight="bold"
            color={main.colorPallete.primary.default}
          >
            {router?.query?.term}
          </Text>
        </Text>
        <Text as="h4" dsize="1.4rem" weight="normal" dheight="2.2rem">
          {`${results?.total || '0'} resultados`}
        </Text>
      </header>

      {results?.data && <ListVideos data={results} />}

      {results?.data &&
        results.page !== results.lastPage - 1 &&
        results.total !== 0 && (
          <Button
            changeInitialSearchData={results}
            refreshToken={refreshToken}
          />
        )}

      {!results?.total && (
        <div className="result-message">
          <Text
            as="h1"
            dsize="3.2rem"
            size="3.2rem"
            weight="bold"
            color={main.colorPallete.primary.default}
          >
            Nenhum resultado foi encontrado
          </Text>
        </div>
      )}
    </Wrapper>
  );
};

Search.propTypes = {
  refreshToken: PropTypes.string,
};

Search.defaultProps = {
  refreshToken: '',
};

export { Search };
export default Search;
