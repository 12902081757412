import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Validations
import { useFormik } from 'formik';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { GoogleButton, FacebookButton, Paper, Radio, Text } from '@components';

// Themes
import { main as theme } from '@themes';

// Styles
import { styles } from './UserAccountInfo.styles';

const Wrapper = styled.div`
  ${styles}
`;

const UserAccountInfo = React.memo(({ refreshToken }) => {
  const { profileData } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const array = [
    {
      label: 'Sim',
      value: true,
      id: 'newsletter__radio__yes',
      disabled: false,
    },
    {
      label: 'Não',
      value: false,
      id: 'newsletter__radio__no',
      disabled: false,
    },
  ];

  const formik = useFormik({
    initialValues: {
      newsletter: profileData?.profile?.newsletter || false,
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        email: profileData.email,
      };

      dispatch(
        userActions.editNewsletterRequest({
          payload: { auth: { refresh_token: refreshToken }, data },
        })
      );
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue('newsletter', e.target.value);
    return formik.handleSubmit();
  };

  return (
    <Wrapper className="account-info">
      <Paper className="paper">
        <div className="block__title">
          <Text
            as="h3"
            size="1.8rem"
            dsize="1.8rem"
            weight="bold"
            color={theme.colorPallete.primary.default}
          >
            Informações de conta
          </Text>
        </div>

        <div className="block__content">
          <form
            className="block__content__newsletter"
            onSubmit={formik.handleSubmit}
            method="post"
          >
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              Deseja receber nossa newsletter?
            </Text>
            <Radio
              objects={array.map((item) => ({
                ...item,
                checked: item.value === formik.values.newsletter,
              }))}
              name="newsletter"
              display="line"
              onChange={
                (e) => handleChange(e)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </form>
          <div className="block__content__social">
            <Text
              as="h4"
              size="1.6rem"
              dsize="1.6rem"
              color={theme.colorPallete.base[600]}
              weight="bold"
            >
              Deseja vincular suas redes sociais?
            </Text>
            <div className="block__content__social__buttons">
              <div className="social__button__container">
                <GoogleButton
                  refreshToken={refreshToken}
                  id="profile__button__logwith--google"
                />
              </div>
              <div className="social__button__container">
                <FacebookButton
                  refreshToken={refreshToken}
                  id="profile__button__logwith--facebook"
                />
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Wrapper>
  );
});

UserAccountInfo.propTypes = {
  refreshToken: PropTypes.string,
};

UserAccountInfo.defaultProps = {
  refreshToken: '',
};

export default UserAccountInfo;
export { UserAccountInfo };
