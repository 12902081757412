export enum MenuItems {
  aulas = 'aulas',
  descricao = 'descricao',
  material = 'material',
}

export interface MenuMobileProps {
  aulasContent: JSX.Element;
  descricaoContent: JSX.Element;
  materialContent: JSX.Element;
}
