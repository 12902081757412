/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Actions
import { actions as checklistActions } from '@store/ducks/checklist';

// Helpers
import {
  // getKeyOfRightAlternative,
  // transformNumberToLetters,
  ramdomizeArray,
} from '@helpers';

// Components
import { Button, ChecklistAnswer, Text } from '@components';

// Theme
import { main as theme } from '@themes';

// Styles
import { styles } from './LessonChecklist.styles';

// Containers
import { SeeResultsButton } from './containers';

const Wrapper = styled.div`
  ${styles}
`;

const LessonChecklist = ({
  currentQuestion,
  setCurrentQuestion,
  toggleShowResult,
  checklisAnswered,
  userAnswers,
}) => {
  const router = useRouter();

  const dispatch = useDispatch();

  const { checklistData } = useSelector((state) => state.checklistReducer);

  const [answeredQuestion, toggleAnsweredQuestion] = useState(0);

  const [alternative, toggleAlternative] = useState(0);

  const answeredRight = userAnswers[currentQuestion.order - 1];

  const [sendQuestion, toggleSendQuestion] = useState(answeredRight);

  useEffect(() => {
    if (currentQuestion?.answered?.option) {
      const optionId = currentQuestion.answered.option;

      currentQuestion.options.find((item, itemKey) => {
        if (item.id === optionId) {
          toggleAlternative(itemKey + 1);
          toggleAnsweredQuestion(itemKey + 1);
          return itemKey;
        }
        return false;
      });
    }
  }, [currentQuestion?.answered?.option, currentQuestion?.options]);

  /**
   * * Exec every time user click on answer button:
   */
  const handleAnswerButton = () => {
    toggleSendQuestion(alternative !== 0);

    if (alternative !== 0) {
      const data = {
        lesson_slug: router.query.aula,
        question_id: currentQuestion.question_id,
        option_selected: {
          option_id: currentQuestion.options[alternative - 1].id,
          answer: true,
        },
      };

      return dispatch(
        checklistActions.sendChecklistAnswersRequest({
          payload: data,
        })
      );
    }
  };

  /**
   * * Exec when user change to the next question:
   */
  const handleChangeQuestion = () => {
    const actualQuestion = currentQuestion.order;
    // Update the current question:
    setCurrentQuestion({
      ...checklistData.checklist[actualQuestion],
      options: ramdomizeArray(checklistData.checklist[actualQuestion].options),
    });
    // Update the address:
    router.push(
      '/curso/checklist/[aula]/[question]',
      `/curso/checklist/${router.query.aula}/${actualQuestion + 1}`,
      { shallow: true }
    );
    // ? Check whether the user has answered the question correctly or not, for the button beginning with "Next question":
    toggleSendQuestion(userAnswers[currentQuestion.order]);
    toggleAlternative(0);
    return toggleAnsweredQuestion(0);
  };

  return (
    <Wrapper>
      <div className="content__header">
        {/* Title */}
        <Text
          as="h2"
          weight={theme.fonts.fontWeight.bold}
          dsize="3.2rem"
          size="3.2rem"
          color={theme.colorPallete.primary.default}
        >
          Checklist
        </Text>

        {/* Number of current question */}
        <Text
          weight={theme.fonts.fontWeight.bold}
          dsize="1.6rem"
          size="1.6rem"
          color={theme.colorPallete.primary.default}
        >
          {`${currentQuestion.order}/${checklistData?.total_questions}`}
        </Text>
      </div>
      <div className="content__question">
        {/* Number of current question, as a marker */}
        <Text
          className="content__question__number"
          family="serif"
          weight={theme.fonts.fontWeight.bold}
          dsize="3.2rem"
          size="3.2rem"
          color={theme.colorPallete.base.default}
        >
          {`${currentQuestion.order}.`}
        </Text>
        {/* Question text */}
        <Text
          as="p"
          className="content__question__text"
          dsize="1.6rem"
          size="1.6rem"
          color={theme.colorPallete.base.default}
        >
          {currentQuestion.question}
        </Text>
      </div>
      {/* Alternatives */}
      <div className="content__answers">
        <div className="content__answers__container">
          {currentQuestion.options.map((answer, key) => {
            return (
              <div className="answer" key={answer.id}>
                <ChecklistAnswer
                  alternative={alternative}
                  correct={answer.correct_option === 'true'}
                  label={answer.description}
                  line={key + 1}
                  reason={answer.reason}
                  sendQuestion={sendQuestion}
                  answeredQuestion={answeredQuestion}
                  toggleAlternative={toggleAlternative}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* Alternative reason */}
      {sendQuestion && (
        <div className="content__reasons">
          <Text
            className="answer-text"
            dsize="1.8rem"
            size="1.8rem"
            color={theme.colorSystem.success}
          >
            {/* {`Resposta correta = ${transformNumberToLetters(
              getKeyOfRightAlternative(currentQuestion.options)
            )}`} */}
            Justificativa
          </Text>
          <Text
            className="answer-text"
            dsize="1.8rem"
            size="1.8rem"
            color={theme.colorSystem.baseColor500}
          >
            {currentQuestion.options.filter((item) => item.reason)[0].reason}
          </Text>
        </div>
      )}

      {/* Back button/see results button */}
      <div className="content__interactions">
        <SeeResultsButton
          lastQuestion={checklisAnswered}
          setCurrentQuestion={setCurrentQuestion}
          toggleShowResult={toggleShowResult}
        />

        <div className="content__interactions__show-answer">
          {/* Answer button */}
          {!sendQuestion && (
            <Button
              variant="primary"
              size="big"
              onClick={() => handleAnswerButton()}
              disabled={alternative === 0}
            >
              Responder
            </Button>
          )}
          {/* Next question button */}
          {sendQuestion &&
            currentQuestion.order < checklistData.total_questions && (
              <Button
                variant="primary"
                size="big"
                onClick={() => handleChangeQuestion()}
              >
                Próxima Questão
              </Button>
            )}
        </div>
      </div>
    </Wrapper>
  );
};

LessonChecklist.propTypes = {
  currentQuestion: PropTypes.shape({
    answered: PropTypes.shape({
      option: PropTypes.string,
    }),
    order: PropTypes.number,
    question: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        correct_option: PropTypes.string,
        description: PropTypes.string,
        reason: PropTypes.string,
      })
    ),
    question_id: PropTypes.string,
  }),
  setCurrentQuestion: PropTypes.func,
  toggleShowResult: PropTypes.func,
  checklisAnswered: PropTypes.bool,
  userAnswers: PropTypes.arrayOf,
};

LessonChecklist.defaultProps = {
  currentQuestion: {},
  setCurrentQuestion: () => '',
  toggleShowResult: () => '',
  checklisAnswered: false,
  userAnswers: [],
};

export default LessonChecklist;
export { LessonChecklist };
