import React, { useEffect, useState } from 'react';
import { FiltersByTheme } from 'containers';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { LoggedIn } from 'templates';
import { assignCookieClientSide } from 'utils';
import { CarouselCards } from '../../containers';
import { popupsApi } from 'services/mirror-api';

import { actions as userActions } from 'store/ducks/user';

import {
  useGetWatchingData,
  useGetFilterTagsData,
  useGetBannersData,
} from 'libs/ms-api-react-query';

import { useGetCards } from 'hooks';

import { UpdateBillingModal, BannerSlider } from 'containers';
import { LessonZeroModal } from './containers';
import { Wrapper } from './home.styles';
import { msApiLib, api } from 'services/config/ms-api';
import { CourseTags, FilterTag } from 'libs/ms-api';

const Home = () => {
  const dispatch = useDispatch();

  const [currentFilter, setCurrentFilter] = useState<FilterTag | null>(null);
  const [popupInfo, setPopupInfo] = useState(null);

  const { data: filterTags } = useGetFilterTagsData(msApiLib, {
    enabled: true,
  });

  const { data: banners } = useGetBannersData(msApiLib, { enabled: true });

  const { data: watching } = useGetWatchingData(msApiLib, { enabled: true });

  const entrepreneursCoursesQuery = useGetCards('entrepreneurs', [
    currentFilter?.slug as CourseTags,
  ]);

  const thematicCoursesQuery = useGetCards('thematic', [
    currentFilter?.slug as CourseTags,
  ]);

  const token = Cookies.get('tokenClient');
  const refreshToken = Cookies.get('refreshtokenClient');

  const {
    userReducer: { userData, dataLayerRed },
  } = useSelector((state: any) => state);

  const fetchNPS = async (email: string) => {
    /* @ts-ignore */
    window.npsUserId = window.btoa(email);

    sessionStorage.removeItem('WiserEducacaoNPS');
    const npsAlreadyExists = !!document.querySelector('.container-nps');

    if (typeof email !== 'undefined' && !npsAlreadyExists) {
      /* @ts-ignore */
      if (window && window.WiserEducacaoNPS !== 'undefined') {
        /* @ts-ignore */
        window.WiserEducacaoNPS.start(window.npsUserId);
      }
    }
  };

  const fetchUserId = async () => {
    /* @ts-ignore */
    window.dataLayer = window.dataLayer || [];
    /* @ts-ignore */
    if (dataLayerRed?.userId !== null && dataLayerRed?.method !== null) {
      /* @ts-ignore */
      window.dataLayer.push({
        event: 'login',
        user_id: dataLayerRed?.userId,
        method: dataLayerRed?.method,
      });

      dispatch(userActions.resetDataLayer(null));
    }
  };

  const getPopupInfo = async () => {
    const response = await popupsApi.getPopup({
      token: token,
      refreshToken: refreshToken,
    });
    setPopupInfo(response?.data?.content?.instance);
    return response;
  };

  useEffect(() => {
    getPopupInfo();
    fetchUserId();
  }, []);

  useEffect(() => {
    fetchNPS(userData?.email);
  }, [userData?.email]);

  const handleCloseBillingModal = () =>
    assignCookieClientSide('showBillingModal', 'nvisible');

  const closePopup = () => {
    const popupCookieExpirationTime = popupInfo.alwaysShow ? 0.5 : 9999;
    Cookies.set('popupHome', popupInfo.idCookie, {
      expires: popupCookieExpirationTime,
    });
    setPopupInfo(null);
  };

  const shouldPopupShow = () => {
    const popupHomeCookie = Cookies.get('popupHome');
    const idPopup = popupInfo?.idCookie;

    if (idPopup && popupHomeCookie && popupHomeCookie !== idPopup) {
      return true;
    }
    if (!popupHomeCookie && idPopup) {
      return true;
    }
    return false;
  };
  const returnPopup = () => {
    return (
      <div
        className="container-notification-mss-warning wsr-notification-mss-accept"
        id="wsr-notification-mss-warning">
        <div
          id="wsr-notification-mss-accept__box"
          className="wsr-notification-mss-accept__main meusucesso">
          <div className="wsr-notification-mss-accept__content">
            <div className="imagem-topo">
              <img
                src={popupInfo.images[0]}
                alt="Produto"
                className="img-produto"
              />
            </div>

            <a href={popupInfo.link} target="_blank" rel="noreferrer">
              <button
                type="button"
                className="wsr-notification-mss-accept__agree-button"
                id="close-popup-buttton"
                tabIndex={-1}>
                {popupInfo.description}
              </button>
            </a>
            <button
              onClick={closePopup}
              type="button"
              className="close-popup-button">
              <img
                src={popupInfo.images[1]}
                id="wsr-notification-mss-accept__agree-button"
                alt="Talvez mais tarde"
                className="icon-btn-pop-home"
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    //@ts-ignore
    <LoggedIn refreshToken={refreshToken} titlePage="Home">
      {/* @ts-ignore */}
      <Wrapper
        //  @ts-ignore
        theme={{
          //@ts-ignore
          btnPopup:
            popupInfo?.styles?.btn && popupInfo?.styles?.btn !== ''
              ? popupInfo.styles.btn
              : '#f25225',
          bgPopup:
            popupInfo?.styles?.background &&
            popupInfo?.styles?.background !== ''
              ? popupInfo.styles.background
              : '#2b2826',
          hoverPopup:
            popupInfo?.styles?.hover && popupInfo?.styles?.hover !== ''
              ? popupInfo.styles.hover
              : '#f25225',
          colorTxtPopup:
            popupInfo?.styles?.text && popupInfo?.styles?.text !== ''
              ? popupInfo.styles.text
              : '#fff',
        }}>
        {userData && (
          <>
            <UpdateBillingModal
              //@ts-ignore
              visible={
                !userData.restriction &&
                !userData.should_update_billing &&
                userData.complient === 'active_defaulting' &&
                (!Cookies.get('showBillingModal') ||
                  Cookies.get('showBillingModal') === 'visible')
              }
              title="Caro assinante"
              message="Identificamos uma pendência nas suas informações de pagamento. Acesse seu perfil para resolvê-la."
              customCallBack={handleCloseBillingModal}
            />

            {/* <LessonZeroModal
              //@ts-ignore
              visible={!_.get(userData, 'ms1_aula_zero', false)}
              refreshToken={refreshToken}
              video={
                _.get(userData, 'user_trial.account_status', 'Trialing') ===
                  'Active'
                  ? '458339329'
                  : '454571640'
              }
            /> */}
          </>
        )}

        <BannerSlider content={banners} />
        {!!watching && (
          <CarouselCards
            content={watching}
            titleCarousel="Continue estudando"
          />
        )}

        <FiltersByTheme
          filters={filterTags}
          onChange={(filterSelected) => {
            setCurrentFilter(filterSelected[0]);
          }}
        />

        <CarouselCards
          // currentFilter={currentFilter}
          content={entrepreneursCoursesQuery?.content}
          loadedCards={entrepreneursCoursesQuery?.loadedCards}
          fetchNextPage={entrepreneursCoursesQuery?.fetchNextPage}
          titleCarousel={'Estudos de Caso'}
        />

        <CarouselCards
          // currentFilter={currentFilter}
          content={thematicCoursesQuery?.content}
          loadedCards={thematicCoursesQuery?.loadedCards}
          fetchNextPage={thematicCoursesQuery?.fetchNextPage}
          titleCarousel={'Séries'}
        />

        {shouldPopupShow() && returnPopup()}
      </Wrapper>
    </LoggedIn>
  );
};

export { Home };
export default Home;
