import styled, { css } from 'styled-components';

const loginStyles = ({ theme }) => css`
  width: 100%;
  max-width: 49rem;
  margin: auto;
  padding: 2rem 2rem 0 2rem;

  @media (max-width: ${theme.breakpoints.medium}) {
    padding: 6rem 2rem 0 2rem;
    margin-top: 0;
  }

  .mobile-only {
    display: none;
    @media (max-width: ${theme.breakpoints.medium}) {
      display: block;
    }
  }
`;

const titleSectionStyles = ({ theme }) => css`
  font-family: ${theme.fonts.fontFamily.sans};

  h6 {
    @media (max-width: ${theme.breakpoints.medium}) {
      margin-top: 5.5rem;
    }
  }

  h4 {
    margin: 1rem 0 2.4rem 0;
  }
`;

const inputSectionStyles = ({ theme }) => css`
  width: 100%;
  margin-top: 4.8rem;
  background-color: ${theme.colorSystem.primarybg};
`;

const Wrapper = styled.div`
  h1 {
    margin-top: 10px;
  }

  ${loginStyles}
`;

const TitleSection = styled.section`
  ${titleSectionStyles}
`;

const InputSection = styled.section`
  ${inputSectionStyles}
`;

export { Wrapper, InputSection, TitleSection };
