import { IconMedal, IconArrowBold } from 'components';

import * as S from './IssueCertificate.styles';
import { useFeatureToggle } from 'hooks';

export interface IssueCertificateProps {
  completed: boolean;
  handleIssueCertificate: () => Promise<void>;
}

const IssueCertificateComponent = ({
  completed,
  handleIssueCertificate,
}: IssueCertificateProps) => {
  return (
    <S.Wrapper className={completed ? 'completed' : null}>
      <S.TitleContainer>
        <S.ModuleNumber className={completed ? 'completed' : null}>
          <IconMedal />
        </S.ModuleNumber>
        <S.ModuleTitle>Curso Concluído!</S.ModuleTitle>
      </S.TitleContainer>
      <S.ContentContainer>
        <p>
          Parabéns! Você concluiu mais um passo em sua jornada empreendedora.
          <br />
          Clique no botão abaixo para emitir seu certificado:
        </p>
        <S.IssueCertificateButton
          onClick={() => handleIssueCertificate()}
          type="button">
          Emitir Certificado
          <IconArrowBold width="24" height="26" strokeWidth="1.8" />
        </S.IssueCertificateButton>
      </S.ContentContainer>
    </S.Wrapper>
  );
};

export const IssueCertificate = ({
  completed,
  handleIssueCertificate,
}: IssueCertificateProps) => {
  const IssueCertificateToggle = useFeatureToggle({
    ComponentWhenEnabled: () => (
      <IssueCertificateComponent
        completed={completed}
        handleIssueCertificate={handleIssueCertificate}
      />
    ),
    featureKeys: ['certificates'],
  });
  return <IssueCertificateToggle />;
};
