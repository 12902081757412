import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  background-color: transparent;

  .paper {
    display: block;

    .block__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;

      button {
        background-color: transparent;
        outline: none;
        display: flex;
        align-items: center;
        cursor: pointer;

        .block-title__button-text {
          text-decoration: underline;
          margin-right: 0.5rem;
          border-bottom: 0.1rem solid transparent;
        }

        &:hover {
          .block-title__button-text {
            color: ${theme.colorPallete.base[500]};
          }

          #icon-pencil path {
            fill: ${theme.colorPallete.base[500]};
          }
        }
      }
    }

    .block__content {
      display: block;
      width: 100%;

      div {
        margin-bottom: 2rem;

        &:last-child {
          margin: 0;
        }
      }

      @media all and (min-width: ${theme.breakpoints.medium}) {
        grid-template-areas:
          'zipcode address address'
          'complement complement complement'
          'country province city';
        display: grid;
        grid-gap: 2rem;

        .block__content--zipcode {
          grid-area: zipcode;
        }

        .block__content--address {
          grid-area: address;
        }

        .block__content--complement {
          grid-area: complement;
        }

        .block__content--country {
          grid-area: country;
        }

        .block__content--province {
          grid-area: province;
        }

        .block__content--city {
          grid-area: city;
        }
      }
    }
  }
`;

export default styles;
export { styles };
