import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { EditProfile } from '@templates';
import { Head } from '@containers';
import { Text } from '@components';

// Themes
import { main as theme } from '@themes';

// Styles
import { styles } from './Attachment.styles';

const Wrapper = styled.div`
  ${styles}
`;

const Attachment = ({ refreshToken }) => {
  return (
    <>
      <Head title="Anexos — meuSucesso.com" />
      <EditProfile>
        <Wrapper>
          <Text
            dsize="2.2rem"
            size="1.8rem"
            weight={theme.fonts.fontWeight.bold}
            color={theme.colorPallete.base.default}
          >
            Anexo não encontrado
          </Text>
        </Wrapper>
      </EditProfile>
    </>
  );
};

Attachment.propTypes = {
  refreshToken: PropTypes.string,
};

Attachment.defaultProps = {
  refreshToken: '',
};

export default Attachment;
export { Attachment };
