import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 6rem;
  background-color: ${theme.colorPallete.white};
  position: relative;

  .page__header {
    width: 100%;
    height: 20rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5rem;
    background-color: ${theme.colorPallete.base[700]};
    background-image: url('/assets/images/cancel-subscription-bg.png');
    background-position: center;
    background-size: cover;
  }

  .page__body {
    width: 100%;
    margin: auto;
    padding: 6rem 2rem;
    background-color: ${theme.colorPallete.white};

    @media all and (min-width: ${theme.breakpoints.big}) {
      padding-left: 14rem;
    }

    .page__body__title {
      display: block;
      margin-bottom: 4.8rem;
    }

    .page__body__container {
      width: 100%;
      max-width: 76.8rem;

      @media all and (min-width: ${theme.breakpoints.big}) {
        max-width: 90rem;
      }

      .page__body__container__title {
        padding-bottom: 2rem;
        max-width: 80rem;

        @media all and (min-width: ${theme.breakpoints.big}) {
          padding-bottom: 6rem;
        }
      }

      .page__body__container__content {
        width: 100%;
        padding-top: 1rem;
        margin-bottom: 3.8rem;

        .page__body__container__content__info {
          width: 100%;
          margin-bottom: 3.6rem;

          .info__line {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .info__line__text {
              padding-left: 1rem;

              h2 {
                padding-bottom: 2rem;
              }
            }
          }
        }

        .page__body__container__content__thumbs {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;

            @media all and (min-width: ${theme.breakpoints.smaller}) {
              width: auto;
            }
          }
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          display: flex;
          align-items: center;
          margin-bottom: 7.3rem;

          .page__body__container__content__info {
            width: 38rem;
            min-width: 38rem;
            box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
            padding: 3.7rem 2.2rem;
            margin-right: 9rem;
            margin-bottom: 0;
          }
        }
      }

      .page__body__container__content__button {
        width: 100%;

        @media all and (min-width: ${theme.breakpoints.big}) {
          max-width: 36rem;
        }
      }
    }
  }

  .page__footer {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${theme.colorPallete.base[700]};
    position: absolute;
    bottom: 0;

    @media all and (min-width: ${theme.breakpoints.big}) {
      padding-left: 14rem;
    }
  }
`;

export default styles;
export { styles };
