import { ConclusionExerciseDto, ModulesDto } from '@libs/ms-api';
import { ModuleSlider } from '../../ModuleSlider';
import { ConclusionExercise } from '../../../ConclusionExercise';
import { IssueCertificate } from '../../../IssueCertificate';
import * as S from './TrailCourse.style';

interface TrailCourseProps {
  modules: ModulesDto[];
  type: 'week' | 'sponsored';
  trailConclusionExercise?: ConclusionExerciseDto;
  studentIssuedCertificate?: boolean;
  handleIssueCertificate?: () => Promise<void>;
}

export const TrailCourse = ({
  type,
  modules,
  trailConclusionExercise,
  studentIssuedCertificate,
  handleIssueCertificate,
}: TrailCourseProps) => {
  if (modules.length < 1) return null;

  return (
    <S.Wrapper className={type}>
      <S.Content>
        <S.Introduction>
          <h1>
            {type === 'week'
              ? 'Trilha do Programa de Estudo'
              : 'Conteúdo extra'}
          </h1>
          {type === 'week' && (
            <p>
              Para concluir o programa de estudo, você precisa assistir todos os
              vídeos e completar todos os exercícios.
            </p>
          )}
        </S.Introduction>
        {modules?.map((module, index) => (
          <ModuleSlider
            moduleNumber={index + 1}
            module={module}
            isLastItem={
              !trailConclusionExercise && index + 1 === modules.length
            }
            index={index}
            modules={modules}
          />
        ))}
        {trailConclusionExercise && (
          <ConclusionExercise
            content={trailConclusionExercise}
            moduleNumber={modules?.length + 1}
            shouldShowLine={studentIssuedCertificate}
            completed={studentIssuedCertificate}
          />
        )}
        {studentIssuedCertificate && (
          <IssueCertificate
            completed={studentIssuedCertificate}
            handleIssueCertificate={handleIssueCertificate}
          />
        )}
      </S.Content>
    </S.Wrapper>
  );
};
