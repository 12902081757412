import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 92px;
  }
`;

export const LessonComments = styled.div`
  .handler {
    cursor: pointer;
    background: ${(props) => props.theme.colorSystem.white};
    color: ${(props) => props.theme.colorPallete.base[700]};
    text-align: center;
    width: 100%;
    height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    span {
      margin-right: 1.5rem;
    }

    .active {
      display: flex;
      background-color: ${(props) => props.theme.colorPallete.base[500]};
    }
  }
`;

export const TopNavigation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  padding-top: 2%;

  padding-inline: 20px;
  @media (min-width: 1025px) {
    padding-left: 10vw;
  }
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    background-color: transparent;
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    font-weight: ${theme.fonts.fontWeight.bold};
    margin-right: 32px;
    cursor: pointer;
  `}
`;

export const Breadcrumb = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.md};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.light};
    cursor: default;

    span {
      color: ${theme.colorSystem.primaryColor};
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1320px;

    padding-inline: 20px;

    @media (min-width: 1025px) {
      padding-left: 10vw;
    }

    margin-top: 32px;
    margin-bottom: 20px;
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.gg};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-inline: 20px;
  padding-bottom: 46px;

  @media (min-width: 1025px) {
    padding-inline: 10vw;
  }

  h2 {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2e2e2e;
  }
`;

export const CommentsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: max-content;
    background-color: #0d0c0c;
    margin-bottom: -46px;
    padding-block: 46px;
  `}
`;
