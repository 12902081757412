import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Player from '@vimeo/player';
import { useRouter } from 'next/router';
import { actions as vvsActions } from 'store/ducks/vvs';
import { actions as progressActions } from 'store/ducks/progress';
import { VideoUnavailable } from './containers';
import { IconArrow } from 'components';
import { Video } from '@libs/ms-api';
import * as S from './VideoPlayer.styles';

interface VideoPlayerProps {
  handleNextVideo: () => void;
  returnNextContentButtonMessage: () => string;
  showNextButton: boolean;
  setShowNextButton: (value: React.SetStateAction<boolean>) => void;
  checkIfTheNextContentIsLastOfModule: () => void;
  hasNextLesson: boolean;
  video: Video;
  lessonSlug: string;
  lessonType: string;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowModal: boolean;
  isLastContent: boolean;
}

const VideoPlayer = ({
  handleNextVideo,
  returnNextContentButtonMessage,
  showNextButton,
  setShowNextButton,
  checkIfTheNextContentIsLastOfModule,
  hasNextLesson,
  video,
  lessonSlug,
  lessonType,
  setIsModalVisible,
  shouldShowModal,
  isLastContent,
}: VideoPlayerProps) => {
  const dispatch = useDispatch();
  const {
    vvsReducer: { homeProgress, courseProgress },
    progressReducer: { newProgress },
  }: any = useSelector((state) => state);

  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [currentTimeVideoPlayer, setCurrentTimeVideoPlayer] = useState(0);
  const [durationVideoPlayer, setDurationVideoPlayer] = useState(0);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  const player = useRef(null);
  const iframe = useRef(null);
  const divRef = useRef(null);
  const prevValueSeconds = useRef(0);
  const router = useRouter();

  const addChildIFrame = () => {
    const iframeChild = document.createElement('iframe');
    iframeChild.id = 'lesson-vid';
    iframeChild.src = `https://player.vimeo.com/video/${video?.id}?autoplay=1`;
    iframeChild.allowFullscreen = true;
    iframeChild.allow = 'autoplay';
    iframeChild.title = 'video';
    divRef.current.appendChild(iframeChild);
    iframe.current = iframeChild;

    return true;
  };

  const handleOnError = (error) => {
    if (error?.name !== 'RangeError' && error?.name !== 'TypeError') {
      setIsModalErrorOpen(true);
    }
  };

  const handleShowAndNextVideo = () => {
    setShowNextButton(false);
    handleNextVideo();
  };

  const handleOnEnded = () => {
    setShowNextButton(false);

    setDurationVideoPlayer(0);
    setCurrentTimeVideoPlayer(0);

    handleShowAndNextVideo();
  };

  const sendCurrentProgress = (
    progressCurrent = currentTimeVideoPlayer,
    durationCurrent = durationVideoPlayer,
  ) => {
    if (progressCurrent > 0) {
      dispatch(
        progressActions.sendProgressRequestV2({
          payload: {
            content: {
              slug: lessonSlug,
            },
            data: {
              end_at: progressCurrent,
              duration: durationCurrent,
            },
          },
        }),
      );
      if (!homeProgress) {
        // ? Exec with the user watch a video, need to get the new progress:
        dispatch(vvsActions.getHomeNewProgress(true));
      }
      if (!courseProgress) {
        // ? Exec with the user watch a video, need to get the new progress:
        dispatch(vvsActions.getCourseNewProgress(true));
      }
    }
  };

  const setInitialSecondsVideo = () => {
    const defaultProgress = {
      slug_lesson: lessonSlug,
      start_at: video.resumeAt,
    };

    const finded = newProgress.find(
      (progress) => progress.slug_lesson === lessonSlug,
    );

    if (finded) {
      return finded;
    }

    return defaultProgress;
  };

  const callPlayer = () => {
    const videoIsReady = addChildIFrame();

    if (videoIsReady) {
      const playerPrincipal = new Player(iframe.current.id);
      player.current = playerPrincipal;

      const progressCurrent = setInitialSecondsVideo();

      if (progressCurrent?.start_at)
        playerPrincipal?.setCurrentTime(progressCurrent?.start_at);

      playerPrincipal
        .getDuration()
        .then((durationCurrent) => {
          setDurationVideoPlayer(durationCurrent);
        })
        .catch((error) => {
          console.log('error:', error);
        });

      playerPrincipal.on('timeupdate', (e) => {
        playerPrincipal.getCurrentTime().then((seconds) => {
          if (
            Number.isInteger(Math.trunc(seconds) / 10) &&
            // @ts-ignore
            prevValueSeconds !== Math.trunc(seconds)
          ) {
            const durationPlayed = Math.floor(e.duration);
            const percentPlayed = e.percent;
            const secondsPlayed = Math.trunc(seconds);

            prevValueSeconds.current = secondsPlayed;

            if (percentPlayed < 0.95) {
              setCurrentTimeVideoPlayer(secondsPlayed);
              setShowNextButton(false);
            } else if (percentPlayed >= 0.95) {
              setCurrentTimeVideoPlayer(secondsPlayed);

              playerPrincipal.exitFullscreen();
              // checkStatusOfCurrentLessonOrQuiz(currentVideo.id);
              if (!isLastContent) {
                setShowNextButton(true);
              }
              checkIfTheNextContentIsLastOfModule();

              setHasReachedEnd(true);
            }
            if (percentPlayed >= 0.99) {
              setCurrentTimeVideoPlayer(durationPlayed);
            }
          }
        });
      });

      playerPrincipal.on('error', (e) => {
        handleOnError(e);
      });

      playerPrincipal.on('ended', () => {
        handleOnEnded();
      });
    }
  };

  useEffect(() => {
    if (iframe?.current?.id && player.current) {
      player.current.pause();
      player.current.unload();
      player.current.destroy();
    }
    callPlayer();
  }, [router.asPath]);

  useEffect(() => {
    sendCurrentProgress();
  }, [currentTimeVideoPlayer]);

  useEffect(() => {
    if (hasReachedEnd && shouldShowModal) setIsModalVisible(true);
  }, [hasReachedEnd, shouldShowModal]);

  useEffect(() => {
    setHasReachedEnd(false);
  }, [lessonSlug]);

  return (
    <S.Wrapper>
      {isModalErrorOpen && (
        <VideoUnavailable setIsModalErrorOpen={setIsModalErrorOpen} />
      )}
      <S.IFrameContainer>
        <div ref={divRef} />
        {hasNextLesson && showNextButton && lessonType !== 'extra' && (
          <S.NextButton
            onClick={() => handleShowAndNextVideo()}
            className="btn-next-video"
            type="button">
            {returnNextContentButtonMessage()}
            <IconArrow width="15" height="15" />
          </S.NextButton>
        )}
      </S.IFrameContainer>
    </S.Wrapper>
  );
};

export default VideoPlayer;
export { VideoPlayer };
