import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Button, CardFlag, Paper, Text } from '@components';

// Themes
import { main as theme } from '@themes';

// Actions
import { actions as userActions } from '@store/ducks/user';
import { styles } from './UserFinancialData.styles';

// Containers
import { EditUserFinancialData } from '../EditUserFinancialData';

const Wrapper = styled.div`
  ${styles}
`;

const UserFinancialData = ({ refreshToken }) => {
  const dispatch = useDispatch();

  const { profileData } = useSelector((state) => state.userReducer);

  const setShowModal = (value) => {
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));
  };

  return (
    <Wrapper className="financial-data">
      <Paper className="paper">
        <div className="block__title">
          <Text
            as="h3"
            size="1.8rem"
            dsize="1.8rem"
            weight="bold"
            color={theme.colorPallete.primary.default}
          >
            Dados financeiros
          </Text>
        </div>

        <div className="block__content">
          {profileData &&
            profileData?.payment_method?.last_card_numbers && (
              <>
                <div className="block__content__flag">
                  <Text
                    as="h4"
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[600]}
                    weight="bold"
                  >
                    Bandeira
                  </Text>
                  <CardFlag name={profileData?.payment_method?.brand || ''} />

                  {/* <IconVisa /> */}
                </div>

                <div className="block__content__cardnumber">
                  <Text
                    as="h4"
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[600]}
                    weight="bold"
                  >
                    Nº Cartão
                  </Text>

                  <Text
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[400]}
                    weight="bold"
                  >
                    {(profileData.payment_method &&
                      `****.****.****.${profileData.payment_method.last_card_numbers}`) ||
                      ''}
                  </Text>
                </div>

                <div className="block__content__expiration">
                  <Text
                    as="h4"
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[600]}
                    weight="bold"
                  >
                    Validade
                  </Text>

                  <Text
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[400]}
                    weight="bold"
                  >
                    {profileData?.payment_method?.valid_until || ''}
                  </Text>
                </div>

                <div className="block__content__cardholder">
                  <Text
                    as="h4"
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[600]}
                    weight="bold"
                  >
                    Nome Completo
                  </Text>

                  <Text
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[400]}
                    weight="bold"
                  >
                    {profileData?.payment_method?.holder_name || ''}
                  </Text>
                </div>

                <div className="block__content__security">
                  <Text
                    as="h4"
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[600]}
                    weight="bold"
                  >
                    Código de Segurança
                  </Text>

                  <Text
                    size="1.6rem"
                    dsize="1.6rem"
                    color={theme.colorPallete.base[400]}
                    weight="bold"
                  >
                    ***
                  </Text>
                </div>
              </>
            )}
          <div className="block__content__button">
            <Button
              variant="hollow"
              size="medium"
              onClick={
                () =>
                  setShowModal(
                    <EditUserFinancialData refreshToken={refreshToken} />
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
              id="profile__button__add-card"
            >
              Cadastrar Novo Cartão
            </Button>
          </div>
        </div>
      </Paper>
    </Wrapper>
  );
};

UserFinancialData.propTypes = {
  refreshToken: PropTypes.string,
};

UserFinancialData.defaultProps = {
  refreshToken: '',
};

export default UserFinancialData;
export { UserFinancialData };
