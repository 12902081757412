/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, createRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import valid from 'card-validator';
import slugify from 'slugify';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';

// Themes
import { main as theme } from '@themes';

// Components
import {
  Button,
  IconRightArrow,
  IconWarning,
  IconHelp,
  Input,
  Text,
  Notification,
} from '@components';

import { EditProfile } from '@templates';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Styles
import { styles } from './EditUserFinancialData.styles';

const Wrapper = styled.div`
  ${styles}
`;

const EditUserFinancialData = ({ refreshToken }) => {
  const dispatch = useDispatch();

  const { error, profileData } = useSelector((state) => state.userReducer);

  const setShowModal = (value) =>
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));

  const recaptchaRef = createRef();

  const brandOptions = [
    { value: 'american_express', label: 'American Express' },
    { value: 'diners_club', label: 'Diners Club' },
    { value: 'elo', label: 'Elo' },
    { value: 'hipercard', label: 'Hipercard' },
    { value: 'mastercard', label: 'MasterCard' },
    { value: 'visa', label: 'Visa' },
  ];

  const [blockedButton, setBlockedButton] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    dirty: true,
    initialValues: {
      brand: '',
      holder_name: '',
      cardNumber: '',
      expiry: '',
      cvv: '',
    },
    validationSchema: Yup.object().shape({
      brand: Yup.string().test(
        'required',
        'Campo bandeira obrigatório',
        (brandVal) => brandVal && brandVal !== 'Selecionar'
      ),
      holder_name: Yup.string()
        .required('Campo nome obrigatório')
        .min(2, 'Mínimo de 2 caracteres')
        .matches(/^[a-zA-Z\s]*$/, 'Apenas letras são permitidas')
        .matches(/(\w.+\s).+/, 'Digite ao menos um nome e um sobrenome'),
      cardNumber: Yup.string()
        .test(
          'min',
          'Digite um cartão válido',
          (cardNumber) =>
            cardNumber &&
            valid.number(cardNumber).isPotentiallyValid &&
            valid.number(cardNumber).isValid
        )
        .test(
          'min',
          'O número do cartão não condiz com sua bandeira',
          (cardNumber) =>
            cardNumber &&
            valid.number(cardNumber).card &&
            valid.number(cardNumber).card.type ===
              slugify(formik.values.brand.replace('_', '-'))
        ),
      expiry: Yup.string()
        .required('Campo data de validade obrigatório')
        .test(
          'min',
          'Digite uma data válida',
          (dateVal) =>
            dateVal &&
            valid.expirationDate(dateVal).isPotentiallyValid &&
            valid.expirationDate(dateVal).isValid &&
            dateVal.substr(3, 2) <= moment().add(10, 'years').format('YY')
        ),
      cvv: Yup.string()
        .required('Campo cvv obrigatório')
        .test(
          'min',
          'Máximo de 3 dígitos',
          (cvvVal) =>
            formik.values.brand === 'american_express' ||
            (cvvVal && cvvVal.match(/\d+/g).join('').length === 3)
        )
        .test(
          'min',
          'Máximo de 4 dígitos',
          (cvvVal) =>
            formik.values.brand !== 'american_express' ||
            (cvvVal && cvvVal.match(/\d+/g).join('').length === 4)
        ),
    }),
    onSubmit: async (values) => {
      const { expiry, cardNumber, ...restValues } = values;

      const data = {
        ...restValues,
        expiration_month: expiry.substr(0, 2),
        expiration_year: expiry.substr(3, 4),
        card_number: cardNumber.match(/\d+/g).join(''),
      };

      setBlockedButton(true);

      dispatch(
        userActions.editFinancialDataRequest({
          payload: { data, auth: { refresh_token: refreshToken } },
        })
      );
    },
  });

  useEffect(() => {
    if (error) {
      setBlockedButton(false);
    }
  }, [error]);

  useEffect(() => {
    if (recaptchaValue && recaptchaValue.length > 10) {
      sendForm();
    }
  }, [recaptchaValue]);

  const { setFieldValue, setFieldTouched, validateForm } = formik;

  const handleOnChange = (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  const handleRecaptchaChange = () =>
    setRecaptchaValue(recaptchaRef.current.getValue());

  const sendForm = () => {
    if (recaptchaValue && recaptchaValue.length > 10) {
      formik.handleSubmit();
    } else {
      recaptchaRef.current.execute();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    sendForm();
  };

  return (
    <EditProfile>
      <Wrapper>
        <div className="page__container">
          {!profileData?.profile?.restriction &&
            profileData?.profile?.complient === 'active_defaulting' && (
              <div className="page__container__notification">
                <Notification
                  colorStrip="#EE9191"
                  colorBg={theme.colorSystem.danger}
                  title={
                    <>
                      <Text
                        as="p"
                        size="1.6rem"
                        dsize="1.6rem"
                        family={theme.fonts.fontFamily.sans}
                        weight={theme.fonts.fontWeight.bold}
                        color={theme.colorPallete.white}
                      >
                        Ao incluir um novo cartão, as parcelas em aberto serão
                        debitadas automaticamente.
                      </Text>
                      <br />
                      <Text
                        as="p"
                        size="1.6rem"
                        dsize="1.6rem"
                        family={theme.fonts.fontFamily.sans}
                        weight={theme.fonts.fontWeight.bold}
                        color={theme.colorPallete.white}
                      >
                        Está precisando de ajuda?
                      </Text>
                      <Link href="/historico-financeiro" target="_blank">

                        <Text
                          as="p"
                          size="1.6rem"
                          dsize="1.6rem"
                          family={theme.fonts.fontFamily.sans}
                          weight={theme.fonts.fontWeight.bold}
                          color={theme.colorPallete.white}
                          decoration="underline"
                        >
                          Clique aqui.
                        </Text>

                      </Link>
                    </>
                  }
                  noclose
                  icon={<IconWarning size="30px" />}
                />
              </div>
            )}

          <form
            className="page__container__form"
            onSubmit={(e) => handleOnSubmit(e)}
            method="post"
          >
            <div className="page__header">
              <button
                className="page__header__goback"
                onClick={() => setShowModal(false)}
                id="add-card__button__close"
                type="button"
              >
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base.default}
                  weight={theme.fonts.fontWeight.bold}
                  cursor="pointer"
                >
                  <span className="arrow-left">
                    <IconRightArrow color={theme.colorSystem.black} />
                  </span>
                  <span>Voltar</span>
                </Text>
              </button>

              <div className="page__header__title">
                <Text
                  size="2rem"
                  dsize="2rem"
                  color={theme.colorPallete.primary.default}
                  weight={theme.fonts.fontWeight.bold}
                >
                  Editar dados financeiros
                </Text>
                {/* { !profileData?.profile?.restriction &&
                      profileData?.profile?.complient === 'active_defaulting' && (
                  <div className="page__header__help-link">
                    <a href="/historico-financeiro" target="_blank">
                      <Text
                        size="1.4rem"
                        dsize="1.4rem"
                        color={theme.colorPallete.base[400]}
                      >
                        Ajuda
                      </Text>
                      <IconHelp
                        size="18px"
                        color={theme.colorPallete.base[400]}
                      />
                    </a>
                  </div>
                )} */}
              </div>
            </div>
            <div className="page__content">
              <div className="page__content__form-input">
                <Input
                  label="Selecione a sua bandeira"
                  name="brand"
                  type="select"
                  placeholder="Selecionar"
                  size="medium"
                  id="brand"
                  value={formik.values.brand}
                  onChange={(e) => handleOnChange('brand', e.target.value)}
                  validation={
                    formik.errors.brand && formik.touched.brand
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.brand && formik.touched.brand
                      ? formik.errors.brand
                      : ''
                  }
                  selectOptions={brandOptions.map((brand) => ({
                    value: brand.value,
                    text: brand.label,
                  }))}
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label="Nome no cartão"
                  name="holder_name"
                  type="text"
                  placeholder=""
                  size="medium"
                  id="holder_name"
                  value={formik.values.holder_name}
                  onChange={(e) =>
                    handleOnChange('holder_name', e.target.value)
                  }
                  validation={
                    formik.errors.holder_name && formik.touched.holder_name
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.holder_name && formik.touched.holder_name
                      ? formik.errors.holder_name
                      : ''
                  }
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label="Número do cartão"
                  name="cardNumber"
                  type="text"
                  placeholder="**** **** **** ****"
                  maskType={
                    formik.values.brand === 'american_express'
                      ? 'cardNumberAmex'
                      : formik.values.brand === 'diners_club'
                      ? 'cardNumberDC'
                      : 'cardNumber'
                  }
                  size="medium"
                  id="cardNumber"
                  value={formik.values.cardNumber}
                  onChange={(e) => handleOnChange('cardNumber', e.target.value)}
                  validation={
                    formik.errors.cardNumber && formik.touched.cardNumber
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.cardNumber && formik.touched.cardNumber
                      ? formik.errors.cardNumber
                      : ''
                  }
                />
              </div>
              <div className="page__content__horizontal__form">
                <div className="horizontal-block">
                  <Input
                    label="Data de validade"
                    name="expiry"
                    type="text"
                    placeholder="MM/AA"
                    maskType="MM/AA"
                    size="medium"
                    id="expiry"
                    value={formik.values.expiry}
                    onChange={(e) => handleOnChange('expiry', e.target.value)}
                    validation={
                      formik.errors.expiry && formik.touched.expiry
                        ? 'invalid'
                        : null
                    }
                    validationMsg={
                      formik.errors.expiry && formik.touched.expiry
                        ? formik.errors.expiry
                        : ''
                    }
                  />
                </div>

                <div className="horizontal-block">
                  <Input
                    label="CVV"
                    name="cvv"
                    type="text"
                    placeholder="***"
                    size="medium"
                    id="cvv"
                    maskType={
                      formik.values.brand === 'american_express'
                        ? 'CVV4'
                        : 'CVV'
                    }
                    value={formik.values.cvv}
                    onChange={(e) => handleOnChange('cvv', e.target.value)}
                    validation={
                      formik.errors.cvv && formik.touched.cvv ? 'invalid' : null
                    }
                    validationMsg={
                      formik.errors.cvv && formik.touched.cvv
                        ? formik.errors.cvv
                        : ''
                    }
                  />
                </div>
              </div>
              <div className="page__content__form-input">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.RECAPTCHA_INV_KEY}
                  size="invisible"
                  onChange={handleRecaptchaChange}
                />
              </div>
            </div>

            <div className="page__submit">
              <Button
                variant="primary"
                size="medium"
                type="submit"
                disabled={blockedButton}
                id="add-card__button__save"
              >
                Salvar
              </Button>
            </div>
          </form>
        </div>
      </Wrapper>
    </EditProfile>
  );
};

EditUserFinancialData.propTypes = {
  refreshToken: PropTypes.string,
};

EditUserFinancialData.defaultProps = {
  refreshToken: '',
};

export default EditUserFinancialData;
export { EditUserFinancialData };
