/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { parsePhoneNumber } from 'react-phone-number-input';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { Input, Button } from '@components';

// Helpers
import {
  CountriesArray,
  validateCpf,
  polishingPhone,
  validatePhone,
  polishingString,
} from '@helpers';

// Styles
import { Wrapper } from './form.styles';

const Form1 = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { query } = router;

  const firstAccessData = useSelector(
    (state) => state.userReducer.firstAccessData,
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      gender: (firstAccessData && firstAccessData.gender) || '',
      phone:
        (firstAccessData && polishingPhone(firstAccessData.phone)) || '+55',
      nationality: (firstAccessData && firstAccessData.nationality) || 'BR',
      document:
        (firstAccessData &&
          firstAccessData.document &&
          firstAccessData.document.replace(
            /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
            '$1.$2.$3-$4',
          )) ||
        '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required('O campo senha é obrigatório')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*_#?&]{8,}$/,
          'Sua senha deverá ter no mínimo 8 dígitos, formada por letras e números',
        ),
      passwordConfirmation: Yup.string()
        .required('O campo confirmar senha é obrigatório')
        .oneOf(
          [Yup.ref('password'), null],
          'As senhas digitadas não estão iguais',
        ),
      gender: Yup.string().required('Campo gênero obrigatório'),
      phone: Yup.string()
        .required(
          'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, adicione o símbolo +, o DDI e o número.',
        )
        .test(
          'is-possible',
          'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, adicione o símbolo +, o DDI e o número.',
          (phone) =>
            phone &&
            phone.length > 3 &&
            parsePhoneNumber(phone) &&
            validatePhone(phone, parsePhoneNumber(phone).country || 'US'),
        ),
      nationality: Yup.string().required('Campo nacionalidade obrigatório'),
      document: Yup.string()
        .test(
          'required',
          'O campo CPF é obrigatório',
          (cpf) =>
            formik.values.nationality !== 'BR' || (cpf && cpf.length > 3),
        )
        .test(
          'required',
          'O campo documento é obrigatório',
          (document) =>
            formik.values.nationality === 'BR' ||
            (document && document.length > 3),
        )
        .test(
          'match',
          'O CPF está inválido, por favor verifique os números digitados',
          (cpf) =>
            formik.values.nationality !== 'BR' ||
            (cpf &&
              // eslint-disable-next-line no-useless-escape
              cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/)),
        )
        .test(
          'valida-cpf',
          'O cpf não é válido',
          (cpfNumber) =>
            formik.values.nationality !== 'BR' ||
            (cpfNumber && validateCpf(cpfNumber.replace(/\D/g, ''))),
        ),
    }),
    onSubmit: async (values) => {
      const { document, phone, passwordConfirmation, ...restValues } = values;

      let data = {
        ...restValues,
        password_confirmation: passwordConfirmation,
        name: firstAccessData?.name || '',
        email: firstAccessData?.email || '',
        email_confirmation: firstAccessData?.email || '',
        cctld: parsePhoneNumber(phone).country || 'US',
        phone: phone.toString().split(' ').join(''),
        fa_source_initial: query.source,
        fa_token: query.token,
      };

      if (data.nationality === 'BR' || data.nationality === 'Brasil') {
        data = {
          ...data,
          cpf: document.replace(/[^a-zA-Z0-9]/g, ''),
        };
      } else {
        data = {
          ...data,
          document: document.replace(/[^a-zA-Z0-9]/g, ''),
        };
      }

      dispatch(userActions.sendFirstAccessRequest({ data }));
      setCurrentStep('STEP_2');
    },
  });

  const { setFieldValue, handleSubmit, setFieldTouched, validateForm } = formik;

  const handleOnChange = (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  return (
    <Wrapper onSubmit={handleSubmit} method="post">
      <div className="form__input">
        <Input
          label="Nome Completo"
          type="text"
          name="name"
          id="name"
          placeholder=""
          size="medium"
          readonly
          value={firstAccessData?.name || ''}
        />
      </div>
      <div className="form__input">
        <Input
          label="E-mail"
          type="text"
          size="medium"
          placeholder=""
          name="email"
          id="email"
          readonly
          value={firstAccessData?.email || ''}
        />
      </div>
      <div className="form__input">
        <Input
          label="Senha"
          name="password"
          type="password"
          placeholder=""
          size="medium"
          id="password"
          value={formik.values.password}
          onChange={(e) =>
            handleOnChange('password', polishingString(e.target.value))
          }
          onBlur={(e) =>
            formik.setFieldValue('password', polishingString(e.target.value))
          }
          validation={
            formik.errors.password && formik.touched.password ? 'invalid' : null
          }
          validationMsg={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : ''
          }
        />
      </div>
      <div className="form__input">
        <Input
          label="Confirmar senha"
          name="passwordConfirmation"
          type="password"
          placeholder=""
          size="medium"
          id="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={(e) =>
            handleOnChange(
              'passwordConfirmation',
              polishingString(e.target.value),
            )
          }
          validation={
            formik.errors.passwordConfirmation &&
            formik.touched.passwordConfirmation
              ? 'invalid'
              : null
          }
          validationMsg={
            formik.errors.passwordConfirmation &&
            formik.touched.passwordConfirmation
              ? formik.errors.passwordConfirmation
              : ''
          }
        />
      </div>
      <div className="form__input">
        <Input
          label="Gênero"
          name="gender"
          type="select"
          placeholder="Selecionar"
          size="medium"
          id="gender"
          value={formik.values.gender}
          onChange={(e) => handleOnChange('gender', e.target.value)}
          selectOptions={[
            {
              id: 0,
              value: 'Masculino',
              text: 'Masculino',
            },
            {
              id: 0,
              value: 'Feminino',
              text: 'Feminino',
            },
            {
              id: 0,
              value: 'Não binário',
              text: 'Não binário',
            },
          ]}
          validation={
            formik.errors.gender && formik.touched.gender ? 'invalid' : null
          }
          validationMsg={
            formik.errors.gender && formik.touched.gender
              ? formik.errors.gender
              : ''
          }
        />
      </div>
      <div className="form__input">
        <Input
          label="Celular"
          id="phone"
          name="phone"
          type="phone"
          placeholder="Insira o seu número"
          value={formik.values.phone}
          onChange={(value) => handleOnChange('phone', value)}
          international
          validation={
            formik.errors.phone && formik.touched.phone ? 'invalid' : null
          }
          validationMsg={
            formik.errors.phone && formik.touched.phone
              ? formik.errors.phone
              : ''
          }
        />
      </div>
      <div className="form__input">
        <Input
          label="Nacionalidade"
          name="nationality"
          type="select"
          placeholder="Selecionar"
          size="medium"
          id="nationality"
          value={formik.values.nationality}
          onChange={(e) => handleOnChange('nationality', e.target.value)}
          validation={
            formik.errors.nationality && formik.touched.nationality
              ? 'invalid'
              : null
          }
          validationMsg={
            formik.errors.nationality && formik.touched.nationality
              ? formik.errors.nationality
              : ''
          }
          selectOptions={CountriesArray.map((country) => ({
            value: country.sigla,
            text: country.nome_pais,
          }))}
        />
      </div>
      <div className="form__input">
        <Input
          label={formik.values.nationality === 'BR' ? 'CPF' : 'Documento'}
          name="document"
          type="text"
          maskType={formik.values.nationality === 'BR' ? 'cpf' : 'false'}
          placeholder={
            formik.values.nationality === 'BR'
              ? 'Insira o seu CPF'
              : 'Insira o seu documento'
          }
          size="medium"
          id="document"
          value={formik.values.document}
          onChange={(e) => handleOnChange('document', e.target.value)}
          validation={
            formik.errors.document && formik.touched.document ? 'invalid' : null
          }
          validationMsg={
            formik.errors.document && formik.touched.document
              ? formik.errors.document
              : ''
          }
        />
      </div>
      <div className="form__button">
        <Button
          id="first-access__button__continue"
          variant="primary"
          size="medium"
          type="submit">
          Continuar
        </Button>
      </div>
    </Wrapper>
  );
};

export { Form1 };
export default Form1;
