import React from 'react';
import styled from 'styled-components';

// Theme
import { main as theme } from '@themes';

// Components
import { IconPlay, Text } from '@components';

// Styles
import { styles } from './WatchLessonZero.styles';

// ------------------------------------------------------------------------- //

const Wrapper = styled.main`
  ${styles}
`;

const WatchLessonZero = React.memo(() => {
  return (
    <Wrapper>
      <Text
        className="button-title"
        dsize="3.4rem"
        size="1.8rem"
        weight={theme.fonts.fontWeight.bold}
        color={theme.colorPallete.white}
      >
        Assistir à Aula Zero
      </Text>
      <span className="play-icon">
        <IconPlay size="33px" />
      </span>
    </Wrapper>
  );
});

export default WatchLessonZero;
export { WatchLessonZero };
