import styled from 'styled-components';

const Wrapper = styled.form`
  .form {
    &__input {
      margin-top: 2.4rem;
    }

    &__button {
      width: 100%;
      max-width: 33.5rem;
      margin: 4rem 0 0 0;
    }
  }

  input {
    /* border: 1px solid #cecece; */
  }
`;

export { Wrapper };
export default Wrapper;
