import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Template Page
import { SignUp } from '@templates';

// Components
import { Logo, Text } from '@components';

// Themes
import { main } from '@themes';

import { Form1 } from './containers/Step1';
import { Form2 } from './containers/Step2';
import { Form3 } from './containers/Step3';

// Styles
import * as S from './firstAccess.styles';

const FirstAccess = () => {
  const firstAccessData = useSelector(
    (state) => state.userReducer.firstAccessData,
  );

  const [currentStep, setCurrentStep] = useState('');

  useEffect(() => {
    setCurrentStep(firstAccessData?.fa_step);
  }, [!!firstAccessData?.fa_step]);

  return (
    <SignUp titlePage="Primeiro Acesso">
      <S.Wrapper>
        <div className="mobile-only">
          <Logo size="150px" />
        </div>

        <S.TitleSection>
          <Text
            as="h1"
            size="1.6rem"
            dsize="1.6rem"
            color={main.colorPallete.base[400]}
            weight={600}
            height="20px"
            dheight="normal">
            Primeiro Acesso
          </Text>
          <div className="titlesection__step">
            <Text
              as="h4"
              size="2rem"
              dsize="2rem"
              color={main.colorSystem.baseColor700}
              weight="bold"
              height="25px"
              dheight="normal">
              {currentStep === 'STEP_3'
                ? 'Nome em certificado'
                : 'Informações Básicas'}
            </Text>
            <Text
              as="h4"
              size="1.4rem"
              dsize="1.4rem"
              color={main.colorSystem.baseColor700}
              weight="bold"
              height="18px"
              dheight="normal">
              {currentStep === 'STEP_1'
                ? '1/2'
                : currentStep === 'STEP_2'
                ? '2/2'
                : '3/3'}
            </Text>
          </div>
        </S.TitleSection>
        <S.FormContainer>
          {currentStep === 'STEP_3' ? (
            <Form3 setCurrentStep={setCurrentStep} />
          ) : currentStep === 'STEP_2' ? (
            <Form2 setCurrentStep={setCurrentStep} />
          ) : (
            <Form1 setCurrentStep={setCurrentStep} />
          )}
        </S.FormContainer>
      </S.Wrapper>
    </SignUp>
  );
};

export { FirstAccess };
export default FirstAccess;
