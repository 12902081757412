import React, { useRef, useState } from 'react';
import * as S from './Comment.styles';
import { IconCaretDown } from 'components';
import { CommentsData } from '../../types';
import { getFirstLetterOfName } from 'helpers';
import { AddComments } from '../AddComments';
import Image from 'next/image';
interface CommentsProps {
  comment: CommentsData;
  replyCallback: (value: string) => void;
}

const timeDifference = (initialDate: Date, finalDate: Date): string => {
  const initialDateFormatted = new Date(initialDate).setHours(
    initialDate.getHours() - 3,
  );
  const diffMilliseconds = Math.abs(finalDate.getTime() - initialDateFormatted);
  const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
  const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
  const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffYears > 0) {
    return `Publicado há ${diffYears} ${diffYears === 1 ? 'ano' : 'anos'}`;
  } else if (diffMonths > 0) {
    return `Publicado há ${diffMonths} ${diffMonths === 1 ? 'mês' : 'meses'}`;
  } else if (diffDays > 0) {
    return `Publicado há ${diffDays} ${diffDays === 1 ? 'dia' : 'dias'}`;
  } else if (diffHours > 0) {
    return `Publicado há ${diffHours} ${diffHours === 1 ? 'hora' : 'horas'}`;
  } else {
    return `Publicado há ${diffMinutes} ${
      diffMinutes === 1 ? 'minuto' : 'minutos'
    }`;
  }
};

const Comment = ({ comment, replyCallback }: CommentsProps) => {
  const [answersIsOpen, setAnswersIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const focusOnTextArea = () => {
    setTimeout(() => {
      if (inputRef.current) {
        const dsb = inputRef.current.querySelector('#add-comment-container');
        const textarea: HTMLElement | null | undefined =
          dsb?.querySelector('#input-add-comment');

        textarea?.focus();
      }
    }, 200);
  };

  return (
    <S.Wrapper ref={inputRef}>
      <S.Avatar>
        {comment?.user?.image?.url ? (
          <Image
            alt="avatar"
            className="avatar"
            src={comment?.user?.image?.url}
            width={42}
            height={42}
          />
        ) : (
          getFirstLetterOfName(comment?.user?.nome)
        )}
      </S.Avatar>
      <S.Contents>
        <S.HeaderContents>
          <S.UserName>{comment?.user?.nome}</S.UserName>
          <S.PublishedTime>
            <span>|</span> {timeDifference(new Date(comment?.date), new Date())}
          </S.PublishedTime>
        </S.HeaderContents>
        <S.CommentContent>{comment?.comment || ''}</S.CommentContent>
        <S.AnswersController>
          <S.ButtonTextWrapper
            answersIsOpen={answersIsOpen}
            onClick={() => {
              setAnswersIsOpen(true);
              focusOnTextArea();
            }}>
            <span>Responder</span>
          </S.ButtonTextWrapper>
          {comment?.answer.length > 0 && (
            <S.ButtonTextWrapper
              answersIsOpen={answersIsOpen}
              onClick={() => {
                setAnswersIsOpen((value) => !value);
              }}>
              {comment?.answer.length || 0} Respostas
              <IconCaretDown />
            </S.ButtonTextWrapper>
          )}
        </S.AnswersController>
        {answersIsOpen && (
          <>
            <S.AnswersWrapper>
              {comment?.answer.map((answer, index) => (
                <S.Wrapper key={index} className="wrapper-answer">
                  <S.Avatar className="answer">
                    {getFirstLetterOfName(answer?.user?.nome)}
                  </S.Avatar>
                  <S.Contents>
                    <S.HeaderContents>
                      <S.UserName>{answer?.user?.nome}</S.UserName>
                      <S.PublishedTime>
                        <span>|</span>{' '}
                        {timeDifference(new Date(answer?.date), new Date())}
                      </S.PublishedTime>
                    </S.HeaderContents>
                    <S.CommentContent>{answer?.comment || ''}</S.CommentContent>
                  </S.Contents>
                </S.Wrapper>
              ))}
            </S.AnswersWrapper>
            <AddComments onClick={replyCallback} />
          </>
        )}
      </S.Contents>
    </S.Wrapper>
  );
};

export default Comment;
export { Comment };
