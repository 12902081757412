import React from 'react';
import { Text, IconClose } from 'components';
import { main as theme } from 'themes';

import * as S from './VideoUnavailable.styles';

interface VideoUnavailableProps {
  setIsModalErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoUnavailable = React.memo(
  ({ setIsModalErrorOpen }: VideoUnavailableProps) => {
    const handleOnClick = () => {
      setIsModalErrorOpen(false);
    };

    return (
      <S.Wrapper className="visible">
        <div className="container">
          <div className="close-button">
            <button type="button" onClick={() => handleOnClick()}>
              <IconClose size="22px" />
            </button>
          </div>
          <div className="white-box">
            <div className="white-box__text">
              {/* @ts-ignore */}
              <Text
                as="h2"
                dsize="2.4rem"
                size="2.4rem"
                color={theme.colorPallete.primary.default}
                weight={theme.fonts.fontWeight.bold}>
                Infelizmente nosso fornecedor de player de vídeos, Vimeo, está
                sofrendo problemas técnicos e por enquanto encontra-se instável.
              </Text>

              {/* @ts-ignore */}
              <Text
                as="p"
                dsize="1.6rem"
                size="1.6rem"
                color={theme.colorPallete.base[400]}>
                Sentimos muito pelo impacto na sua experiência, em breve você
                poderá acessar novamente todo conteúdo do meuSucesso.
              </Text>
            </div>
          </div>
        </div>
      </S.Wrapper>
    );
  },
);

export default VideoUnavailable;
export { VideoUnavailable };
