import React from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Actions
import { actions as searchActions } from '@store/ducks/search';

// Components
import { IconLoading, Text } from '@components';

// Themes
import { main as theme } from '@themes';

// Styles
import { Wrapper } from './Button.styles';

const Button = ({ refreshToken }) => {
  const { loading, searchData, searchLoading } = useSelector(
    (state) => state.searchReducer
  );

  const dispatch = useDispatch();

  const router = useRouter();

  const handleOnCLick = async () => {
    dispatch(
      searchActions.sendSearchRequest({
        payload: {
          data: { term: router?.query?.term, page: searchData?.page + 1 },
          auth: {
            refresh_token: refreshToken,
          },
        },
      })
    );
  };

  return (
    <Wrapper>
      {loading && searchLoading ? (
        <IconLoading />
      ) : (
        <button
          type="button"
          onClick={handleOnCLick}
          disabled={searchLoading}
          className={classnames({ disabled: searchLoading })}
          id="search__button__load-more"
        >
          <Text
            as="h3"
            dsize="1.6rem"
            size="1.6rem"
            dheight="1.8rem"
            color={theme.colorSystem.white}
          >
            Carregar mais
          </Text>
        </button>
      )}
    </Wrapper>
  );
};

Button.propTypes = {
  refreshToken: PropTypes.string,
};

Button.defaultProps = {
  refreshToken: null,
};

export { Button };
export default Button;
