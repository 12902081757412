/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { main as theme } from '@themes';

import { useDispatch, useSelector } from 'react-redux';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cep from 'cep-promise';

// Actions
import { actions as placeActions } from '@store/ducks/place';
import { actions as userActions } from '@store/ducks/user';

// Helpers
import { CountriesArray, getPropertyOfArray } from '@helpers';

// Components
import { Button, IconRightArrow, Input, IconLoading, Text } from '@components';

import { EditProfile } from '@templates';

// Styles
import { styles } from './EditUserAddress.styles';

const Wrapper = styled.div`
  ${styles}
`;

const EditUserAddress = ({ refreshToken }) => {
  const dispatch = useDispatch();

  const setShowModal = (value) =>
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));

  const {
    userReducer: { profileData, error },
    placeReducer: { statesData },
  } = useSelector((state) => state);

  const [blockedButton, setBlockedButton] = useState(false);

  const [searchingZipCode, toggleSearchingZipCode] = useState(false);

  const [stateSelected, changeStateSelected] = useState(null);

  const [userCep, insertUserCep] = useState(
    profileData?.profile?.zip_code || false
  );

  const polishingCountry = () => {
    const userCountry = profileData?.profile?.country?.toLowerCase() || 'BR';

    if (
      userCountry === 'brasil' ||
      userCountry === 'brazil' ||
      userCountry.length > 3
    ) {
      return 'BR';
    }

    return profileData?.profile?.country;
  };

  const formik = useFormik({
    initialValues: {
      country: polishingCountry() || 'BR',
      zipCode: profileData?.profile?.zip_code || '',
      province: profileData?.profile?.province || '',
      city: profileData?.profile?.city || '',
      district: profileData?.profile?.district || '',
      street: profileData?.profile?.street || '',
      complement: profileData?.profile?.complement || '',
      number: profileData?.profile?.number || '',
    },
    validationSchema: Yup.object().shape({
      country: Yup.string().test(
        'required',
        'Campo país obrigatório',
        (countryVal) => countryVal && countryVal.length < 3
      ),
      zipCode: Yup.string()
        .test(
          'required',
          'Campo CEP obrigatório',
          (cepNumber) =>
            formik.values.country !== 'BR' ||
            (cepNumber &&
              cepNumber.match(/\d+/g).join(',').replace(',', '').length === 8)
        )
        .test(
          'required',
          'Campo Zip Code obrigatório',
          (zipCodeNumber) =>
            formik.values.country === 'BR' ||
            (zipCodeNumber && zipCodeNumber.length > 0)
        ),
      province: Yup.string().required('Campo estado obrigatório'),
      city: Yup.string().required('Campo cidade obrigatório'),
      district: Yup.string()
        .test(
          'required',
          'Campo bairro obrigatório',
          (district) =>
            formik.values.country !== 'BR' || (district && district.length > 0)
        )
        .test(
          'required',
          'Campo district obrigatório',
          (district) =>
            formik.values.country === 'BR' || (district && district.length > 0)
        ),
      street: Yup.string()
        .test(
          'required',
          'Campo endereço obrigatório',
          (adress) =>
            formik.values.country !== 'BR' || (adress && adress.length > 0)
        )
        .test(
          'required',
          'Campo linha 1 obrigatório',
          (adress) =>
            formik.values.country === 'BR' || (adress && adress.length > 0)
        ),
      number: Yup.string()
        .required('Campo número obrigatório')
        .test(
          'regex',
          'O campo não é válido',
          (number) => number && number.match(/^[A-Za-z0-9-+()/,]*$/)
        ),
    }),
    onSubmit: async (values) => {
      const { zipCode, province, ...restValues } = values;

      let uf = false;

      if (restValues.country === 'BR') {
        uf = statesData.filter(
          (state) => state.id.toString(10) === province && state.sigla
        )[0].sigla;
      }

      const data = {
        ...restValues,
        zip_code: zipCode.replace(/[^a-zA-Z0-9]/g, '').substr(0, 8),
        province: uf || province,
      };

      setBlockedButton(true);

      dispatch(
        userActions.editAddressDataRequest({
          payload: { data, auth: { refresh_token: refreshToken } },
        })
      );
    },
  });

  const { setFieldValue, handleSubmit, setFieldTouched, validateForm } = formik;

  const handleOnChange = (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  const fetchStates = useCallback(() => {
    return dispatch(placeActions.getBrStatesMock());
  }, [dispatch]);

  // ? Dispara esse evento quando o usuário modificar o estado:
  useEffect(() => {
    // Carregando os estados:
    if (statesData.length === 0) {
      fetchStates();
    }
  }, [statesData.length, formik.values.province]);

  // ? Dispara esse evento quando o usuário inserir um cep:
  useEffect(() => {
    if (
      formik.values.zipCode &&
      (formik.values.country === 'BR' || formik.values.country === 'Brasil') &&
      formik.values.zipCode.replace(/\D/g, '').length === 8
    ) {
      // ? Activate loading on fields
      toggleSearchingZipCode(true);
      cep(formik.values.zipCode.replace(/\D/g, ''))
        .then((response) => {
          const newState = getPropertyOfArray(
            response.state,
            'sigla',
            'id',
            statesData
          );
          if (newState.length > 0) {
            // ? Save user valid CEP:
            insertUserCep(formik.values.zipCode.replace(/\D/g, ''));
            // ? Save user`s state:
            changeStateSelected(newState[0].id.toString(10));
            setFieldValue('province', newState[0].id.toString(10));
            setFieldValue('street', response.street);
            setFieldValue('district', response.neighborhood);
            setFieldValue('city', response.city);
          }
        })
        .catch(() => {
          // ? Save user invalid CEP:
          insertUserCep(false);
          return false;
        });
      // ? Cancel loading on fields
      toggleSearchingZipCode(false);
    }
  }, [formik.values.zipCode]);

  // ? Dispara caso o input de estado não esteja selecionado:
  useEffect(() => {
    if (statesData && document.getElementById('province').value === '') {
      setFieldValue('province', '');
    }
  }, []);

  // ? Fires when listening a form error
  useEffect(() => {
    if (error) {
      setBlockedButton(false);
    }
  }, [error]);

  /**
   * * Save state in a var, in case the user changes the country
   * @param {sring} value
   */
  const handleChangeState = (value) => {
    handleOnChange('province', value);
    return changeStateSelected(value);
  };

  /**
   * * When user changes the country, the brazilian state doesnt appear if its a foreign country.
   * @param {string} value
   */
  const handleChangeCountry = (value) => {
    if (value === 'BR') {
      handleOnChange('province', stateSelected);
    } else {
      handleOnChange('province', '');
    }
    handleOnChange('country', value);
  };

  return (
    <EditProfile>
      <Wrapper>
        <form className="page__container" onSubmit={handleSubmit} method="post">
          <div className="page__header">
            <button
              className="page__header__goback"
              onClick={() => setShowModal(false)}
              id="profile__button__address-close"
              type="button"
            >
              <Text
                size="1.6rem"
                dsize="1.6rem"
                color={theme.colorPallete.base.default}
                weight={theme.fonts.fontWeight.bold}
                cursor="pointer"
              >
                <span className="arrow-left">
                  <IconRightArrow color={theme.colorSystem.black} />
                </span>
                <span>Voltar</span>
              </Text>
            </button>

            <div className="page__header__title">
              <Text
                size="2rem"
                dsize="2rem"
                color={theme.colorPallete.primary.default}
                weight={theme.fonts.fontWeight.bold}
              >
                Editar endereço
              </Text>
            </div>
          </div>
          {!searchingZipCode ? (
            <div className="page__content">
              <div className="page__content__form-input">
                <Input
                  label="País"
                  name="country"
                  type="select"
                  placeholder="Selecionar"
                  size="medium"
                  id="country"
                  value={formik.values.country}
                  onChange={(e) => handleChangeCountry(e.target.value)}
                  validation={
                    formik.errors.country && formik.touched.country
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.country && formik.touched.country
                      ? formik.errors.country
                      : ''
                  }
                  selectOptions={CountriesArray.map((country) => ({
                    value: country.sigla,
                    text: country.nome_pais,
                  }))}
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label={formik.values.country === 'BR' ? 'CEP' : 'Zip Code'}
                  name="zipCode"
                  type="text"
                  maskType={formik.values.country === 'BR' ? 'cep' : 'false'}
                  placeholder={
                    formik.values.country === 'BR'
                      ? 'Insira o seu CEP'
                      : 'Insira o seu Zip Code'
                  }
                  size="medium"
                  id="zipCode"
                  value={formik.values.zipCode}
                  onChange={(e) => handleOnChange('zipCode', e.target.value)}
                  validation={
                    formik.errors.zipCode && formik.touched.zipCode
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.zipCode && formik.touched.zipCode
                      ? formik.errors.zipCode
                      : ''
                  }
                  maxLength="30"
                />
              </div>

              <div className="page__content__horizontal__form">
                <div className="page__content__form__big">
                  <Input
                    label={
                      formik.values.country === 'BR' ? 'Endereço' : 'Linha 1'
                    }
                    name="street"
                    type="text"
                    placeholder=""
                    size="medium"
                    id="street"
                    value={formik.values.street}
                    onChange={(e) => handleOnChange('street', e.target.value)}
                    validation={
                      formik.errors.street && formik.touched.street
                        ? 'invalid'
                        : null
                    }
                    validationMsg={
                      formik.errors.street && formik.touched.street
                        ? formik.errors.street
                        : ''
                    }
                    maxLength="80"
                  />
                </div>

                <div className="page__content__form__small">
                  <Input
                    label="Número"
                    name="number"
                    type="text"
                    placeholder=""
                    size="medium"
                    id="number"
                    value={formik.values.number}
                    onChange={(e) => handleOnChange('number', e.target.value)}
                    validation={
                      formik.errors.number && formik.touched.number
                        ? 'invalid'
                        : null
                    }
                    validationMsg={
                      formik.errors.number && formik.touched.number
                        ? formik.errors.number
                        : ''
                    }
                    maxLength="20"
                  />
                </div>
              </div>

              <div className="page__content__form-input">
                <Input
                  label={
                    formik.values.country === 'BR' ? 'Complemento' : 'Linha 2'
                  }
                  name="complement"
                  type="text"
                  placeholder=""
                  size="medium"
                  id="complement"
                  value={formik.values.complement}
                  onChange={(e) => handleOnChange('complement', e.target.value)}
                  validation={
                    formik.errors.complement && formik.touched.complement
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.complement && formik.touched.complement
                      ? formik.errors.complement
                      : ''
                  }
                  maxLength="80"
                />
              </div>

              <div className="page__content__form-input">
                <Input
                  label={
                    formik.values.country === 'BR'
                      ? 'Estado'
                      : 'Estado / Província / Região'
                  }
                  name="province"
                  type={formik.values.country === 'BR' ? 'select' : 'text'}
                  placeholder="Selecionar"
                  size="medium"
                  id="province"
                  value={formik.values.province}
                  onChange={(e) => handleChangeState(e.target.value)}
                  validation={
                    formik.errors.province && formik.touched.province
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.province && formik.touched.province
                      ? formik.errors.province
                      : ''
                  }
                  selectOptions={statesData.map((state) => ({
                    value: state.id,
                    text: state.sigla,
                  }))}
                  maxLength="80"
                />
              </div>

              <div className="page__content__form-input">
                <Input
                  label="Cidade"
                  name="city"
                  type="text"
                  placeholder=""
                  size="medium"
                  id="city"
                  value={formik.values.city}
                  onChange={(e) => handleOnChange('city', e.target.value)}
                  validation={
                    formik.errors.city && formik.touched.city ? 'invalid' : null
                  }
                  validationMsg={
                    formik.errors.city && formik.touched.city
                      ? formik.errors.city
                      : ''
                  }
                  maxLength="80"
                />
              </div>

              <div className="page__content__form-input">
                <Input
                  label={formik.values.country === 'BR' ? 'Bairro' : 'District'}
                  name="district"
                  type="text"
                  placeholder=""
                  size="medium"
                  id="district"
                  value={formik.values.district}
                  onChange={(e) => handleOnChange('district', e.target.value)}
                  validation={
                    formik.errors.district && formik.touched.district
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.district && formik.touched.district
                      ? formik.errors.district
                      : ''
                  }
                  maxLength="80"
                />
              </div>
            </div>
          ) : (
            <div className="page__content--loading">
              <IconLoading />
            </div>
          )}

          <div className="page__submit">
            <Button
              variant="primary"
              size="medium"
              type="submit"
              disabled={blockedButton}
              id="profile__button__address-save"
            >
              Salvar
            </Button>
          </div>
        </form>
      </Wrapper>
    </EditProfile>
  );
};

EditUserAddress.propTypes = {
  refreshToken: PropTypes.string,
};

EditUserAddress.defaultProps = {
  refreshToken: '',
};

export default EditUserAddress;
export { EditUserAddress };
