import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-family: ${theme.fonts.fontFamily.sans};
    padding-block: 32px;
    padding-left: 12px;

    @media (min-width: 768px) {
      padding-left: 10vw;
      padding-block: 24px 32px;
    }
  `}
`;

export const CertificateInfoConatiner = styled.div`
  ${({ theme }) => css`
    position: relative;
    order: 7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  `}
`;

export const CertificateText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-feature-settings:
      'clig' off,
      'liga' off;

    p {
      font-size: ${theme.fonts.fontSize.sm};
      font-weight: ${theme.fonts.fontWeight.regular};

      @media (min-width: 1400) {
        font-size: ${theme.fonts.fontSize.md};
      }
    }

    h4 {
      font-size: ${theme.fonts.fontSize.md};
      font-weight: ${theme.fonts.fontWeight.bold};

      @media (min-width: 1400) {
        font-size: ${theme.fonts.fontSize.g};
      }
    }
  `}
`;

export const InfoButton = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0;
  margin-bottom: -4px;

  align-self: flex-end;
  cursor: pointer;
`;

export const TooltipContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.sm};

    max-width: 375px;

    @media (max-width: 768px) {
      font-size: ${theme.fonts.fontSize.xs};
    }

    h5 {
      font-size: ${theme.fonts.fontSize.sm};
      font-weight: ${theme.fonts.fontWeight.bold};

      @media (max-width: 768px) {
        font-size: ${theme.fonts.fontSize.xs};
      }
    }

    ul {
      margin-inline: 24px 12px;
      margin-block: 12px;
    }

    p {
      font-style: italic;
    }
  `}
`;
