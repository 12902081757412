import React, { useEffect } from 'react';
import styled from 'styled-components';

// Theme
import { main as theme } from '@themes';

// Components
import { Button, IconPerson, Logo, Text } from '@components';

// Styles
import { styles } from './CancelSubscription.styles';

// ------------------------------------------------------------------------- //

const Wrapper = styled.main`
  ${styles}
`;

const CancelSubscription = React.memo(() => {
  function closeCurrentTab() {
    window.close();
  }

  return (
    <Wrapper>
      <div className="page__header">
        <div className="page__header__logo">
          <Logo size="220px" color="#fff" />
        </div>
      </div>

      <div className="page__body">
        <Text
          className="page__body__title"
          size="2.6rem"
          dsize="1.4rem"
          weight={theme.fonts.fontWeight.bold}
          color={theme.colorPallete.primary.default}
        >
          Cancelamento
        </Text>

        <div className="page__body__container">
          <Text
            className="page__body__container__title"
            as="h1"
            size="1.6rem"
            dsize="6rem"
            weight={theme.fonts.fontWeight.bold}
            family={theme.fonts.fontFamily.serif}
            color={theme.colorPallete.base.default}
          >
            Solicitação de Cancelamento:
          </Text>

          <div className="page__body__container__content">
            <div className="page__body__container__content__info">
              <div className="info__line">
                <div className="info__line__icon">
                  <IconPerson color={theme.colorPallete.base[400]} />
                </div>
                <div className="info__line__text">
                  <Text
                    as="h2"
                    dsize="1.6rem"
                    size="1.6rem"
                    color={theme.colorPallete.base[400]}
                    weight={theme.fonts.fontWeight.bold}
                  >
                    Atendimento
                  </Text>

                  <Text
                    as="p"
                    dsize="1.6rem"
                    size="1.6rem"
                    color={theme.colorPallete.base[400]}
                  >
                    Para prosseguir com o cancelamento, solicite a um de nossos
                    atendentes através do chat nesta mesma página.
                  </Text>
                  <br />
                  <br />
                  <Text
                    as="p"
                    dsize="1.6rem"
                    size="1.6rem"
                    color={theme.colorPallete.base[400]}
                  >
                    Em até 48 horas úteis, o departamento responsável dará
                    segmento a sua solicitação.
                  </Text>
                </div>
              </div>
            </div>
            <div className="page__body__container__content__thumbs">
              <img src="assets/images/samples-thumb.png" alt="" />
            </div>
          </div>

          <div className="page__body__container__content__button">
            <Button
              variant="hollow"
              size="big"
              onClick={() => {
                closeCurrentTab();
              }}
              id="cancel__button__close"
            >
              Voltar para a plataforma
            </Button>
          </div>
        </div>
      </div>

      <div className="page__footer">
        <Text
          as="p"
          size="1.3rem"
          dsize="1.3rem"
          color={theme.colorPallete.base[300]}
        >
          meusucesso.com© Copyright 2014-2021. powered by meuSucesso.com
        </Text>
      </div>
      <div id="nchat" chat-sdk="___$_6"></div>
    </Wrapper>
  );
});

export default CancelSubscription;
export { CancelSubscription };
