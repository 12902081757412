import { styled } from 'styled-components';
import { main as theme } from 'themes';

export const Wrapper = styled.div`
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;

  &.visible {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 100%;
    max-width: 51.6rem;
    margin-top: -8rem;

    .close-button {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0;

      button {
        cursor: pointer;
        background-color: transparent;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .white-box {
      width: 100%;
      padding: 3.6rem 1rem;
      background-color: ${theme.colorPallete.white};
      border-radius: 0.4rem;
      cursor: default;

      .white-box__text {
        h2,
        p {
          margin-bottom: 2.4rem;
        }

        h2 {
          padding-inline: 24px;
        }
      }

      .white-box__button {
        width: 100%;
        max-width: 31.5rem;
        margin: 0 auto;
        padding-top: 2.4rem;
      }
    }

    @media all and (min-width: ${theme.breakpoints.medium}) {
      .white-box {
        padding: 5rem;

        .white-box__text {
          p:last-of-type {
            margin-bottom: 0;
          }
        }

        .white-box__button {
          padding-top: 3.4rem;
        }
      }
    }
  }
`;
