import React from 'react';
import PropTypes from 'prop-types';
import { PosterBanner } from '@components';

import { Wrapper } from './Banner.styles';

const Banner = ({ content }) => {
  return (
    <Wrapper>
      <>{content && <PosterBanner content={content} />}</>
    </Wrapper>
  );
};

Banner.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    contents: PropTypes.instanceOf(Array),
  }),
};

Banner.defaultProps = {
  content: {
    title: null,
    subtitle: null,
    contents: [],
  },
};

export { Banner };
export default Banner;
