import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  height: 100%;
  background-color: ${theme.colorPallete.base[700]};
  padding: 3.6rem 1rem;
  padding-top: 9.1rem;
  position: relative;

  .notifications__container {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .account {
    width: 100%;
    margin: 0 auto;

    .account-containers {
      margin-top: 2rem;
      display: grid;
      grid-gap: 2rem;
      grid-auto-columns: 1fr;
      grid-template-areas:
        'user'
        'address'
        'account'
        'financial'
        'subscription'
        'exit';

      .user-data {
        grid-area: user;
      }

      .user-address {
        grid-area: address;
      }

      .account-info {
        grid-area: account;
      }

      .subscription-info {
        grid-area: subscription;
      }

      .financial-data {
        grid-area: financial;
      }

      .exit-button-container {
        grid-area: exit;
      }
    }
  }

  .lesson-zero-button {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    margin-top: 2rem;
    cursor: pointer;
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    padding: 3.6rem 8rem;

    .notifications__container {
      max-width: 120rem;
    }

    .account {
      max-width: 120rem;

      .account-containers {
        grid-template-areas:
          'user address'
          'user subscription'
          'account subscription'
          'financial subscription'
          'financial exit';
      }
    }
  }
`;

export default styles;
export { styles };
