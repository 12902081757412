import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  background-color: ${theme.colorPallete.base.default};
  background-image: url('/assets/images/error-background-mobile.svg');

  .page__header {
    display: block;
  }

  .page__error__message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;

    .error__regular-text {
      display: block;
      padding-top: 2rem;
    }

    .error__number {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .error__button {
      width: 100%;
      margin-top: 3rem;
      display: block;
      max-width: 30rem;
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    background-size: cover;
    background-image: url('/assets/images/error-background.svg');
  }
`;

export default styles;
export { styles };
