import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  /* max-width: 78rem; */
  margin: 0 auto;
  padding: 0 1rem;

  .content {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;
    }

    &__question {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 3rem;

      .content__question__number {
        padding-right: 2rem;
      }
    }

    &__answers {
      margin-bottom: 5rem;
    }

    &__interactions {
      margin-bottom: 3rem;

      div {
        width: 100%;
        margin-bottom: 2rem;
      }

      .content__interactions__goback {
        a {
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 1.6rem;
          font-weight: ${theme.fonts.fontWeight.bold};
          color: ${theme.colorPallete.base[400]};
          font-family: ${theme.fonts.fontFamily.sans};
          border: 0.1rem solid ${theme.colorPallete.base[400]};
          border-radius: 0.4rem;
          cursor: pointer;

          &:hover {
            color: ${theme.colorPallete.base.default};
            border: 0.1rem solid ${theme.colorPallete.base.default};
          }
        }
      }

      @media all and (min-width: ${theme.breakpoints.medium}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          max-width: 33rem;
          margin-right: 2rem;
        }
      }
    }

    &__reasons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        margin-bottom: 3rem;
      }
    }
  }
`;

export default styles;
export { styles };
