import styled from 'styled-components';

const Wrapper = styled.main`
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.bigger};
  padding: 2rem;
  margin: 0 auto;

  header {
    padding: 2rem 0 0;

    @media (min-width: ${(props) => props.theme.breakpoints.bigger}) {
      padding: 2rem 0;
    }
  }

  .result-message {
    width: 100%;
    text-align: center;
  }
`;

export { Wrapper };
export default Wrapper;
