import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-left: 16px;
  width: 100%;

  @media (max-width: 790px) {
    margin-left: 0;
  }

  .slick-track {
    margin: 0;

    @media (max-width: 790px) {
      display: flex;
      gap: 24px;
      display: flex;
      align-items: stretch;
      gap: 12px;
      width: 100% !important;

      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .slick-slide {
        width: 100% !important;
      }
    }
  }

  .slick-arrow {
    top: 50%;
    width: 5%;
    height: 100%;

    &::before {
      display: none !important;
    }
    &.slick-disabled {
      display: none !important;
    }

    &.slick-next {
      right: calc(-1% -24px);
      background-image: linear-gradient(to right, transparent, #000000);
      svg {
        transform: rotate(180deg);
      }
    }
    &.slick-prev {
      left: calc(-1% -24px);
      background-image: linear-gradient(to left, transparent, #000000);
    }
  }
`;

export const SliderContainer = styled.div`
  position: relative;
`;

export const NavigationArrow = styled.button`
  width: 30px;
  z-index: 3;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 100%;
  }
`;
