/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Text } from '@components';

import { TitleSkeleton, VideoListSkeleton } from 'skeletons';
import { ListVideos } from './containers';

// Styles
import { WrapperTop } from './History.styles';

const History = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);

  const { watchedData, loading } = useSelector((state) => state.watchedReducer);

  useEffect(() => {
    if (watchedData) {
      setSeriesData(watchedData.series);
      setCoursesData(watchedData.courses);
    }
  }, [watchedData]);

  return (
    <WrapperTop>
      <header className="my-list__header">
        <Text as="h1" size="1.8rem" dsize="2.2rem" weight="bold" dheight="180%">
          Assistidos
        </Text>
      </header>

      {loading ? (
        <div className="skeleton">
          <TitleSkeleton />
          <VideoListSkeleton />
        </div>
      ) : (
        <>
          {coursesData && (
            <div className="my-list__content">
              <div className="my-list__subtitle">
                <Text as="h2" dsize="1.6rem" weight="bold" dheight="180%">
                  Estudos de Caso
                </Text>
              </div>
              <ListVideos data={coursesData} />
            </div>
          )}
          {seriesData && (
            <div className="my-list__content">
              <div className="my-list__subtitle">
                <Text as="h2" dsize="1.6rem" weight="bold" dheight="180%">
                  Séries
                </Text>
              </div>
              <ListVideos data={seriesData} />
            </div>
          )}
        </>
      )}
    </WrapperTop>
  );
};

export { History };
export default History;
