import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

// Helpers
import { orderArrayByOrder } from '@helpers';

// Themes
import { main as theme } from '@themes';

// Components
import { Text } from '@components';

// Containers
import { CarouselContainer, LessonCard } from '@containers';

// Styles
import { styles as extraContentStyles } from './ExtraContent.styles';

const Wrapper = styled.div`
  ${extraContentStyles}
`;

const ExtraContent = React.memo(({ styles, data }) => {
  let weekKey = 0;
  let cardKey = 0;

  return (
    <Wrapper
      gradient_start={styles.colors.gradient_start}
      gradient_end={styles.colors.gradient_end}
    >
      <div className="extra-section">
        <div className="extra-section__title">
          <Text
            as="h3"
            size="2.2rem"
            dsize="3.2rem"
            color={theme.colorPallete.white}
            weight={theme.fonts.fontWeight.bold}
          >
            Conteúdo Extra
          </Text>
        </div>
        <div className="extra-section__content">
          {data &&
            orderArrayByOrder(data.weeks).map((week, weekIdx) => {
              weekKey = weekIdx;

              return (
                <div className="course__week" key={`week-${weekKey}`}>
                  <div className="course__week-title">
                    <Text
                      as="span"
                      className="course__week-number"
                      weight="bolder"
                      color={theme.colorPallete.white}
                      size="2.1rem"
                      dsize="2.1rem"
                      height="2.2rem"
                      dheight="2.2rem"
                    >
                      {`${week.order + 1}`}
                    </Text>
                    <Text
                      as="h2"
                      className="course__week-subject"
                      weight="bold"
                      color={theme.colorPallete.white}
                      size="2.1rem"
                      dsize="2.1rem"
                      height="2.2rem"
                      dheight="2.2rem"
                    >
                      {week.title}
                    </Text>
                  </div>
                  <div className="course__week-content">
                    <CarouselContainer count={week.contents.length * 2}>
                      {orderArrayByOrder(week.contents).map((card, cardIdx) => {
                        cardKey = cardIdx;
                        const modalContent = card.modal_content;
                        modalContent.thumbnails = card.video.thumbnails;
                        return (
                          <div
                            className="course__week__card"
                            key={`week-${cardKey}`}
                          >
                            <LessonCard
                              className="course__week__card--item"
                              lessonDescription={card.description}
                              lessonTitle={card.title}
                              lessonSubtitle={card.subtitle}
                              lessonSequence={card.sequence}
                              lessonNumber={`${card.order}`}
                              lessonAttachments={card.analisys_pdf}
                              modalContent={modalContent}
                              lessonPhoto={_.get(
                                card,
                                'video.thumbnails[3].link',
                                'https://i.vimeocdn.com//video//883665447_640x360.jpg?r=pad'
                              )}
                              lessonUrl={card.url}
                              lessonProgress={card?.progress?.progress}
                            />
                          </div>
                        );
                      })}
                    </CarouselContainer>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
});

ExtraContent.propTypes = {
  data: PropTypes.shape({
    weeks: PropTypes.arrayOf(
      PropTypes.shape({
        order: PropTypes.number,
        title: PropTypes.string,
        contents: PropTypes.array,
      })
    ),
  }),
  styles: PropTypes.shape({
    colors: PropTypes.shape({
      gradient_start: PropTypes.string,
      gradient_end: PropTypes.string,
    }),
  }),
};

ExtraContent.defaultProps = {
  data: {},
  styles: {},
};

export default ExtraContent;
export { ExtraContent };
