import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: ${(props) => props.theme.colorSystem.baseColor600};
    color: ${(props) => props.theme.colorSystem.white};
    border: 0.2rem solid ${(props) => props.theme.colorSystem.baseColor600};
    width: 100%;
    max-width: 33.5rem;
    height: 5.9rem;
    border-radius: 4px;
    cursor: pointer;
    transition: 300ms all;

    &:focus {
      box-shadow: 0rem 0rem 0.5rem
        ${(props) => props.theme.colorPallete.primary.default};
    }

    &:hover {
      background: transparent;
      border: 0.2rem solid ${(props) => props.theme.colorSystem.baseColor600};
    }

    &:active {
      color: ${(props) => props.theme.colorSystem.baseColor600};
      background: transparent;
      border: 0.2rem solid ${(props) => props.theme.colorSystem.baseColor600};
    }

    &.disabled {
      color: ${(props) => props.theme.button.fontcolor.hollow.disabled};
      border-color: ${(props) => props.theme.colorSystem.white};

      &:hover {
        background-color: ${(props) => props.theme.colorSystem.baseColor600};
        cursor: not-allowed;
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
