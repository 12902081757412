import { styled, css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    aspect-ratio: 1.38;

    display: flex;

    border-radius: 4px;
    background: radial-gradient(
      farthest-corner at 0% 1.01%,
      #656565 0%,
      #4b4b4b 9.08%,
      #2a2a2a 29.19%,
      #222 49.06%
    );
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 7.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const CourseSubtitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colorPallete.base[220]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: max(12px, 1vw);
    font-weight: ${theme.fonts.fontWeight.bold};
    text-transform: uppercase;

    @media (max-width: 1350px) {
      font-size: 2.2vw;
      line-height: 3vw;
    }
  `}
`;

export const CourseTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: max(16px, 1.8vw);
    font-weight: ${theme.fonts.fontWeight.bold};

    @media (max-width: 1350px) {
      font-size: 3.6vw;
      line-height: 4vw;
    }
  `}
`;

export const NameSubtitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: max(12px, 1vw);
    font-weight: ${theme.fonts.fontWeight.bold};

    @media (max-width: 1350px) {
      font-size: 2.2vw;
      line-height: 3vw;
    }
  `}
`;

export const StudentName = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colorPallete.primary[200]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: max(14px, 1.6vw);
    font-weight: ${theme.fonts.fontWeight.bold};

    margin-top: 4px;

    @media (max-width: 1350px) {
      font-size: 3.4vw;
      line-height: 3.8vw;
      margin-top: 0;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: max(12px, 0.9vw);
    font-weight: ${theme.fonts.fontWeight.regular};
    line-height: 22px;

    margin-top: 12px;

    @media (max-width: 1350px) {
      font-size: 2.2vw;
      line-height: 3vw;
    }
  `}
`;

export const SignaturesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -32px;

  @media (max-width: 1350px) {
    img {
      max-width: 24vw;
    }
  }
`;

export const LateralStrip = styled.div`
  ${({ theme }) => css`
    width: 5%;
    border-radius: 4px;
    background: linear-gradient(
      ${theme.colorPallete.primary[200]} 27.66%,
      rgba(254, 112, 0, 0) 139.81%
    );
  `}
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1.38;
`;
