import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

// Themes
import { main as theme } from '@themes';

// Components
import { Text, ReactAvatar } from '@components';

// Styles
import { Wrapper } from './CardUser.styles';

const CardUser = ({ comment, toggleFormOpened, formOpened, blockAnswer }) => {
  const { userData } = useSelector((state) => state.userReducer);

  return (
    <Wrapper blockAnswer={blockAnswer}>
      {comment.user.id === userData.id ? (
        <UserImage userData={userData} />
      ) : (
        <CostumerImage image={comment.user.image} name={comment.user.nome} />
      )}
      <div className="info">
        <Text
          as="h6"
          weight="bold"
          color={theme.colorSystem.baseColor500}
          size="1.6rem"
          dsize="1.6rem"
          height="2rem"
          dheight="2rem"
        >
          {comment.user.nome}
        </Text>
        <Text
          as="h6"
          weight="normal"
          color={theme.colorSystem.baseColor500}
          size="1.2rem"
          dsize="1.2rem"
          height="1.5rem"
          dheight="1.5rem"
          className="info__time"
        >
          {`${moment(comment.date.split(' ')[0], 'YYYY-MM-DD').format(
            'll'
          )} - ${moment(comment.date.split(' ')[1], 'HH:mm:ss').format(
            'HH:mm'
          )}`}
        </Text>
        <Text
          as="h6"
          weight="normal"
          color={theme.colorSystem.baseColor300}
          size="1.6rem"
          dsize="1.6rem"
          height="2.8rem"
          dheight="2.8rem"
          className="info__comment"
        >
          {comment.comment}
        </Text>
        <div className="info__buttons">
          <button type="button" onClick={() => toggleFormOpened(!formOpened)}>
            <Text
              as="h6"
              weight="normal"
              color={theme.colorSystem.baseColor500}
              size="1.4rem"
              dsize="1.4rem"
              height="2rem"
              dheight="2rem"
            >
              {formOpened ? 'Esconder' : 'Deixe sua resposta'}
            </Text>
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const UserImage = ({ userData }) => {
  return userData && userData.avatar ? (
    <div className="photo-holder">
      <img
        src={`${userData.avatar}`}
        alt={userData.name}
      />
    </div>
  ) : (
    <div className="photo-holder">
      <ReactAvatar name={userData.name} size="3.6" className="comment-avatar" />
    </div>
  );
};

UserImage.propTypes = {
  userData: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }),
};

UserImage.defaultProps = {
  userData: {},
};

const CostumerImage = ({ image, name }) => {
  return image && image.url ? (
    <div className="photo-holder">
      <img src={image.url} alt={image.alt} />
    </div>
  ) : (
    <div className="photo-holder">
      <ReactAvatar name={name} size="3.6" className="comment-avatar" />
    </div>
  );
};

CostumerImage.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  name: PropTypes.string,
};

CostumerImage.defaultProps = {
  image: {},
  name: '',
};

CardUser.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    comment: PropTypes.string,
    user: PropTypes.shape({
      nome: PropTypes.string,
      id: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    date: PropTypes.string,
  }),
  toggleFormOpened: PropTypes.func,
  formOpened: PropTypes.bool,
  blockAnswer: PropTypes.bool,
};

CardUser.defaultProps = {
  comment: {},
  toggleFormOpened: () => '',
  formOpened: false,
  blockAnswer: false,
};

export { CardUser };
export default CardUser;
