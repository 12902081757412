/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import { main as theme } from '@themes';
import { Button, IconRightArrow, Input, Text } from '@components';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Helpers
import { polishingString } from '@helpers';

// Templates
import { EditProfile } from '@templates';

// Styles
import { styles } from './EditUserPassword.styles';

const Wrapper = styled.div`
  ${styles}
`;

const EditUserPassword = ({ refreshToken }) => {
  const dispatch = useDispatch();

  const setShowModal = (value) =>
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));

  const { error } = useSelector((state) => state.userReducer);

  const [blockedButton, setBlockedButton] = useState(false);

  useEffect(() => {
    if (error) {
      setBlockedButton(false);
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('O campo senha é obrigatório'),
      password: Yup.string()
        .required('O campo senha é obrigatório')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*_#?&]{8,}$/,
          'Sua senha deverá ter no mínimo 8 dígitos, formada por letras e números'
        ),
      passwordConfirmation: Yup.string()
        .required('O campo confirmar senha é obrigatório')
        .oneOf(
          [Yup.ref('password'), null],
          'As senhas digitadas não estão iguais'
        ),
    }),
    onSubmit: async (values) => {
      const { currentPassword, passwordConfirmation, ...restValues } = values;

      const data = {
        ...restValues,
        current_password: currentPassword,
        password_confirmation: passwordConfirmation,
      };

      setBlockedButton(true);

      dispatch(
        userActions.forgotNewPasswordRequest({
          payload: { data, auth: { refresh_token: refreshToken } },
        })
      );
    },
  });

  const { setFieldValue, handleSubmit, setFieldTouched, validateForm } = formik;

  const handleOnChange = (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  return (
    <EditProfile>
      <Wrapper>
        <form className="page__container" onSubmit={handleSubmit} method="post">
          <div className="page__header">
            <button
              className="page__header__goback"
              onClick={() => setShowModal(false)}
              id="profile__button__password-close"
              type="button"
            >
              <Text
                size="1.6rem"
                dsize="1.6rem"
                color={theme.colorPallete.base.default}
                weight={theme.fonts.fontWeight.bold}
                cursor="pointer"
              >
                <span className="arrow-left">
                  <IconRightArrow color={theme.colorSystem.black} />
                </span>
                <span>Voltar</span>
              </Text>
            </button>

            <div className="page__header__title">
              <Text
                size="2rem"
                dsize="2rem"
                color={theme.colorPallete.primary.default}
                weight={theme.fonts.fontWeight.bold}
              >
                Alterar senha
              </Text>
            </div>
          </div>
          <div className="page__content">
            <div className="page__content__form-input">
              <Input
                label="Senha atual"
                name="currentPassword"
                type="password"
                placeholder=""
                size="medium"
                id="currentPassword"
                value={formik.values.currentPassword}
                onChange={(e) =>
                  handleOnChange(
                    'currentPassword',
                    polishingString(e.target.value)
                  )
                }
                validation={
                  formik.errors.currentPassword &&
                  formik.touched.currentPassword
                    ? 'invalid'
                    : null
                }
                validationMsg={
                  formik.errors.currentPassword &&
                  formik.touched.currentPassword
                    ? formik.errors.currentPassword
                    : ''
                }
              />
            </div>
            <div className="page__content__form-input">
              <Input
                label="Senha"
                name="password"
                type="password"
                placeholder=""
                size="medium"
                id="password"
                value={formik.values.password}
                onChange={(e) =>
                  handleOnChange('password', polishingString(e.target.value))
                }
                validation={
                  formik.errors.password && formik.touched.password
                    ? 'invalid'
                    : null
                }
                validationMsg={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : ''
                }
              />
            </div>
            <div className="page__content__form-input">
              <Input
                label="Confirmar senha"
                name="passwordConfirmation"
                type="password"
                placeholder=""
                size="medium"
                id="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onChange={(e) =>
                  handleOnChange(
                    'passwordConfirmation',
                    polishingString(e.target.value)
                  )
                }
                validation={
                  formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                    ? 'invalid'
                    : null
                }
                validationMsg={
                  formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                    ? formik.errors.passwordConfirmation
                    : ''
                }
              />
            </div>
          </div>

          <div className="page__submit">
            <Button
              variant="primary"
              size="medium"
              type="submit"
              disabled={blockedButton}
              id="profile__button__password-save"
            >
              Salvar
            </Button>
          </div>
        </form>
      </Wrapper>
    </EditProfile>
  );
};

EditUserPassword.propTypes = {
  refreshToken: PropTypes.string,
};

EditUserPassword.defaultProps = {
  refreshToken: '',
};

export default EditUserPassword;
export { EditUserPassword };
