/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cep from 'cep-promise';

// Actions
import { actions as placeActions } from '@store/ducks/place';
import { actions as userActions } from '@store/ducks/user';

// Components
import { Button, Input, IconLoading, Radio, Text } from '@components';

// Helpers
import { CountriesArray, getPropertyOfArray } from '@helpers';

// Themes
import { main } from '@themes';

// Styles
import * as S from './form.styles';

const Form2 = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { query } = router;

  const [blockedButton, setBlockedButton] = useState(false);

  const [searchingZipCode, toggleSearchingZipCode] = useState(false);

  const [userCep, insertUserCep] = useState(false);

  const [stateSelected, changeStateSelected] = useState(null);

  const { statesData } = useSelector((state) => state.placeReducer);

  const formik = useFormik({
    initialValues: {
      country: 'BR',
      zipCode: '',
      province: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      newsletter: '',
      number: '',
    },
    validationSchema: Yup.object().shape({
      country: Yup.string().required('Campo país obrigatório'),
      zipCode: Yup.string()
        .test(
          'required',
          'Campo CEP obrigatório',
          (cepNumber) =>
            formik.values.country !== 'BR' ||
            (cepNumber &&
              cepNumber.match(/\d+/g).join(',').replace(',', '').length === 8),
        )
        .test(
          'required',
          'Campo Zip Code obrigatório',
          (zipCodeNumber) =>
            formik.values.country === 'BR' ||
            (zipCodeNumber && zipCodeNumber.length > 0),
        ),
      province: Yup.string()
        .test(
          'required',
          'Campo estado obrigatório',
          (province) =>
            formik.values.country !== 'BR' || (province && province !== ''),
        )
        .test(
          'required',
          'Campo Estado / Província / Região obrigatório',
          (province) =>
            formik.values.country === 'BR' || (province && province.length > 0),
        ),
      city: Yup.string().required('Campo cidade obrigatório'),
      district: Yup.string()
        .test(
          'required',
          'Campo bairro obrigatório',
          (district) =>
            formik.values.country !== 'BR' || (district && district.length > 0),
        )
        .test(
          'required',
          'Campo district obrigatório',
          (district) =>
            formik.values.country === 'BR' || (district && district.length > 0),
        ),
      street: Yup.string()
        .test(
          'required',
          'Campo endereço obrigatório',
          (adress) =>
            formik.values.country !== 'BR' || (adress && adress.length > 0),
        )
        .test(
          'required',
          'Campo linha 1 obrigatório',
          (adress) =>
            formik.values.country === 'BR' || (adress && adress.length > 0),
        ),
      newsletter: Yup.boolean().required('Campo newsletter obrigatório'),
      number: Yup.string()
        .required('Campo número obrigatório')
        .test(
          'regex',
          'O campo não é válido',
          (number) => number && number.match(/^[A-Za-z0-9-+()/,]*$/),
        ),
    }),
    onSubmit: async (values) => {
      const { zipCode, province, ...restValues } = values;

      let uf = false;

      if (restValues.country === 'BR') {
        uf = statesData.filter(
          (state) => state.id.toString(10) === province && state.sigla,
        )[0].sigla;
      }

      const data = {
        ...restValues,
        zip_code: zipCode.replace(/[^a-zA-Z0-9]/g, '').substr(0, 8),
        fa_source_current: query.source,
        province: uf || province,
        fa_token: query.token,
      };

      // setBlockedButton(true);

      dispatch(userActions.sendFirstAccessRequest({ data }));

      // setCurrentStep('STEP_3');
    },
  });

  const { setFieldValue, handleSubmit, setFieldTouched, validateForm } = formik;

  const handleOnChange = async (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  const fetchStates = useCallback(() => {
    return dispatch(placeActions.getBrStatesMock());
  }, [dispatch]);

  // ? Dispara esse evento quando o usuário modificar o estado:
  useEffect(() => {
    // Carregando os estados:
    if (statesData.length === 0) {
      fetchStates();
    }
  }, [statesData.length, formik.values.province]);

  // ? Dispara esse evento quando o usuário inserir um cep:
  useEffect(() => {
    if (
      formik.values.zipCode &&
      (formik.values.country === 'BR' || formik.values.country === 'Brasil') &&
      formik.values.zipCode.replace(/\D/g, '').length === 8
    ) {
      // ? Activate loading on fields
      toggleSearchingZipCode(true);
      cep(formik.values.zipCode.replace(/\D/g, ''))
        .then((response) => {
          const newState = getPropertyOfArray(
            response.state,
            'sigla',
            'id',
            statesData,
          );
          if (newState.length > 0) {
            // ? Save user valid CEP:
            insertUserCep(formik.values.zipCode.replace(/\D/g, ''));
            // ? Save user`s state:
            changeStateSelected(newState[0].id.toString(10));
            setFieldValue('province', newState[0].id.toString(10));
            setFieldValue('street', response.street);
            setFieldValue('district', response.neighborhood);
            setFieldValue('city', response.city);
          }
        })
        .catch(() => {
          // ? Save user invalid CEP:
          insertUserCep(false);
          return false;
        });
      // ? Cancel loading on fields
      toggleSearchingZipCode(false);
    }
  }, [formik.values.zipCode]);

  /**
   * * Save state in a var, in case the user changes the country
   * @param {sring} value
   */
  const handleChangeState = (value) => {
    handleOnChange('province', value);
    return changeStateSelected(value);
  };

  /**
   * * When user changes the country, the brazilian state doesnt appear if its a foreign country.
   * @param {string} value
   */
  const handleChangeCountry = (value) => {
    if (value === 'BR') {
      handleOnChange('province', stateSelected);
    } else {
      handleOnChange('province', '');
    }
    handleOnChange('country', value);
  };

  return (
    <S.Wrapper onSubmit={handleSubmit} method="post">
      <div className="form__input">
        <Input
          label="País"
          name="country"
          type="select"
          placeholder="Selecionar"
          size="medium"
          id="country"
          value={formik.values.country}
          onChange={(e) => handleChangeCountry(e.target.value)}
          validation={
            formik.errors.country && formik.touched.country ? 'invalid' : null
          }
          validationMsg={
            formik.errors.country && formik.touched.country
              ? formik.errors.country
              : ''
          }
          selectOptions={CountriesArray.map((country) => ({
            value: country.sigla,
            text: country.nome_pais,
          }))}
        />
      </div>
      <div className="form__input">
        <Input
          label={formik.values.country === 'BR' ? 'CEP' : 'Zip Code'}
          name="zipCode"
          type="text"
          maskType={formik.values.country === 'BR' ? 'cep' : 'false'}
          placeholder={
            formik.values.country === 'BR'
              ? 'Insira o seu CEP'
              : 'Insira o seu Zip Code'
          }
          size="medium"
          id="zipCode"
          value={formik.values.zipCode}
          onChange={(e) => handleOnChange('zipCode', e.target.value)}
          validation={
            formik.errors.zipCode && formik.touched.zipCode ? 'invalid' : null
          }
          validationMsg={
            formik.errors.zipCode && formik.touched.zipCode
              ? formik.errors.zipCode
              : ''
          }
          maxLength="30"
        />
      </div>

      {!searchingZipCode ? (
        <>
          <div className="form__input--row">
            <div className="form__input__big">
              <Input
                label={formik.values.country === 'BR' ? 'Endereço' : 'Linha 1'}
                name="street"
                type="text"
                placeholder=""
                size="medium"
                id="street"
                value={formik.values.street}
                onChange={(e) => handleOnChange('street', e.target.value)}
                validation={
                  formik.errors.street && formik.touched.street
                    ? 'invalid'
                    : null
                }
                validationMsg={
                  formik.errors.street && formik.touched.street
                    ? formik.errors.street
                    : ''
                }
                maxLength="80"
              />
            </div>
            <div className="form__input__small">
              <Input
                label="Número"
                name="number"
                type="text"
                placeholder=""
                size="medium"
                id="number"
                value={formik.values.number}
                onChange={(e) => handleOnChange('number', e.target.value)}
                validation={
                  formik.errors.number && formik.touched.number
                    ? 'invalid'
                    : null
                }
                validationMsg={
                  formik.errors.number && formik.touched.number
                    ? formik.errors.number
                    : ''
                }
                maxLength="20"
              />
            </div>
          </div>
          <div className="form__input">
            <Input
              label={formik.values.country === 'BR' ? 'Complemento' : 'Linha 2'}
              name="complement"
              type="text"
              placeholder=""
              size="medium"
              id="complement"
              value={formik.values.complement}
              onChange={(e) => handleOnChange('complement', e.target.value)}
              validation={
                formik.errors.complement && formik.touched.complement
                  ? 'invalid'
                  : null
              }
              validationMsg={
                formik.errors.complement && formik.touched.complement
                  ? formik.errors.complement
                  : ''
              }
              maxLength="80"
            />
          </div>
          <div className="form__input">
            <Input
              label={formik.values.country === 'BR' ? 'Bairro' : 'District'}
              name="district"
              type="text"
              placeholder=""
              size="medium"
              id="district"
              value={formik.values.district}
              onChange={(e) => handleOnChange('district', e.target.value)}
              validation={
                formik.errors.district && formik.touched.district
                  ? 'invalid'
                  : null
              }
              validationMsg={
                formik.errors.district && formik.touched.district
                  ? formik.errors.district
                  : ''
              }
              maxLength="80"
            />
          </div>
          <div className="form__input">
            <Input
              label={
                formik.values.country === 'BR'
                  ? 'Estado'
                  : 'Estado / Província / Região'
              }
              name="province"
              type={formik.values.country === 'BR' ? 'select' : 'text'}
              placeholder="Selecionar"
              size="medium"
              id="province"
              value={formik.values.province}
              onChange={(e) => handleChangeState(e.target.value)}
              validation={
                formik.errors.province && formik.touched.province
                  ? 'invalid'
                  : null
              }
              validationMsg={
                formik.errors.province && formik.touched.province
                  ? formik.errors.province
                  : ''
              }
              selectOptions={statesData.map((state) => ({
                value: state.id,
                text: state.sigla,
              }))}
              maxLength="80"
            />
          </div>
          <div className="form__input">
            <Input
              label="Cidade"
              name="city"
              type="text"
              placeholder=""
              size="medium"
              id="city"
              value={formik.values.city}
              onChange={(e) => handleOnChange('city', e.target.value)}
              validation={
                formik.errors.city && formik.touched.city ? 'invalid' : null
              }
              validationMsg={
                formik.errors.city && formik.touched.city
                  ? formik.errors.city
                  : ''
              }
              maxLength="80"
            />
          </div>
        </>
      ) : (
        <div className="form__input--loading">
          <IconLoading />
        </div>
      )}

      <div className="form__text">
        <Text
          as="h6"
          size="1.6rem"
          color={main.colorSystem.primaryColor}
          weight="bold"
          height="normal"
          dheight="normal">
          Deseja receber nossa newsletter?
        </Text>
        <div className="form__input">
          <Radio
            objects={[
              {
                label: 'Sim',
                value: true,
                id: 'newsletter__radio__yes',
                disabled: false,
              },
              {
                label: 'Não',
                value: false,
                id: 'newsletter__radio__no',
                disabled: false,
              },
            ]}
            name="newsletter"
            display="line"
            onChange={(e) => handleOnChange('newsletter', e.target.value)}
            validation={
              formik.errors.newsletter && formik.touched.newsletter
                ? 'invalid'
                : null
            }
            validationMsg={
              formik.errors.newsletter && formik.touched.newsletter
                ? formik.errors.newsletter
                : ''
            }
            idTip="newsletter"
          />
        </div>
      </div>
      <S.ButtonContainer>
        <Button
          id="first-access__button__back"
          variant="hollow"
          size="medium"
          type="button"
          color={main.colorPallete.base[400]}
          onClick={() => setCurrentStep('STEP_1')}>
          Voltar
        </Button>
        <Button
          id="first-access__button__entry"
          variant="primary"
          size="medium"
          type="submit"
          disabled={blockedButton}>
          Continuar
        </Button>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};

export { Form2 };
export default Form2;
