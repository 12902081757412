import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default styles;
export { styles };
