export interface CompletionModalProps {
  type: ModalType;
  visible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setHasShownModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleIssueCertificate?: () => Promise<void>;
}

export enum ModalType {
  withCertificate = 'withCertificate',
  withoutCertificate = 'withoutCertificate',
}
