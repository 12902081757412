import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 24px;

    font-family: ${theme.fonts.fontFamily.sans};

    .has-focus {
      border: 1px solid white;
    }
  `}
`;

export const Avatar = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 42px;
    height: 42px;
    align-items: center;
    border-radius: 42px;
    background-color: ${theme.colorSystem.baseColor400};
    font-size: 16px;
    font-weight: ${theme.fonts.fontWeight.bold};

    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 42px;
    }
  `}
`;

export const ContainerInput = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-size: 1.6rem;
    width: 100%;
    padding: 12px 24px;
    background-color: ${theme.colorSystem.baseColor400};
    border: 1px solid ${theme.colorSystem.baseColor400};
    border-radius: 8px;

    cursor: text;

    @media (max-width: 600px) {
      padding: 12px 12px;
    }
  `}
`;

export const Input = styled.textarea`
  ${({ theme }) => css`
    width: 100%;
    height: 24px;
    vertical-align: center;
    background-color: transparent;
    color: ${theme.colorPallete.white};
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    font-family: ${theme.fonts.fontFamily.sans};
    resize: auto;
    margin: auto;

    &.resize {
      height: 48px;
    }

    @media (max-width: 500px) {
      margin: 0;
      height: 90px;
    }

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #7a7a7a;
    }
  `}
`;

export const Line = styled.div`
  width: 3px;
  height: 100%;
  background-color: black;
  margin-inline: auto 24px;

  @media (max-width: 600px) {
    margin: 0;
    display: none;
  }
`;

export const SendButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    cursor: text;

    &.enable {
      cursor: pointer;
      svg path {
        stroke: ${theme.colorPallete.white};
      }
    }

    @media (max-width: 600px) {
      margin-left: 5px;
    }
  `}
`;
