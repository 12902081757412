/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

// Themes
import { main as theme } from '@themes';

// Components
import {
  Button,
  IconRightArrow,
  Input,
  Text,
  UploadPhoto,
  IconWarning,
} from '@components';

import { parsePhoneNumber } from 'react-phone-number-input';

import { EditProfile } from '@templates';

import { CroppingArea } from './containers/CroppingArea';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Helpers
import {
  CountriesArray,
  validateCpf,
  capitalizeFirstLetter,
  polishingPhone,
  validatePhone,
  polishingString,
} from '@helpers';

// Styles
import { styles } from './EditUserData.styles';

const Wrapper = styled.div`
  ${styles}
`;

const EditUserData = ({ refreshToken }) => {
  const [blockedButton, setBlockedButton] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [tooLargeFile, setTooLargeFile] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePlaceholder, setImagePlaceholder] = useState(null);
  const [rotate, setRotate] = useState(0);
  const { profileData, error } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const setShowModal = (value) =>
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));

  const resolveCPF = (cpf) => {
    if (cpf !== null) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    }
    return '';
  };

  const FILE_SIZE = 8 * 800 * 1024;

  const formik = useFormik({
    initialValues: {
      avatar: false,
      name: profileData?.profile.name || '',
      email: profileData?.profile.email || '',
      gender: profileData?.profile.gender || '',
      phone: polishingPhone(profileData?.profile.phone) || '+55',
      nationality: profileData?.profile.nationality || 'BR',
      document:
        ((profileData?.profile?.nationality === 'BR' ||
          profileData?.profile?.nationality === 'Brasil') &&
        _.has(profileData.profile, 'cpf')
          ? resolveCPF(profileData?.profile.cpf)
          : profileData?.profile.document) || '',
    },
    validationSchema: Yup.object().shape({
      avatar: Yup.mixed().test(
        'fileSize',
        'Escolha uma imagem de até 10MB, ou tente recortar uma porção menor da imagem enviada.',
        (value) => {
          setTooLargeFile(false);
          if (!value) {
            return true;
          }

          const base64str = value.split(';base64,')[1];
          const decoded = atob(base64str);
          if (decoded.length > FILE_SIZE) {
            setTooLargeFile(true);
          }
          return value && decoded.length <= FILE_SIZE;
        }
      ),
      name: Yup.string()
        .required('Campo nome obrigatório')
        .min(2, 'Mínimo de 2 caracteres')
        .matches(
          /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
          'Apenas letras são permitidas'
        )
        .matches(/(\w.+\s).+/, 'Digite ao menos um nome e um sobrenome'),
      email: Yup.string()
        .email('Digite um e-mail válido')
        .required('Campo e-mail obrigatório')
        .test(
          'is-possible',
          'O e-mail digitado não é valido',
          (email) =>
            email &&
            email.indexOf('@') !== -1 &&
            email.substr(0, email.indexOf('@')).match(/[A-Za-z]+/)
        ),
      gender: Yup.string().required('Campo gênero obrigatório'),
      phone: Yup.string()
        .required(
          'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, adicione o símbolo +, o DDI e o número.'
        )
        .test(
          'is-possible',
          'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, adicione o símbolo +, o DDI e o número.',
          (phone) =>
            phone &&
            phone.length > 3 &&
            parsePhoneNumber(phone) &&
            validatePhone(phone, parsePhoneNumber(phone).country || 'US')
        ),
      nationality: Yup.string().required('Campo nacionalidade obrigatório'),
      document: Yup.string()
        .test(
          'required',
          'O campo CPF é obrigatório',
          (cpf) => formik.values.nationality !== 'BR' || (cpf && cpf.length > 3)
        )
        .test(
          'required',
          'O campo documento é obrigatório',
          (document) =>
            formik.values.nationality === 'BR' ||
            (document && document.length > 3)
        )
        .test(
          'match',
          'O CPF está inválido, por favor verifique os números digitados',
          (cpf) =>
            formik.values.nationality !== 'BR' ||
            (cpf &&
              // eslint-disable-next-line no-useless-escape
              cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/))
        )
        .test(
          'valida-cpf',
          'O cpf não é válido',
          (cpfNumber) =>
            formik.values.nationality !== 'BR' ||
            (cpfNumber && validateCpf(cpfNumber.replace(/\D/g, '')))
        ),
    }),
    onSubmit: async (values) => {
      const { avatar, document, phone, ...restValues } = values;

      let data = {
        ...restValues,
        email_confirmation: restValues.email,
        cctld: parsePhoneNumber(phone).country || 'US',
        phone: phone.toString().split(' ').join(''),
      };

      if (data.nationality === 'BR' || data.nationality === 'Brasil') {
        data = {
          ...data,
          cpf: document.replace(/[^a-zA-Z0-9]/g, ''),
        };
      } else {
        data = {
          ...data,
          document: document.replace(/[^a-zA-Z0-9]/g, ''),
        };
      }

      if (avatar) {
        data = {
          ...data,
          avatar,
        };
      }

      setBlockedButton(true);

      dispatch(
        userActions.editPersonalDataRequest({
          payload: { data, auth: { refresh_token: refreshToken } },
        })
      );
    },
  });

  // Fires when listening a form error
  useEffect(() => {
    if (error) {
      setBlockedButton(false);
    }
  }, [error]);

  const { setFieldValue, handleSubmit, setFieldTouched, validateForm } = formik;

  // handle avatar crop
  const handleImagePlaceholder = (imageBase) => {
    if (imageBase !== null) {
      setImageFile(imageBase);
      setImagePlaceholder(imageBase);
      setFieldValue('avatar', imageBase);
      setFieldTouched('avatar', false, false);
    }
  };

  const handleOnChange = (field, value, rotation) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);

    // call crop avatar
    if (field === 'avatar' && value !== null) {
      setShowCrop(true);
      setRotate(rotation);
      setImageFile(value);
      setImagePlaceholder(value);
    }

    return validateForm({ ...formik.values, [field]: value });
  };

  return (
    <EditProfile>
      <Wrapper>
        {(!showCrop || tooLargeFile) && (
          <form
            className="page__container"
            onSubmit={handleSubmit}
            method="post"
          >
            <div className="page__header">
              <button
                id="profile__button__user-close"
                className="page__header__goback"
                onClick={() => setShowModal(false)}
                type="button"
              >
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base.default}
                  weight={theme.fonts.fontWeight.bold}
                  cursor="pointer"
                >
                  <span className="arrow-left">
                    <IconRightArrow color={theme.colorSystem.black} />
                  </span>
                  <span>Voltar</span>
                </Text>
              </button>

              <div className="page__header__title">
                <Text
                  size="2rem"
                  dsize="2rem"
                  color={theme.colorPallete.primary.default}
                  weight={theme.fonts.fontWeight.bold}
                >
                  Alterar dados pessoais
                </Text>
              </div>
            </div>
            <div className="page__content">
              <div className="page__content__photo">
                <UploadPhoto
                  imagePlaceholder={imagePlaceholder}
                  setAvatar={handleOnChange}
                />
                <div className="page__content__photo-warning">
                  <>
                    {formik.errors.avatar && !tooLargeFile && (
                      <>
                        <IconWarning
                          size="16px"
                          color={theme.colorSystem.danger}
                        />
                        <Text
                          dsize="1.3rem"
                          size="1.1rem"
                          weight={theme.fonts.fontWeight.bold}
                          color={theme.colorSystem.danger}
                        >
                          <>{formik.errors.avatar}</>
                        </Text>
                      </>
                    )}

                    {tooLargeFile && (
                      <>
                        <Text
                          dsize="1.3rem"
                          size="1.1rem"
                          weight={theme.fonts.fontWeight.bold}
                          color={theme.colorSystem.danger}
                        >
                          <>
                            O arquivo enviado é muito grande, tente enviar uma
                            imagem de até 10MB.
                          </>
                        </Text>
                      </>
                    )}
                  </>
                </div>
              </div>
              <div className="page__content__form-input">
                <Input
                  label="Nome Completo"
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  size="medium"
                  value={formik.values.name}
                  onBlur={(e) =>
                    handleOnChange(
                      'name',
                      capitalizeFirstLetter(e.target.value)
                    )
                  }
                  onChange={(e) => handleOnChange('name', e.target.value)}
                  validation={
                    formik.errors.name && formik.touched.name ? 'invalid' : null
                  }
                  validationMsg={
                    formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : ''
                  }
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label="E-mail"
                  type="text"
                  size="medium"
                  placeholder=""
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onBlur={(e) =>
                    handleOnChange('email', e.target.value.toLowerCase())
                  }
                  onChange={(e) =>
                    handleOnChange('email', polishingString(e.target.value))
                  }
                  validation={
                    formik.errors.email && formik.touched.email
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : ''
                  }
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label="Gênero"
                  name="gender"
                  type="select"
                  placeholder="Selecionar"
                  size="medium"
                  id="gender"
                  value={formik.values.gender}
                  onChange={(e) => handleOnChange('gender', e.target.value)}
                  selectOptions={[
                    {
                      id: 0,
                      value: 'Masculino',
                      text: 'Masculino',
                    },
                    {
                      id: 0,
                      value: 'Feminino',
                      text: 'Feminino',
                    },
                    {
                      id: 0,
                      value: 'Não binário',
                      text: 'Não binário',
                    },
                  ]}
                  validation={
                    formik.errors.gender && formik.touched.gender
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.gender && formik.touched.gender
                      ? formik.errors.gender
                      : ''
                  }
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label="Celular"
                  id="phone"
                  name="phone"
                  type="phone"
                  placeholder="Insira o seu número"
                  value={formik.values.phone}
                  onChange={(value) => handleOnChange('phone', value)}
                  international
                  validation={
                    formik.errors.phone && formik.touched.phone
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.phone && formik.touched.phone
                      ? formik.errors.phone
                      : ''
                  }
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label="Nacionalidade"
                  name="nationality"
                  type="select"
                  placeholder="Selecionar"
                  size="medium"
                  id="nationality"
                  value={formik.values.nationality}
                  onChange={(e) =>
                    handleOnChange('nationality', e.target.value)
                  }
                  validation={
                    formik.errors.nationality && formik.touched.nationality
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.nationality && formik.touched.nationality
                      ? formik.errors.nationality
                      : ''
                  }
                  selectOptions={CountriesArray.map((country) => ({
                    value: country.sigla,
                    text: country.nome_pais,
                  }))}
                />
              </div>
              <div className="page__content__form-input">
                <Input
                  label={
                    formik.values.nationality === 'BR' ? 'CPF' : 'Documento'
                  }
                  name="document"
                  type="text"
                  maskType={
                    formik.values.nationality === 'BR' ? 'cpf' : 'false'
                  }
                  placeholder={
                    formik.values.nationality === 'BR'
                      ? 'Insira o seu CPF'
                      : 'Insira o seu documento'
                  }
                  size="medium"
                  id="document"
                  value={formik.values.document}
                  onChange={(e) => handleOnChange('document', e.target.value)}
                  validation={
                    formik.errors.document && formik.touched.document
                      ? 'invalid'
                      : null
                  }
                  validationMsg={
                    formik.errors.document && formik.touched.document
                      ? formik.errors.document
                      : ''
                  }
                />
              </div>
            </div>

            <div className="page__submit">
              <Button
                variant="primary"
                size="medium"
                type="submit"
                disabled={blockedButton}
                id="profile__button__user-save"
              >
                Salvar
              </Button>
            </div>
          </form>
        )}

        {showCrop && !tooLargeFile && (
          <div className="page__container">
            <div className="page__header">
              <div className="page__header__title">
                <Text
                  size="2rem"
                  dsize="2rem"
                  color={theme.colorPallete.primary.default}
                  weight={theme.fonts.fontWeight.bold}
                >
                  Recortar imagem
                </Text>
              </div>
            </div>

            <div className="page__content">
              <CroppingArea
                image={imageFile}
                setImage={handleImagePlaceholder}
                setShowCrop={setShowCrop}
                rotate={rotate}
              />
            </div>
          </div>
        )}
      </Wrapper>
    </EditProfile>
  );
};

EditUserData.propTypes = {
  refreshToken: PropTypes.string,
};

EditUserData.defaultProps = {
  refreshToken: '',
};

export default EditUserData;
export { EditUserData };
