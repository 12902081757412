import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  background-color: transparent;

  .paper {
    display: block;

    .block__title {
      margin-bottom: 3rem;
    }

    .block__content {
      h4 {
        margin-bottom: 3rem;
      }

      .label-content {
        color: ${theme.colorPallete.base.default};
      }

      .block__content__newsletter {
        margin-bottom: 4rem;
      }

      .social__button__container {
        width: 100%;
        max-width: 31.5rem;
        margin-top: 2.4rem;

        &:first-child {
          margin-top: 0;
        }
      }

      .ms-button {
        display: flex;
        align-items: center;
        justify-content: center;

        .social-button {
          margin-left: 2rem;
        }
      }

      @media all and (min-width: ${theme.breakpoints.medium}) {
        .block__content__social__buttons {
          display: grid;
          grid-gap: 3rem;
          grid-template-columns: auto auto;

          .social__button__container {
            margin: 0;
            max-width: 100%;
          }
        }
      }
    }
  }
`;

export default styles;
export { styles };
