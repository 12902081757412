import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import getCroppedImg from '@helpers/getCroppedImg';
import { main } from '@themes';
import { Button, Text } from '@components';

// Styles
import { styles } from './CroppingArea.styles';

const Wrapper = styled.div`
  ${styles}
`;

const CroppingArea = ({ image, setImage, setShowCrop, rotate }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const aspect = 1;

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotate
      );
      setImage(croppedImage);
      setShowCrop(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <>
      <Wrapper className="cropping-area">
        <div className="cropping-area__canvas">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            rotation={rotate}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="cropping-area__tips">
          <Text
            dsize="1.3rem"
            size="1.1rem"
            color={main.colorPallete.base[200]}
          >
            Arraste para posicionar e use o scroll do mouse para ampliar a
            imagem.
          </Text>
        </div>
        <div className="cropping-area__controls">
          <Button
            variant="hollow"
            color={main.colorPallete.base[400]}
            size="medium"
            onClick={() => setShowCrop(false)}
          >
            Cancelar
          </Button>
          <Button variant="primary" size="medium" onClick={cropImage}>
            Recortar
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

CroppingArea.propTypes = {
  setImage: PropTypes.func,
  setShowCrop: PropTypes.func,
  image: PropTypes.string,
};

CroppingArea.defaultProps = {
  // refreshToken: null,
  setImage: null,
  setShowCrop: null,
  image: null,
};

export default CroppingArea;
export { CroppingArea };
