import React from 'react';
import PropTypes from 'prop-types';

// Componentes
import { Text, Picture } from '@components';

// Styles
import { Wrapper } from './About.styles';

const About = ({ content }) => {
  return (
    <Wrapper>
      <header />
      <div>
        {content &&
          content.map((item) => {
            return (
              <article className="about-list">
                <div className="about-list__picture">
                  <Picture src={item.image.path} />
                </div>
                <div className="about-list__description">
                  <Text as="h4" dsize="1.5rem" weight="bold" dheight="180%">
                    {item.author.title}
                  </Text>
                  <Text as="p" dsize="1.6rem" dheight="160%">
                    {item.author.description}
                  </Text>
                </div>
              </article>
            );
          })}
      </div>
    </Wrapper>
  );
};

About.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
};

About.defaultProps = {
  content: {},
};

export { About };
export default About;
