import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import _ from 'lodash';

import { LoggedIn } from 'templates';

import { CatalogList } from './containers';

import {
  useInfiniteEntrepreneursCourseData,
  useInfiniteThematicCoursesData,
} from 'libs/ms-api-react-query';
import { msApiLib } from 'services/config/ms-api';

import { useWindowSize } from 'helpers';
import { Skeleton } from 'skeletons';

import * as S from './homeChildren.styles';

interface HomeChildrenProps {
  refreshToken: string;
}

const HomeChildren = ({ refreshToken }: HomeChildrenProps) => {
  const { pathname } = useRouter();
  const [titlePage, setTitlePage] = useState('');
  const [currentFilter, setCurrentFilter] = useState([]);
  const loadingRef = useRef<HTMLDivElement>();

  const { width = 1600 } = useWindowSize();

  const getPageSize = () => {
    const pageSize = Math.floor(width / 300);
    if (pageSize <= 2) return 2;
    if (pageSize >= 6 || width >= 1600) return 6;
    else return pageSize;
  };

  const getQueryFunction = () => {
    if (pathname === '/series') return useInfiniteThematicCoursesData;
    else return useInfiniteEntrepreneursCourseData;
  };

  const contentQuery = getQueryFunction()(msApiLib, {
    enabled: true,
    query: { tags: currentFilter, pageSize: getPageSize() },
  });

  const handleScroll = _.debounce(
    () => {
      const scrollElement = document.querySelector(
        '.template-loggedin__main-wrapper',
      );
      if (
        scrollElement.scrollTop + scrollElement.clientHeight >=
        loadingRef?.current?.offsetTop
      ) {
        if (contentQuery?.hasNextPage && !contentQuery?.isFetchingNextPage) {
          contentQuery.fetchNextPage();
        }
      }
    },
    200,
    { leading: true },
  );

  useEffect(() => {
    document
      .querySelector('.template-loggedin__main-wrapper')
      .addEventListener('scroll', handleScroll);
    return () =>
      document
        .querySelector('.template-loggedin__main-wrapper')
        .removeEventListener('scroll', handleScroll);
  }, [contentQuery?.isFetchingNextPage, currentFilter, pathname]);

  useEffect(() => {
    if (contentQuery?.currentPage <= 1) contentQuery.fetchNextPage();
  }, [pathname, contentQuery?.currentPage]);

  useEffect(() => {
    switch (pathname) {
      case '/estudos-de-caso':
        setTitlePage('Estudos de Caso');
        break;
      case '/series':
        setTitlePage('Séries');
        break;
      default:
        break;
    }
  }, [pathname]);

  return (
    <LoggedIn
      refreshToken={refreshToken}
      titlePage={titlePage}
      modalActive={false}
      showingModal={false}>
      <S.Wrapper>
        {pathname === '/estudos-de-caso' && (
          <CatalogList
            content={contentQuery?.data}
            whichPath={'courses'}
            onChangeFilter={(filter) => {
              setCurrentFilter(filter);
            }}
          />
        )}

        {pathname === '/series' && (
          <CatalogList
            content={contentQuery?.data}
            whichPath={'series'}
            onChangeFilter={(filter) => {
              setCurrentFilter(filter);
            }}
          />
        )}

        <S.LoadingMessage ref={loadingRef}>
          {contentQuery.isFetchingNextPage &&
            Array.from({ length: getPageSize() }).map((_, index) => (
              <S.SkeletonContainer>
                <Skeleton key={index} isFullHeight isFullWidth />
              </S.SkeletonContainer>
            ))}
        </S.LoadingMessage>
      </S.Wrapper>
    </LoggedIn>
  );
};

export { HomeChildren };
export default HomeChildren;
