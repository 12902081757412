import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 13px;
    margin-block: 46px;
    padding-inline: 20px;
    font-family: ${theme.fonts.fontFamily.sans};
    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 1.8rem;
    font-weight: ${theme.fonts.fontWeight.bold};

    &.title-about {
      margin-block: 13px;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    li {
      font-size: 1.4rem;
      list-style-type: none;
      line-height: 133%;
    }
  `}
`;

export const AboutEntrepreneursContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 70px;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  `}
`;

export const EntrepreneursCard = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: min-content auto;
    gap: 16px;

    @media (min-width: 769px) {
      min-width: 489px;
    }
  `}
`;

export const EntrepreneursContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 6px;

    max-width: 489px;
  `}
`;

export const EntrepreneursDescription = styled.p`
  ${({ theme }) => css`
    margin-bottom: 12px;
    font-size: 14px;
    color: #c0c0c0;
  `}
`;

export const Avatar = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 42px;
    min-width: 42px;
    height: 42px;
    align-items: center;
    border-radius: 42px;
    background-color: ${theme.colorSystem.baseColor100};
    font-size: 16px;
    font-weight: ${theme.fonts.fontWeight.bold};

    &.answer {
      width: 32px;
      height: 32px;
    }
    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 42px;
      object-fit: cover;
    }
  `}
`;
