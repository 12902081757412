/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from 'react-phone-number-input';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import { ReactAvatar, Button, IconPencil, Paper, Text } from '@components';

// Themes
import { main as theme } from '@themes';

// Containers
import { EditUserData } from '../EditUserData';
import { EditUserPassword } from '../EditUserPassword';

import { userdataStyles } from './UserData.styles';

const Wrapper = styled.div`
  ${userdataStyles}
`;

const UserData = ({ refreshToken }) => {
  const { profileData } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const setShowModal = (value) => {
    dispatch(userActions.setEditModal({ payload: { editModal: value } }));
  };

  const verifyFormatPhone = (phone) => {
    if (phone) {
      if (formatPhoneNumber(phone) !== '') {
        return formatPhoneNumber(phone);
      }
      return phone;
    }
    return '';
  };

  const resolveCPF = (cpf) => {
    if (cpf !== null) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    }
    return '';
  };

  return (
    <Wrapper className="user-data">
      <Paper className="paper">
        <div className="block-title">
          <Text
            as="h3"
            size="1.8rem"
            dsize="1.8rem"
            weight="bold"
            color={theme.colorPallete.primary.default}
          >
            Dados pessoais
          </Text>
          <button
            onClick={() =>
              setShowModal(<EditUserData refreshToken={refreshToken} />)
            }
            id="profile__button__edit-user"
          >
            <Text
              className="block-title__button-text"
              color={theme.colorPallete.base[400]}
              weight={theme.fonts.fontWeight.bold}
            >
              Editar
            </Text>
            <IconPencil color={theme.colorPallete.base[400]} />
          </button>
        </div>
        <div className="main-container">
          <div className="main-container__photo-holder">
            {profileData.profile.avatar ? (
              <div className="photo-holder">
                <img
                  src={`${profileData.profile.avatar}`}
                  alt={profileData.profile.name}
                />
              </div>
            ) : (
              <div className="photo-holder">
                <ReactAvatar name={profileData?.profile?.name || ''} />
              </div>
            )}
          </div>
          <div className="main-container__data-holder">
            <div className="data-holder">
              <div className="field-wrapper">
                <Text
                  as="h4"
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[400]}
                  weight="bold"
                >
                  Nome
                </Text>
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[600]}
                  weight="bold"
                >
                  {profileData?.profile?.name || ''}
                </Text>
              </div>
              <div className="field-wrapper">
                <Text
                  as="h4"
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[400]}
                  weight="bold"
                >
                  {profileData?.profile?.nationality === 'BR'
                    ? 'CPF'
                    : 'Documento'}
                </Text>
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[600]}
                  weight="bold"
                >
                  {((profileData?.profile?.nationality === 'BR' ||
                    profileData?.profile?.nationality === 'Brasil') &&
                    _.has(profileData.profile, 'cpf')
                    ? resolveCPF(profileData.profile.cpf)
                    : profileData?.profile?.document) || ''}
                </Text>
              </div>
              <div className="field-wrapper">
                <Text
                  as="h4"
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[400]}
                  weight="bold"
                >
                  Email
                </Text>
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[600]}
                  weight="bold"
                >
                  {profileData?.profile?.email || ''}
                </Text>
              </div>
              <div className="field-wrapper">
                <Text
                  as="h4"
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[400]}
                  weight="bold"
                >
                  Telefone
                </Text>
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[600]}
                  weight="bold"
                >
                  {verifyFormatPhone(profileData?.profile?.phone)}
                </Text>
              </div>
              <div className="field-wrapper">
                <Text
                  as="h4"
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[400]}
                  weight="bold"
                >
                  Gênero
                </Text>
                <Text
                  size="1.6rem"
                  dsize="1.6rem"
                  color={theme.colorPallete.base[600]}
                  weight="bold"
                >
                  {profileData?.profile?.gender || ''}
                </Text>
              </div>
              <div className="button-wrapper">
                <Button
                  variant="hollow"
                  size="medium"
                  onClick={() =>
                    setShowModal(
                      <EditUserPassword refreshToken={refreshToken} />
                    )
                  }
                  id="profile__button__edit-password"
                >
                  Alterar Senha
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Wrapper>
  );
};

UserData.propTypes = {
  refreshToken: PropTypes.string,
};

UserData.defaultProps = {
  refreshToken: '',
};

export default UserData;
export { UserData };
