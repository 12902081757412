import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  margin: 4.8rem auto;

  .container__header {
    width: 100%;
    margin-bottom: 2.5rem;
  }

  .container__interactions {
    width: 100%;

    .container__interactions__button {
      width: 100%;
      margin-bottom: 3rem;

      .ms-button {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 2rem;
        }
      }
    }

    @media all and (min-width: ${theme.breakpoints.medium}) {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .container__interactions__button {
        max-width: 24.6rem;

        &:first-of-type {
          margin-right: 2.4rem;
        }
      }
    }
  }
`;

export default styles;
export { styles };
