import { css } from 'styled-components';

const styles = ({ theme, bgimage, mobimage, color }) => css`
  width: 100%;
  max-width: 120rem;
  margin-top: 6rem;
  position: relative;
  padding: 0;
  border-radius: 0.4rem;
  background-color: ${theme.colorPallete.base[700]};
  background-image: url(${mobimage});
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  overflow: hidden;

  @media all and (min-width: ${theme.breakpoints.big}) {
    background-image: url(${bgimage});
    background-position: right center;
    background-size: cover;
  }

  .banner__image {
    width: 100%;
    height: 100%;
    min-height: 30rem;

    opacity: 0;
  }

  .banner__content {
    z-index: 3;
    margin: 3.5rem 2rem;

    .banner__content--title {
      display: inline-block;
      padding: 0.5rem;
      border: 0.1rem solid ${color};
      margin-bottom: 2.5rem;
    }

    .banner__content--text {
      margin-bottom: 2.5rem;
    }

    .banner__content--attachments {
      width: 100%;
      max-width: 36rem;

      .trial-text {
        padding-top: 1rem;
      }
    }
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    .banner__image {
      display: block;
      height: auto;
      width: 50%;
      max-width: 60rem;
    }

    .banner__content {
      max-width: 30rem;
      margin: 8rem;
    }
  }
`;

export default styles;
export { styles };
