import React, { useRef, useState } from 'react';
import * as S from './AddComments.styles';
import { IconSend } from 'components';
import { useSelector } from 'react-redux';
import { getFirstLetterOfName } from 'helpers';
import Image from 'next/image';

interface AddCommentsProps {
  onClick: (value: string) => void;
}

const AddComments = ({ onClick }: AddCommentsProps) => {
  const userData = useSelector((state: any) => state.userReducer.userData);
  const [valueInput, setValueInput] = useState('');
  const [focusInput, setFocusInput] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValueInput(e.currentTarget.value);
  };

  const handleSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(valueInput);
    setValueInput('');
  };

  return (
    <S.Wrapper id="add-comment-container" className={`wrapper-add-comment`}>
      <S.Avatar className="avatar">
        {userData?.avatar ? (
          <Image
            alt="avatar"
            className="avatar"
            src={userData?.avatar}
            width={42}
            height={42}
          />
        ) : (
          getFirstLetterOfName(userData?.name)
        )}
      </S.Avatar>
      <S.ContainerInput
        className={`${focusInput ? 'has-focus' : ''}`}
        onClick={() => {
          setFocusInput(true);
          inputRef.current?.focus();
        }}>
        <S.Input
          ref={inputRef}
          id="input-add-comment"
          placeholder="Digite aqui seu comentário"
          className={valueInput.length > 33 ? 'resize' : ''}
          onChange={handleChangeValue}
          value={valueInput}
          onFocus={() => {
            setFocusInput(true);
          }}
          onBlur={() => {
            setFocusInput(false);
          }}
        />
        <S.Line />
        <S.SendButton
          onClick={handleSend}
          disabled={!valueInput}
          className={!!valueInput ? 'enable' : ''}>
          <IconSend />
        </S.SendButton>
      </S.ContainerInput>
    </S.Wrapper>
  );
};

export default AddComments;
export { AddComments };
