import styled from 'styled-components';

const Wrapper = styled.form`
  .form {
    &__input {
      height: 7.4rem;
      width: 100%;
      display: flex;
      align-items: center;

      &__text {
        width: 100% !important;
        position: relative;

        @media all and (max-width: ${(props) =>
            props.theme.breakpoints.smaller}) {
          width: ${(props) =>
            props.variant === 'primary'
              ? '25rem !important'
              : '20rem !important'};
        }

        @media all and (min-width: ${(props) =>
            props.theme.breakpoints.smaller}) and (max-width: 400px) {
          width: ${(props) =>
            props.variant === 'primary'
              ? '30rem !important'
              : '26rem !important'};
        }

        @media all and (min-width: 400px) and (max-width: 500px) {
          width: ${(props) =>
            props.variant === 'primary'
              ? '34rem !important'
              : '30rem !important'};
        }

        label {
          display: none;
        }

        textarea {
          margin-left: 1rem;
          height: 7.4rem !important;
          border: 1px solid ${(props) => props.theme.colorSystem.baseColor100} !important;
          background-color: ${(props) =>
            props.theme.colorSystem.baseColor100} !important;
          padding: 2.5rem 1.5rem !important;
        }

        span {
          position: absolute;
          top: 7rem;
          left: 0.5rem;
        }
      }
    }

    &__buttons {
      margin-top: 2.5rem;
      height: 3rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      &__content {
        width: 25rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          width: 14.5rem;
        }
      }

      @media all and (max-width: ${(props) => props.theme.breakpoints.small}) {
        &__content {
          width: 15rem;

          button {
            width: 6.5rem;
            font-size: 1.2rem;
            height: 3.5rem;
            min-height: 3.5rem;
            max-height: 3.5rem;
          }
        }
      }

      .btn-not-ds {
        border: none;
        background: transparent;
        color: ${(props) => props.theme.colorPallete.primary.default};
        text-decoration: underline;
        width: auto;
        min-width: unset;
      }
    }
  }
`;

export { Wrapper };
export default Wrapper;
