/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Template
import { LoggedIn } from '@templates';

// Components
import { Text } from '@components';

// Skeleton
import { TitleSkeleton, VideoListSkeleton } from '@skeletons';

import { ListVideos } from './containers';

// Styles
import { WrapperTop } from './MyListPage.styles';

const MyListPage = ({ refreshToken }) => {
  const { favoriteData } = useSelector((state) => state.favoriteReducer);

  return (
    <LoggedIn titlePage="Minha Lista">
      <WrapperTop>
        <header className="my-list__header">
          <Text
            as="h1"
            dsize="2.2rem"
            size="1.8rem"
            weight="bold"
            dheight="180%">
            Minha Lista
          </Text>
        </header>
        {favoriteData ? (
          <>
            {favoriteData.courses && favoriteData.courses.length > 0 && (
              <div className="my-list__content">
                <div className="my-list__subtitle">
                  <Text as="h2" dsize="1.6rem" weight="bold" dheight="180%">
                    Estudos de Caso
                  </Text>
                </div>
                <ListVideos
                  data={favoriteData.courses}
                  refreshToken={refreshToken}
                />
              </div>
            )}
            {favoriteData.series && favoriteData.series.length > 0 && (
              <div className="my-list__content">
                <div className="my-list__subtitle">
                  <Text as="h2" dsize="1.6rem" weight="bold" dheight="180%">
                    Séries
                  </Text>
                </div>
                <ListVideos
                  data={favoriteData.series}
                  refreshToken={refreshToken}
                />
              </div>
            )}
          </>
        ) : (
          <div className="skeleton">
            <TitleSkeleton />
            <VideoListSkeleton />
          </div>
        )}
      </WrapperTop>
    </LoggedIn>
  );
};

MyListPage.propTypes = {
  refreshToken: PropTypes.string,
};

MyListPage.defaultProps = {
  refreshToken: '',
};

export { MyListPage };
export default MyListPage;
