import styled from 'styled-components';

export const Wrapper = styled.form`
  .form {
    min-width: 370px;

    &__input,
    &__input--row {
      margin-top: 2.4rem;
    }

    &__text {
      margin-top: 3.6rem;

      span {
        font-weight: inherit;
      }
    }

    &__input {
      &--row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &__big {
        width: calc(100% - 142px);
      }

      &__small {
        width: 120px;
      }

      &--loading {
        display: flex;
        justify-content: center;
        height: 10rem;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 4rem 0 0 0;
  gap: 25px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;
