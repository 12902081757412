import { css } from 'styled-components';

const styles = ({ theme }) => css`
  .cropping-area {
    &__canvas {
      position: relative;
      min-height: 500px;
      padding: 10px;
      background: ${theme.colorPallete.white};
    }

    &__tips {
      padding: 2rem;
    }

    &__controls {
      display: grid;
      column-gap: 2rem;
      grid-template-columns: 1fr;

      padding: 2rem;

      button {
        margin-bottom: 2rem;
      }

      @media (min-width: ${theme.breakpoints.medium}) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

export default styles;
export { styles };
