import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IconArrow } from 'components';
import { InvolvedPeopleDto } from '@libs/ms-api';
import * as S from './InvolvedPerson.styles';

interface involvedPersonProps {
  involvedPerson: InvolvedPeopleDto[];
}

const InvolvedPerson = ({ involvedPerson }: involvedPersonProps) => {
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    draggable: true,
    speed: 1000,
    rows: 1,
    slidesToShow: 6,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    centerMode: false,
    prevArrow: (
      <S.NavigationArrow className="arrow__previous" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    nextArrow: (
      <S.NavigationArrow className="arrow__next" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5.5,
        },
      },
      {
        breakpoint: 1850,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4.5,
        },
      },
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1335,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 790,
        settings: {
          unslick: true,
        },
      },
    ],
  };

  if (involvedPerson.length < 1) return <></>;

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>Quem fez parte deste programa de estudo</S.Title>
        <Slider {...settings}>
          {involvedPerson.map((person) => {
            return (
              <S.Card>
                {person.image.url ? (
                  <S.CardImage
                    src={person.image.url}
                    alt={person.image.url}
                    width="32"
                    height="32"
                  />
                ) : (
                  <S.DefaultCardImage />
                )}
                <S.CardContentText>
                  <S.CardPersonName title={person.name}>
                    {person.name}
                  </S.CardPersonName>
                  <S.CardPersonDescription title={person.bio}>
                    {person.bio}
                  </S.CardPersonDescription>
                </S.CardContentText>
              </S.Card>
            );
          })}
        </Slider>
      </S.Content>
    </S.Wrapper>
  );
};

export { InvolvedPerson };
export default InvolvedPerson;
