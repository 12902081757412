import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 6rem;
  background-color: ${theme.colorPallete.white};
  position: relative;

  .page__header {
    width: 100%;
    height: 20rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5rem;
    background-color: ${theme.colorPallete.base[700]};
    background-image: url('/assets/images/cancel-subscription-bg.png');
    background-position: center;
    background-size: cover;
  }

  .page__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 6rem 2rem;
    background-color: ${theme.colorPallete.white};

    .page__body__title {
      display: block;
      margin-bottom: 4.8rem;
    }

    .page__body__container {
      width: 100%;
      text-align: center;

      .page__body__container__title {
        padding-bottom: 2rem;

        @media all and (min-width: ${theme.breakpoints.big}) {
          padding-bottom: 6rem;
        }
      }

      .page__body__container__content {
        width: 100%;
        padding-top: 1rem;
        margin-bottom: 3.8rem;

        .page__body__container__content__info {
          width: 100%;
          margin-bottom: 3.6rem;

          .video-container {
            width: 100%;
            max-width: 120rem;
            margin: 0 auto;

            display: block;
            position: relative;
            height: calc((9 / 16) * 100vw);
            max-height: calc(100vh - 29rem);
            min-height: 24rem;

            @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
              min-height: 46rem;
            }

            & > div#lesson-vid {
              width: 100%;
              height: 100%;
              box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
              padding: 3.7rem;
              margin-right: 9rem;
              margin-bottom: 0;
            }

            iframe {
              width: 100%;
              height: 100%;
              border: 0;
            }
          }
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          display: flex;
          align-items: center;
          margin-bottom: 7.3rem;

          .page__body__container__content__info {
            padding: 3.7rem 2.2rem;
            margin-bottom: 0;
          }
        }
      }

      .page__body__container__content__button {
        width: 100%;

        @media all and (min-width: ${theme.breakpoints.big}) {
          max-width: 36rem;
        }
      }

      .container {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .page__footer {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colorPallete.base[700]};
    position: absolute;
    bottom: 0;
  }
`;

export default styles;
export { styles };
