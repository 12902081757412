import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

import { msApiLib } from 'services/config/ms-api';
import {
  useGetCourseData,
  usePostCertificateIssuanceData,
  useRecommendedCoursesData,
} from 'libs/ms-api-react-query';
import keepWatchingApi from 'services/msv2-api/keepWatching';

import { ComplementaryMaterial } from 'containers';
import { CourseNewBanner } from 'components';
import { useWindowSize } from '@helpers/useWindowSize';
import {
  BannerCourseSkeleton,
  ComplementarMaterialSkeleton,
  InvolvedPersonSkeleton,
  RecommendedContentSkeleton,
} from '@skeletons/index';
import {
  InvolvedPerson,
  RecommendedContent,
  CertificateInfo,
} from './containers';
import { TrailCourse } from './containers/ModuleSlider/components/TrailCourse';

interface LastWatched {
  id: string;
  url: string;
  in_progress: boolean;
}

const CourseContent = () => {
  const router = useRouter();
  const [lastWatched, setLastWatched] = useState<LastWatched | null>(null);
  const token = Cookies.get('tokenClient');
  const {
    userReducer: { userData },
  } = useSelector((state: any) => state);

  const { width } = useWindowSize();
  const isMobile = !!width && width <= 768;
  const certificateInfoBreakpoint = !!width && width < 1200;

  const { data: newCourseData } = useGetCourseData(msApiLib, {
    enabled: !!router.query?.slug,
    courseSlug: router.query?.slug as string,
  });

  const { data: recommendedCourses } = useRecommendedCoursesData(msApiLib, {
    enabled: !!router.query?.slug,
    params: { courseSlug: router.query?.slug as string },
  });

  const getLastWatchedLesson = async () => {
    if (!!newCourseData) {
      const response = await keepWatchingApi.toWatchLesson({
        course_id: newCourseData?.data?.id,
        token: token,
      });

      if (!!response.data?.content?.instance) {
        setLastWatched(response.data?.content?.instance);
      }
    }
  };

  const issueCertificate = usePostCertificateIssuanceData(msApiLib);

  const handleIssueCertificate = async () => {
    if (!!userData && userData.social_name === null) {
      router.push('/confirmar-nome');
      return;
    }
    await issueCertificate.mutate({
      bearerToken: token,
      params: {
        studentId: userData?.id,
        courseId: newCourseData?.data?.id,
      },
    });
  };

  useEffect(() => {
    getLastWatchedLesson();
  }, [newCourseData?.data?.id]);

  useEffect(() => {
    if (issueCertificate.isSuccess) {
      const certificateLink = `/certificados/${issueCertificate.data.data.id}`;
      router.push({
        pathname: certificateLink,
        query: { courseId: issueCertificate.data.data.course.id },
      });
    }
  }, [issueCertificate?.isSuccess]);

  if (!newCourseData?.data)
    return (
      <div className="skeleton">
        <BannerCourseSkeleton />
        <ComplementarMaterialSkeleton />
        <InvolvedPersonSkeleton />
        <RecommendedContentSkeleton />
      </div>
    );

  return (
    <>
      {newCourseData?.data && (
        <>
          <CourseNewBanner
            content={{
              images: newCourseData?.data?.images,
              description: newCourseData?.data?.description,
              progress: newCourseData?.data?.progress.percent,
              type: newCourseData?.data?.type,
            }}
            color={newCourseData?.data?.colors.primary}
            canCertify={newCourseData?.data?.canCertify}
            url={lastWatched?.url}
            handleIssueCertificate={handleIssueCertificate}
          />

          {newCourseData?.data?.canCertify && certificateInfoBreakpoint && (
            <CertificateInfo isMobile={isMobile} />
          )}

          <ComplementaryMaterial
            color={newCourseData?.data?.colors.primary}
            materials={newCourseData?.data?.materials}
          />

          <TrailCourse
            type="week"
            trailConclusionExercise={newCourseData?.data?.conclusionExercise}
            modules={newCourseData?.data?.modules.filter(
              (module) => module.type === 'week',
            )}
            studentIssuedCertificate={
              newCourseData?.data?.studentIssuedCertificate
            }
            handleIssueCertificate={handleIssueCertificate}
          />

          <TrailCourse
            type="sponsored"
            modules={newCourseData?.data?.modules.filter(
              (module) => module.type === 'sponsored',
            )}
          />

          <InvolvedPerson
            involvedPerson={newCourseData?.data?.involvedPeople}
          />
        </>
      )}

      {recommendedCourses && (
        <RecommendedContent content={recommendedCourses} />
      )}
    </>
  );
};

export { CourseContent };
export default CourseContent;
