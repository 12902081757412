export const getTitleAboutText = (type?: string, isPlural: boolean = false) => {
  if (!type) return 'o Empreendedor';
  const texts = {
    Especialista: ['o Especialista', 'os Especialistas'],
    Empreendedor: ['o Empreendedor', 'os Empreendedores'],
  };

  if (type === 'Especialista' || type === 'Empreendedor')
    return texts[type][isPlural ? 1 : 0] ?? '';
  else return texts['Empreendedor'][isPlural ? 1 : 0];
};
