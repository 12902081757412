import { Dispatch, useEffect, useState } from 'react';
import { IconCorrect, IconVector, IconWrong } from 'components';
import { LessonData, ChecklistItem } from '../Quiz/type';
import { checklistApi as mirrorChecklistApi } from 'services/msv2-api';
import Cookies from 'js-cookie';
import * as S from './ListAnswered.styles';

interface ListAnsweredProps {
  quiz: LessonData;
  setQuiz: Dispatch<any>;
  checkIfQuizIsDone: () => void;
}

const selectCurrentOption = (checklist: ChecklistItem) => {
  const currentOption = checklist?.options?.find(
    (option) => option.id === checklist.answered.option,
  );

  return currentOption;
};

const selectCurrentQuestion = (quiz: LessonData) => {
  const currentOptionIndex = quiz?.checklist?.find(
    (checklist) => !checklist?.answered,
  );

  if (!currentOptionIndex) return quiz.checklist[0];

  return currentOptionIndex;
};

const ListAnswered = ({
  quiz,
  setQuiz,
  checkIfQuizIsDone,
}: ListAnsweredProps) => {
  const token = Cookies.get('tokenClient');
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [checkResponse, setCheckResponse] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<ChecklistItem>(
    selectCurrentQuestion(quiz),
  );

  const handleAnswerButton = async () => {
    const optionSelected = currentQuestion.options.find(
      (option) => option.id === selectedOption,
    );

    const data = {
      lesson_slug: quiz.lesson_slug,
      question_id: currentQuestion.question_id,
      option_selected: {
        option_id: optionSelected.id,
        answer: true,
      },
    };

    try {
      const response = await mirrorChecklistApi.sendChecklistAnswers({
        data,
        token,
      });

      setQuiz(response?.data?.content?.instance);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleNextQuestion = () => {
    const newQuestion = quiz.checklist.find(
      (check) => check.order === currentQuestion.order + 1,
    );

    if (newQuestion) {
      setCurrentQuestion(newQuestion);

      if (newQuestion.answered) {
        const { order } = selectCurrentOption(newQuestion);
        setSelectedOption(order.toString());
        setCheckResponse(true);
      } else {
        setSelectedOption(null);
        setCheckResponse(false);
      }
    }
  };

  const handleCheckAnswer = () => {
    if (checkResponse) {
      if (currentQuestion.order === quiz.total_questions) {
        checkIfQuizIsDone();
      } else {
        handleNextQuestion();
      }
    } else {
      setCheckResponse(true);
      handleAnswerButton();
    }
  };

  useEffect(() => {
    if (currentQuestion.answered) {
      const { order } = selectCurrentOption(currentQuestion);
      setSelectedOption(order.toString());
      setCheckResponse(true);
    } else {
      setSelectedOption(null);
      setCheckResponse(false);
    }
  }, []);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Teste seus conhecimentos adquiridos nesse vídeo</S.Title>
        <S.AmountQuestions>{`${currentQuestion?.order}/${quiz?.total_questions}`}</S.AmountQuestions>
      </S.Header>
      <S.QuestionsContainer>
        <S.QuestionsTitle>{currentQuestion?.question}</S.QuestionsTitle>

        {currentQuestion?.options?.map((option, index) => {
          const isSelectedOption = selectedOption === option.id;
          const optionClass = JSON.parse(option.correct_option)
            ? 'correct'
            : 'wrong';
          const isCurrentOption =
            checkResponse && isSelectedOption ? optionClass : '';

          return (
            <S.Questions
              key={index}
              className={`${isCurrentOption} ${
                isSelectedOption ? 'current' : ''
              } ${!checkResponse ? 'not-answered' : ''}`}
              onClick={() => {
                setSelectedOption(option.id);
              }}
              disabled={checkResponse}>
              {isCurrentOption === 'correct' && <IconCorrect />}
              {isCurrentOption === 'wrong' && <IconWrong />}

              <S.Radio
                type="radio"
                value={option.id}
                checked={isSelectedOption}
                readOnly
                disabled={checkResponse}
              />

              <S.ItemContainer className={isCurrentOption}>
                {option?.description}
              </S.ItemContainer>
              {!!option?.reason && (
                <S.QuestionFeedbackContainer
                  className={'feedback-container'}
                  type={isCurrentOption}>
                  {option?.reason}
                </S.QuestionFeedbackContainer>
              )}
            </S.Questions>
          );
        })}

        <S.ButtonsContainer>
          <S.CheckAnswer
            isNextQuestion={checkResponse}
            onClick={() => {
              handleCheckAnswer();
            }}
            className={checkResponse && 'button-next-question'}
            disabled={!selectedOption}>
            {!checkResponse ? (
              'Conferir resposta'
            ) : currentQuestion.order === quiz.total_questions ? (
              'Verificar resultado'
            ) : (
              <>
                Próxima pergunta
                <IconVector />
              </>
            )}
          </S.CheckAnswer>
        </S.ButtonsContainer>
      </S.QuestionsContainer>
    </S.Wrapper>
  );
};

export default ListAnswered;
export { ListAnswered };
