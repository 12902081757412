import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { actions as lessonActions } from 'store/ducks/lesson';
import { CommentsSection } from './containers/CommentsSection';
import { ClassSummary } from './containers/ClassSummary';
import { msApiLib } from '@services/config/ms-api';
import {
  useGetPlaylist,
  usePostCertificateIssuanceData,
} from '@libs/ms-api-react-query';
import { LessonDto, Lesson, CheckCourseProgressDto } from '@libs/ms-api';

import { IconArrowLeft } from 'components';
import { ComplementaryMaterial } from 'containers';
import {
  CompletionModal,
  Playlist,
  MenuMobile,
  VideoPlayer,
  Quiz,
} from './containers';
import { ModalType } from './containers/CompletionModal/types';
import { useWindowSize } from 'helpers/useWindowSize';
import { main } from 'themes';
import * as S from './LessonContent.styles';

interface LessonContentProps {
  lessonData: LessonDto;
  courseProgress: CheckCourseProgressDto;
}

const LessonContent = ({ lessonData, courseProgress }: LessonContentProps) => {
  const [selectedLessonOrQuizOption, setSelectedLessonOrQuizOption] =
    useState(null);

  const [selectedModuleOption, setSelectedModuleOption] = useState(
    lessonData.module,
  );

  const [showNextButton, setShowNextButton] = useState(false);
  const [hasNextLesson, setHasNextLesson] = useState(false);
  const [isModuleOptionsOpen, setIsModuleOptionsOpen] = useState(false);
  const [isExtraContent, setIsExtraContent] = useState(false);
  const [wasModuleUpdated, setWasModuleUpdated] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasShownModal, setHasShownModal] = useState(false);

  const router = useRouter();
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const isMobile = !!width && width < 768;
  const isTablet = width <= 1024;

  const token = Cookies.get('tokenClient');
  const {
    userReducer: { userData },
  } = useSelector((state: any) => state);

  const { data: playlistContent } = useGetPlaylist(msApiLib, {
    bearerToken: token,
    params: {
      moduleSlug: selectedModuleOption?.slug,
    },
  });

  const currentLesson = playlistContent?.lessons?.find(
    (content) => content.id === selectedLessonOrQuizOption?.id,
  );
  const currentLessonIndex = playlistContent?.lessons?.indexOf(currentLesson);
  const nextLesson = playlistContent?.lessons?.[currentLessonIndex + 1];

  const currentModule = playlistContent?.availableModules?.find(
    (content) => selectedModuleOption?.id === content.id,
  );
  const currentModuleIndex =
    playlistContent?.availableModules?.indexOf(currentModule);
  const nextModule =
    playlistContent?.availableModules?.[currentModuleIndex + 1];

  const lastLessonId =
    playlistContent?.lessons[playlistContent?.lessons?.length - 1]?.id;
  const lastModuleId =
    playlistContent?.availableModules[
      playlistContent?.availableModules?.length - 1
    ]?.id;

  const currentLessonIsLastOfTheModule =
    selectedLessonOrQuizOption?.id === lastLessonId;
  const currentModuleIsLastOfPlaylist =
    selectedModuleOption?.id === lastModuleId;

  const handleChangeVideo = (lesson: Lesson) => {
    if (lesson?.slug)
      router.push('/curso/aula/[aula]', `/curso/aula/${lesson.slug}`, {
        shallow: true,
      });

    // * Get comments:
    dispatch(
      lessonActions.getCommentsRequest({
        payload: {
          data: {
            lessonId: lesson?.id,
          },
        },
      }),
    );

    setIsModuleOptionsOpen(false);
    setShowNextButton(false);
  };

  const returnNextContentButtonMessage = () => {
    const lastIndex = playlistContent?.lessons?.length;
    const lastId = playlistContent?.lessons[lastIndex - 1]?.id;
    if (selectedLessonOrQuizOption?.id === lastId) {
      return 'Próximo módulo';
    }
    return 'Próximo vídeo';
  };

  const checkIfTheNextContentIsLastOfModule = () => {
    setHasNextLesson(false);
    const lastModuleIndex = playlistContent?.availableModules?.length;
    const lastModule = playlistContent?.availableModules[lastModuleIndex - 1];

    const lastIndex = playlistContent?.lessons?.length;
    const lastId = playlistContent?.lessons[lastIndex - 1]?.id;

    const currentContentIsLastOfTheModule =
      selectedLessonOrQuizOption?.id === lastId;

    if (selectedModuleOption.name !== lastModule?.name) {
      setHasNextLesson(true);
    } else if (!currentContentIsLastOfTheModule) {
      setHasNextLesson(true);
    }
  };

  const handleNextVideo = useCallback(() => {
    if (!currentLessonIsLastOfTheModule) {
      setSelectedLessonOrQuizOption(nextLesson);
      if (nextLesson?.type !== 'quiz') handleChangeVideo(nextLesson);
    } else if (
      currentLessonIsLastOfTheModule &&
      !currentModuleIsLastOfPlaylist
    ) {
      setSelectedModuleOption(nextModule);
      setWasModuleUpdated(true);
    }
  }, [selectedLessonOrQuizOption]);

  const issueCertificate = usePostCertificateIssuanceData(msApiLib);

  const handleIssueCertificate = async () => {
    await issueCertificate.mutate({
      bearerToken: token,
      params: {
        studentId: userData?.id,
        courseId: lessonData?.course?.id,
      },
    });
  };

  useEffect(() => {
    if (issueCertificate.isSuccess) {
      const certificateLink = `/certificados/${issueCertificate.data.data.id}`;
      if (!!userData && userData.social_name === null) {
        router.push('/confirmar-nome');
      } else {
        router.push({
          pathname: certificateLink,
          query: { courseId: issueCertificate.data.data.course.id },
        });
      }
    }
  }, [issueCertificate?.isSuccess]);

  useEffect(() => {
    if (selectedLessonOrQuizOption) {
      checkIfTheNextContentIsLastOfModule();
    }
  }, [selectedLessonOrQuizOption]);

  useEffect(() => {
    if (!!playlistContent) {
      const selectedLesson = playlistContent?.lessons?.find(
        (lesson) => lesson.slug === router.query.aula,
      );
      setSelectedLessonOrQuizOption(selectedLesson);
    }
  }, [router.query.aula, playlistContent]);

  useEffect(() => {
    if (!!playlistContent && wasModuleUpdated) {
      setSelectedLessonOrQuizOption(playlistContent?.nextLesson);
      handleChangeVideo(playlistContent?.nextLesson);
      setWasModuleUpdated(false);
    }
  }, [selectedModuleOption, playlistContent?.nextLesson?.slug]);
  return (
    <S.Wrapper>
      <CompletionModal
        visible={isModalVisible && !hasShownModal}
        type={
          courseProgress?.courseCanCertify
            ? ModalType.withCertificate
            : ModalType.withoutCertificate
        }
        setIsModalVisible={setIsModalVisible}
        setHasShownModal={setHasShownModal}
        handleIssueCertificate={handleIssueCertificate}
      />
      <S.TopNavigation>
        <Link
          href={
            lessonData?.type === 'library'
              ? '/home'
              : `/curso/${lessonData?.course?.slug}`
          }>
          <S.BackButtonWrapper>
            <IconArrowLeft />
            Voltar
          </S.BackButtonWrapper>
        </Link>
        {!isMobile && lessonData?.type !== 'library' && (
          <S.Breadcrumb>
            Dashboard / {lessonData?.course.name} /{' '}
            {selectedLessonOrQuizOption && (
              <span>
                {selectedLessonOrQuizOption.category?.title}
                {selectedLessonOrQuizOption.category?.subtitle !== '' &&
                selectedLessonOrQuizOption.type !== 'liveclass'
                  ? ` - ${selectedLessonOrQuizOption.category?.subtitle}`
                  : null}
              </span>
            )}
          </S.Breadcrumb>
        )}
      </S.TopNavigation>
      <S.Title>
        {lessonData?.type === 'sponsored' || lessonData?.type === 'library'
          ? lessonData?.name
          : selectedLessonOrQuizOption?.title}
      </S.Title>

      <S.VideoContainer>
        {selectedLessonOrQuizOption?.status === 'pendente' ? (
          <h2>Vídeo não disponível</h2>
        ) : (
          <>
            {selectedLessonOrQuizOption?.type === 'quiz' ? (
              <Quiz
                selectedLessonOrQuizOption={selectedLessonOrQuizOption}
                handleNextVideo={handleNextVideo}
                returnNextContentButtonMessage={returnNextContentButtonMessage}
                showNextButton={showNextButton}
                setShowNextButton={setShowNextButton}
                checkIfTheNextContentIsLastOfModule={
                  checkIfTheNextContentIsLastOfModule
                }
                hasNextLesson={hasNextLesson}
                isLastContent={
                  currentLessonIsLastOfTheModule &&
                  currentModuleIsLastOfPlaylist
                }
              />
            ) : (
              <VideoPlayer
                handleNextVideo={handleNextVideo}
                returnNextContentButtonMessage={returnNextContentButtonMessage}
                showNextButton={showNextButton}
                setShowNextButton={setShowNextButton}
                checkIfTheNextContentIsLastOfModule={
                  checkIfTheNextContentIsLastOfModule
                }
                hasNextLesson={hasNextLesson}
                video={lessonData?.video}
                lessonSlug={lessonData.slug}
                lessonType={lessonData?.type}
                setIsModalVisible={setIsModalVisible}
                shouldShowModal={courseProgress?.isLastLessonToCertificate}
                isLastContent={
                  currentLessonIsLastOfTheModule &&
                  currentModuleIsLastOfPlaylist
                }
              />
            )}
          </>
        )}

        {selectedLessonOrQuizOption &&
          lessonData?.type !== 'sponsored' &&
          lessonData?.type !== 'library' &&
          !isTablet && (
            <Playlist
              playlistContent={playlistContent}
              handleChangeVideo={handleChangeVideo}
              selectedLessonOrQuizOption={selectedLessonOrQuizOption}
              setSelectedLessonOrQuizOption={setSelectedLessonOrQuizOption}
              selectedModuleOption={selectedModuleOption}
              setSelectedModuleOption={setSelectedModuleOption}
              isModuleOptionsOpen={isModuleOptionsOpen}
              setIsModuleOptionsOpen={setIsModuleOptionsOpen}
              setWasModuleUpdated={setWasModuleUpdated}
            />
          )}
      </S.VideoContainer>

      {selectedLessonOrQuizOption &&
      lessonData?.type !== 'sponsored' &&
      lessonData?.type !== 'library' &&
      isTablet ? (
        <MenuMobile
          aulasContent={
            <Playlist
              playlistContent={playlistContent}
              handleChangeVideo={handleChangeVideo}
              selectedLessonOrQuizOption={selectedLessonOrQuizOption}
              setSelectedLessonOrQuizOption={setSelectedLessonOrQuizOption}
              selectedModuleOption={selectedModuleOption}
              setSelectedModuleOption={setSelectedModuleOption}
              isModuleOptionsOpen={isModuleOptionsOpen}
              setIsModuleOptionsOpen={setIsModuleOptionsOpen}
              setWasModuleUpdated={setWasModuleUpdated}
            />
          }
          descricaoContent={<ClassSummary lessonData={lessonData} />}
          materialContent={
            <ComplementaryMaterial
              color={main.colorSystem.baseColor100}
              materials={lessonData?.materials}
            />
          }
        />
      ) : (
        <>
          {lessonData?.materials?.length > 0 && (
            <ComplementaryMaterial
              color={main.colorSystem.baseColor100}
              materials={lessonData?.materials}
            />
          )}
          <ClassSummary lessonData={lessonData} />
        </>
      )}
      <S.CommentsContainer>
        <CommentsSection
          lessonId={lessonData?.id}
          title={lessonData?.metadata?.commentsTitles?.title}
        />
      </S.CommentsContainer>
    </S.Wrapper>
  );
};

export default LessonContent;
export { LessonContent };
