import React, { useEffect, useState } from 'react';
import * as S from './CommentsSection.styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as lessonActions } from 'store/ducks/lesson';
import { AddComments, Comment } from './Components';
import { CommentsData } from './types';
import { useWindowSize } from 'helpers/useWindowSize';
import Cookies from 'js-cookie';

interface CommentsSectionProps {
  lessonId: string;
  title: string;
}

const CommentsSection = ({ lessonId, title }: CommentsSectionProps) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { commentsData } = useSelector((state: any) => state.lessonReducer);
  const {
    lessonReducer: { lessonData },
  }: any = useSelector((state) => state);
  const isTablet = !!width && width <= 1024;
  const refreshToken = Cookies.get('refreshtokenClient');

  const sendComment = (commentText: string, commentId?: string) => {
    dispatch(
      lessonActions.sendCommentsRequest({
        payload: {
          data: {
            description: commentText,
            lesson_id: lessonId,
            comment_id: !!commentId ? commentId : null,
          },
          auth: {
            refresh_token: refreshToken,
          },
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(
      lessonActions.getCommentsRequest({
        payload: {
          data: {
            lessonId,
          },
        },
      }),
    );
  }, []);

  return (
    <S.Wrapper className={isTablet && 'resposive'}>
      <S.HeaderComments>
        <S.Description>{title}</S.Description>
      </S.HeaderComments>
      <S.AmountComments>
        Comentários<span>{` (${commentsData?.length || 0})`}</span>
      </S.AmountComments>
      <AddComments onClick={sendComment} />
      {commentsData?.map((comment: CommentsData, index: number) => (
        <Comment
          key={index}
          comment={comment}
          replyCallback={(value) => {
            sendComment(value, comment.id);
          }}
        />
      ))}
    </S.Wrapper>
  );
};

export default CommentsSection;
export { CommentsSection };
