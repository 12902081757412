/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'next/link';
import _ from 'lodash';

// Components
import { Text } from '@components';

// Containers
import { VideoThumb } from '@containers';

// Themes
import { main as theme } from '@themes';

// Styles
import { styles } from './ListVideos.styles';

const Wrapper = styled.div`
  ${styles}
`;

const ListVideos = ({ data }) => {
  return (
    <Wrapper>
      {data && (
        <>
          <div className="my-list-video__list">
            {data.map((item, index) => {
              const keythumb = `${item.video_type}-${index}`;

              return <VideoItem item={item} key={keythumb} />;
            })}
          </div>
        </>
      )}
    </Wrapper>
  );
};

const VideoItem = ({ item }) => {
  return item && (
    <div className="my-list-video__item">
      <div className="my-list-video__thumb">
        <Link href="/curso/aula/[aula]" as={item.url}>

          <VideoThumb
            alt={item.title}
            img={_.get(
              item,
              'video.thumbnails[3].link',
              'https://i.vimeocdn.com//video//883665447_640x360.jpg?r=pad'
            )}
            radius="6px"
            time={item?.progress?.progress?.toString() || '1'}
            source="external"
            iconSize="30px"
          />

        </Link>
      </div>
      <div className="my-list-video__title">
        <Text
          as="h3"
          dsize="1.6rem"
          size="1.6rem"
          weight="bold"
          dheight="2rem"
          color={theme.colorSystem.white}
        >
          {item.title}
        </Text>
      </div>
      <div className="my-list-video__subtitle">
        <Text
          as="p"
          dsize="1.2rem"
          size="1.2rem"
          dheight="1.6rem"
          transform="uppercase"
          color={theme.colorPallete.base[400]}
        >
          {item.header.type}
          {item.header.author ? `. ${item.header.author}` : ''}
        </Text>
      </div>
    </div>
  );
};
export { VideoItem };

ListVideos.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

ListVideos.defaultProps = {
  data: [],
};

export default ListVideos;
export { ListVideos };
