import { useRouter } from 'next/router';

import { PublicRoute } from '@templates';
import { PublicCertificateContent } from './containers';

import { useGetPublicCertificateInformation } from '@libs/ms-api-react-query';
import { msApiLib } from '@services/config/ms-api';
import { useEffect } from 'react';

export const PublicCertificate = () => {
  const router = useRouter();

  const { data: certificateContent, isError } =
    useGetPublicCertificateInformation(msApiLib, {
      enabled: true,
      params: {
        certificateId: router.query.certificateId as string,
      },
    });

  useEffect(() => {
    //Como é uma rota pública, não temos o userData para verificar as features. O redirect é feito sempre
    router.replace('/404');
  }, []);

  if (isError) router.push('/404');

  return (
    // <PublicRoute
    //   titlePage={`Certificado ${
    //     certificateContent ? ` - ${certificateContent?.course?.title}` : ''
    //   }`}>
    //   <PublicCertificateContent certificateContent={certificateContent} />
    // </PublicRoute>
    <></>
  );
};
