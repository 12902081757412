import styled from 'styled-components';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  padding: 0 0 2rem;
  position: relative;

  @keyframes opening {
    from {
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .container-notification-mss-warning {
    position: fixed;
    bottom: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #000c;
    z-index: 999;

    animation-name: opening;
    animation-duration: 1s;
    transition: all 0.5s ease-in-out;

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    [tabindex='-1']:focus {
      outline: 0 !important;
    }

    *:focus {
      outline: none;
    }

    a {
      text-decoration: none;
      background-color: transparent;
    }

    @media (max-width: 453px) {
      top: auto;
      height: 100%;
    }
  }

  .wsr-notification-mss-accept {
    @supports ((--a: 0)) {
      /* supported */
      font-family: 'Muli', Helvetica, Arial, sans-serif;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;

    &__main {
      margin: 20px;

      @supports ((--a: 0)) {
        background: #fff;
        border-radius: 10px;
      }
      @supports (not (--a: 0)) {
        background: #fff;
        border-radius: 0;
      }

      @media (min-width: 475px) {
        max-width: 750px;
        position: relative;
        margin: 20px auto 20px auto;
      }

      @media (max-width: 900px) {
        max-width: 500px;
      }
    }

    &__text {
      @supports ((--a: 0)) {
        font-family: 'Muli', Helvetica, Arial, sans-serif;
      }

      padding: 15px 20px 20px 20px;

      h1 {
        font-size: 18px;
        margin-bottom: 6px;
        @supports ((--a: 0)) {
          color: #f25225;
          text-transform: unset;
        }
        @supports (not (--a: 0)) {
          color: #000;
          text-transform: unset;
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
        max-width: 1200px;

        @supports ((--a: 0)) {
          color: #000000;
        }
        @supports (not (--a: 0)) {
          color: #000;
        }

        a {
          text-decoration: underline;
          @supports ((--a: 0)) {
            color: #f25225;
          }
          @supports (not (--a: 0)) {
            color: #000;
          }
        }
      }
    }

    &__close-button {
      background: transparent;
      margin: 0;
      border: none;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      .close-icon {
        cursor: pointer;
      }
    }

    &__agree-button {
      width: 91%;
      display: block;
      margin: 0 auto;
      padding: 10px 0;
      background: none;
      border: none;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;

      @supports ((--a: 0)) {
        font-family: 'Muli', Helvetica, Arial, sans-serif;
        background: ${(props) => props.theme.btnPopup};
        color: ${(props) => props.theme.colorTxtPopup};
        border-radius: 4px;
        text-transform: unset;
      }
      @supports (not (--a: 0)) {
        font-family: Arial, Helvetica, sans-serif;
        background: ${(props) => props.theme.btnPopup};
        color: ${(props) => props.theme.colorTxtPopup};
        border-radius: 4px;
        text-transform: unset;
      }

      &:hover {
        transition: all 0.2s ease-in-out;

        @supports ((--a: 0)) {
          background: ${(props) => props.theme.hoverPopup};
        }
        @supports (not (--a: 0)) {
          background: ${(props) => props.theme.hoverPopup};
        }
      }
    }

    &__content {
      padding-bottom: 10px;
      background-color: ${(props) => props.theme.bgPopup};
      border-radius: 9px;
      text-align: center;

      .icon-btn-pop-home {
        margin-top: 10px;
        cursor: pointer;
      }

      .imagem-topo {
        position: relative;
        padding-bottom: 6px;

        .img-produto {
          width: 100%;
          border-radius: 9px 9px 0 0;
        }
      }
    }

    &__close-button {
      right: 48px;
      top: 50%;
      transform: translateY(-50%);
    }

    .close-popup-button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
`;

export { Wrapper };
export default Wrapper;
