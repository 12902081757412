import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Theme
import { main as theme } from '@themes';

// Components
import { Text } from '@components';

// Styles
import { styles } from './Notifications.styles';

// ------------------------------------------------------------------------- //

const Wrapper = styled.main`
  ${styles}
`;

const Notifications = React.memo(({ children }) => {
  return (
    <Wrapper>
      <Text
        as="h2"
        size="1.8rem"
        dsize="1.8rem"
        color={theme.colorPallete.white}
        weight={theme.fonts.fontWeight.bold}
      >
        Notificações
      </Text>
      <div className="notifications__body">{children}</div>
    </Wrapper>
  );
});

Notifications.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Notifications;
export { Notifications };
