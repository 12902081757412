import { css } from 'styled-components';

const styles = css`
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 3.6rem;
  cursor: pointer;
  overflow: auto;

  .video-container {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;

    .video-wrap {
      display: flex;
      position: relative;
      width: 100%;
      height: calc((9 / 16) * 100vw);
      max-height: calc(100vh - 29rem);
      min-height: 46rem;

      & > div,
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .close-modal {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 2rem;

      button {
        background: transparent;
        outline: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  &.visible {
    display: block;
  }
`;

export default styles;
export { styles };
