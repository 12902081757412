import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 3.6rem;

  .form {
    display: ${(props) =>
      props.formOpened && !props.blockAnswer ? 'block' : 'none'};
    margin: 2rem 0 0 4.6rem;
  }
`;

export { Wrapper };
export default Wrapper;
