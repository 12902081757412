import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Components
import {
  Button,
  Input,
  Text,
  IconCertificateCircle,
  IconWatermark,
} from '@components';

// Styles
import * as S from './form.styles';
import main from '@themes/main';

const Form3 = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { query } = router;

  const firstAccessData = useSelector(
    (state) => state.userReducer.firstAccessData,
  );

  const formik = useFormik({
    initialValues: {
      social_name: firstAccessData.name,
    },
    validationSchema: Yup.object().shape({
      social_name: Yup.string()
        .matches(/^\S+\s\S+.*$/, 'Nome e sobrenome são necessários')
        .required('Nome social é obrigatório'),
    }),
    onSubmit: async (values) => {
      const { ...restValues } = values;
      const data = {
        ...restValues,
        fa_source_current: query.source,
        fa_token: query.token,
        fa_step: 'STEP_3',
      };
      dispatch(userActions.sendFirstAccessRequest({ data }));
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    validateForm,
    validateField,
  } = formik;

  const handleOnChange = async (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    return validateForm({ ...formik.values, [field]: value });
  };

  const capitalizeLetters = (inputString) => {
    const words = inputString.split(' ');

    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    return formattedString;
  };

  return (
    <S.Wrapper>
      <S.TextContainer>
        <Text
          as="p"
          size="1.5rem"
          dsize="1.5rem"
          color={main.colorPallete.base[350]}
          weight="400"
          dheight="normal">
          Seu nome completo <S.FullName>“{firstAccessData.name}”</S.FullName>{' '}
          será exibido em seu certificado.
        </Text>
        <Text
          as="p"
          size="1.5rem"
          dsize="1.5rem"
          color={main.colorPallete.base[350]}
          weight="400"
          dheight="normal">
          Certifique-se de que esteja correto.
        </Text>
        <Text
          as="p"
          size="1.1rem"
          dsize="1.1rem"
          color={main.colorPallete.base[350]}
          weight="400"
          dheight="normal">
          ATENÇÃO: Esta informação não poderá ser alterada! Caso mais tarde
          deseje modificar o nome exibido, entre em contato com nossa central de
          atendimento.
        </Text>
      </S.TextContainer>
      <S.Form onSubmit={handleSubmit} method="post">
        <S.InputContainer>
          <Input
            label="Nome de Certificado"
            name="social_name"
            type="text"
            placeholder="Insira o nome social"
            size="medium"
            id="socialName"
            value={formik.values.social_name}
            validation={
              formik.errors.social_name && formik.touched.social_name
                ? 'invalid'
                : null
            }
            onChange={(e) => handleOnChange('social_name', e.target.value)}
            validationMsg={
              formik.errors.social_name && formik.touched.social_name
                ? formik.errors.social_name
                : ''
            }
            maxLength="200"
          />
        </S.InputContainer>
        <S.CertificateTemplate>
          <IconCertificateCircle size="64px" className="icon-certificate" />
          <S.TextplaceHolder size="50%" />
          <S.SocialName>
            {capitalizeLetters(formik.values.social_name)}
          </S.SocialName>
          <div className="subtext">
            <S.TextplaceHolder size="80%" />
            <S.TextplaceHolder size="70%" />
          </div>
          <IconWatermark className="icon-watermark" />
        </S.CertificateTemplate>

        <S.ButtonContainer>
          <Button
            id="first-access__button__back"
            variant="hollow"
            size="medium"
            type="button"
            color={main.colorPallete.base[400]}
            onClick={() => setCurrentStep('STEP_2')}>
            Voltar
          </Button>

          <Button
            id="first-access__button__entry"
            variant="primary"
            size="medium"
            type="submit"
            disabled={!!formik.errors.social_name}>
            Confirmar Nome
          </Button>
        </S.ButtonContainer>
      </S.Form>
    </S.Wrapper>
  );
};

export { Form3 };
export default Form3;
