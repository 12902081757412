import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Actions
import { actions as libraryActions } from '@store/ducks/library';

// Components
import { Tag } from '@components';

// Styles
import { styles } from './Keywords.styles';

const Wrapper = styled.div`
  ${styles}
`;

const Keywords = ({ refreshToken }) => {
  const dispatch = useDispatch();

  const {
    libraryData: { tags },
    loading,
  } = useSelector((state) => state.libraryReducer);

  const handleActiveTag = (item) => {
    const newTags = [];

    // Active item only isnt active and is clcked by user (one tag active per time):
    tags.map((tag) =>
      tag.title === item.title && !item.active
        ? newTags.push({ ...item, active: true })
        : newTags.push({ ...tag, active: false })
    );

    dispatch(libraryActions.toggleActiveTag({ payload: { tags: newTags } }));

    let params = {
      page: 1,
    };
    // If item isnt active, it means that the tag will be active this time:
    if (!item.active) {
      params = {
        ...params,
        tag: item.slug,
      };
    }

    dispatch(
      libraryActions.getLibraryRequest({
        payload: {
          params,
          auth: {
            refresh_token: refreshToken,
          },
          data: {
            newTag: true,
          },
        },
      })
    );
  };

  return (
    <Wrapper>
      <div className="block__keywords">
        {tags &&
          tags.map((item, index) => {
            const key = `tag-${index}`;
            return (
              <Tag
                className="block__keywords--item library__button__tag"
                text={item.title}
                key={key}
                id={index}
                active={item.active}
                disabled={loading}
                handleActive={() => handleActiveTag(item)}
              />
            );
          })}
      </div>
    </Wrapper>
  );
};

Keywords.propTypes = {
  refreshToken: PropTypes.string,
};

Keywords.defaultProps = {
  refreshToken: '',
};

export default Keywords;
export { Keywords };
