import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .aulas {
    padding-inline: 20px;
  }
  .descricao .wrapper-class-summary {
    padding-inline: 20px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;

  margin-block: 24px 32px;
  padding-inline: 20px;
`;

export const NavigationItem = styled.button`
  ${({ theme }) => css`
    width: 100%;
    background-color: transparent;
    padding: 8px;
    cursor: pointer;

    color: ${theme.colorPallete.base[320]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.sm};

    border-bottom: 2px solid ${theme.colorPallete.base[425]};

    transition: all 0.2s;
    &.active {
      color: ${theme.colorPallete.primary[200]};
      border-bottom: 2px solid ${theme.colorPallete.primary[200]};
    }
  `}
`;
