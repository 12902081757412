import React, { useState } from 'react';
import { PosterList } from 'containers';
import { FiltersByTheme } from 'containers/FiltersByTheme';
import { useRouter } from 'next/router';

import * as S from './CatalogList.styles';
import { useGetFilterTagsData } from 'libs/ms-api-react-query';
import { msApiLib } from 'services/config/ms-api';

const CatalogList = ({ content, whichPath, onChangeFilter }) => {
  const { pathname } = useRouter();
  const [filter, setFilter] = useState('');
  const { data: filterTags } = useGetFilterTagsData(msApiLib, {
    enabled: true,
  });

  const returnTitleSubtitle = () => {
    if (!!filter && content?.length > 0) {
      return (
        <S.Subtitle>
          Foram encontrados <span>{content?.length} resultado(s)</span>{' '}
          relacionado(s) a <span>{filter}</span>
        </S.Subtitle>
      );
    }

    if (!!filter && content?.length === 0) {
      return (
        <S.Subtitle>
          Nenhum resultado encontrado relacionado a <span>{filter}</span>
        </S.Subtitle>
      );
    }

    if (pathname === '/estudos-de-caso') {
      return (
        <S.Subtitle>
          Aprenda com as histórias dos maiores empreendedores e especialistas
        </S.Subtitle>
      );
    }

    if (pathname === '/series') {
      return (
        <S.Subtitle>
          Conteúdos exclusivos, nacionais e internacionais, abordando temas
          relevantes pro seu negócio
        </S.Subtitle>
      );
    }

    return <></>;
  };

  return (
    <S.Wrapper>
      <FiltersByTheme
        whichPath={whichPath}
        filters={filterTags}
        onChange={(currentFilter) => {
          setFilter(currentFilter[0]?.text);
          onChangeFilter([currentFilter[0]?.slug]);
        }}
      />
      <S.TitleContainer>
        <S.Title>
          {pathname === '/estudos-de-caso' ? 'Estudos de Caso' : 'Séries'}
        </S.Title>
        {returnTitleSubtitle()}
      </S.TitleContainer>

      <PosterList content={content} />
    </S.Wrapper>
  );
};

export { CatalogList };
