import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-left: 4px;
    margin-bottom: 30px;
    position: relative;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 12px;
`;

export const ModuleNumber = styled.p`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    z-index: 3;

    border-radius: 50%;
    background-color: ${theme.colorPallete.base[600]};
    border: 1px solid ${theme.colorPallete.base[400]};

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};

    &.completed {
      background-color: #70380a;
      border: 1px solid #fe7000;
    }
  `}
`;

export const ModuleTitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 35px 30px 51px;
    border-radius: 8px;
    max-width: 500px;

    font-family: ${theme.fonts.fontFamily.sans};

    p {
      font-size: ${theme.fonts.fontSize.xs};
      color: ${theme.colorPallete.base[200]};
      margin-bottom: 24px;
    }
  `}
`;

export const IssueCertificateButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 196px;
    padding: 8px 16px;
    cursor: pointer;

    background-color: transparent;
    border: 1px solid #fe7000;
    border-radius: 4px;

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.xs};
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colorPallete.white};
    font-feature-settings:
      'clig' off,
      'liga' off;
  `}
`;
