import styled, { css } from 'styled-components';
import { main as theme } from '@themes';

const FormStyles = styled.form`
  .form {
    &__input {
      margin-top: 2.4rem;
    }

    &__button {
      width: 100%;
      margin: 4rem 0 14rem 0;
    }
  }
`;

const FooterButton = styled.div`
  width: 100%;
  /* max-width: 33.5rem; */
  margin: 3.6rem 0 0 0;
  text-align: right;

  button {
    margin-bottom: 1rem;
  }
`;

const linkSectionStyles = () => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;

  a,
  span {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .cb-keep-me-logged {
    display: flex;
    position: relative;
    margin-top: -8px;
    max-width: 12rem;

    @media (min-width: ${theme.breakpoints.smaller}) {
      max-width: 100%;
    }

    input {
      display: block;
      visibility: hidden;
      width: 0px;
      height: 0px;
      position: absolute;
      left: -9999px;
    }

    label {
      display: flex;
      align-items: center;

      .styled-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.8rem;
        height: 1.8rem;
        min-width: 1.8rem;
        min-height: 1.8rem;
        margin-right: 1rem;
        border-radius: 4px;
        border: 1px solid ${theme.colorPallete.base[400]};

        svg {
          display: none;
        }
      }
    }
  }

  .cb-keep-me-logged input:checked ~ label {
    .styled-checkbox {
      svg {
        display: block;
      }
    }
  }
`;

const LinkSection = styled.section`
  ${linkSectionStyles}
`;

export { FormStyles, FooterButton, LinkSection };
