import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 49rem;
    margin: auto;
    padding: 6rem 2rem 0 2rem;

    span {
      font-weight: bold;
    }

    @media (max-width: ${theme.breakpoints.medium}) {
      margin-top: 0;
    }

    .mobile-only {
      display: none;
      @media (max-width: ${theme.breakpoints.medium}) {
        display: block;
      }
    }
  `}
`;

export const TitleSection = styled.section`
  ${({ theme }) => css`
    max-width: 95%;

    h4 {
      font-family: ${theme.fonts.fontFamily.serif};
      margin: 1.5rem 0 2.4rem 0;
    }

    .titlesection__step {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `}
`;

export const FormContainer = styled.div`
  width: 95%;
  max-width: 95%;
`;
