import { ModulesDto } from '@libs/ms-api';
import { CarouselCards } from './components';
import * as S from './ModuleSlider.styles';

export interface ModuleSliderProps {
  module: ModulesDto;
  moduleNumber: number;
  isLastItem?: boolean;
  modules?: any;
  index?: any;
}

export const ModuleSlider = ({
  module,
  isLastItem,
  moduleNumber,
  modules,
  index,
}: ModuleSliderProps) => {
  const checkIfCompleted = module.isChecked;

  const classes = `${checkIfCompleted ? 'completed' : ''} ${
    isLastItem ? 'last' : ''
  }`;

  return (
    <>
      <S.Wrapper className={classes}>
        <S.TitleContainer>
          <S.ModuleNumber className={checkIfCompleted ? 'completed' : null}>
            {moduleNumber}
          </S.ModuleNumber>
          <S.ModuleTitle>{`Módulo ${moduleNumber} - ${module.name}`}</S.ModuleTitle>
        </S.TitleContainer>
        <S.CarouselContainer>
          <CarouselCards module={module} />
        </S.CarouselContainer>
      </S.Wrapper>
    </>
  );
};
